import React, { useEffect, useState } from "react"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import Slide from "@material-ui/core/Slide"
import "./style.scss"
import { getContent } from "../../../../services/Content"
import { CircularProgress } from "@material-ui/core"
import vLine from "../../../../images/v-line.png"
import { prefix_api_set_img_content } from "../../../../services/prefix"

import join from "classnames"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function InfoModel({
  type = "",
  handleCloseInfoModal = (f) => f,
  infoModal = false,
  dialogContent = "",
  className = "",
}) {
  const [img, setimg] = useState("")
  const [title, settitle] = useState("")
  const [text, setText] = useState()
  const [loading, setloading] = useState(false)
  useEffect(() => {
    setloading(true)
    getContent(type).then((data) => {
      let arr = data.content
      settitle(arr[0].value)
      setText(arr[1].value)
      setimg(`${prefix_api_set_img_content}${arr[2].file}`)
      setloading(false)
    })
  }, [type])

  return (
    <Dialog
      open={infoModal}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseInfoModal}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={"info-modal-container"}
    >
      {loading ? (
        <div style={{ margin: "auto" }}>
          <CircularProgress
            size={50}
            thickness={4}
            style={{ color: "#ff6500", marginRight: "3px" }}
          />
        </div>
      ) : (
        <div
          className={join(
            "info-modal-content",
            dialogContent && "dialog-content"
          )}
        >
          {infoModal && (
            <span onClick={handleCloseInfoModal} className="close-icon"></span>
          )}
          {!dialogContent ? (
            <DialogTitle id="alert-dialog-slide-title">
              <span dangerouslySetInnerHTML={{ __html: title }}></span>
            </DialogTitle>
          ) : (
            ""
          )}
          <div className="img-text-content">
            <img src={vLine} alt="" className="left-line" />

            {!dialogContent ? (
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  <span dangerouslySetInnerHTML={{ __html: text }}></span>
                </DialogContentText>
              </DialogContent>
            ) : (
              dialogContent
            )}
            <img src={vLine} alt="" className="right-line" />
          </div>
          <img src={img} alt="" />
        </div>
      )}
    </Dialog>
  )
}
