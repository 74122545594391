import { getTheTime } from "./dateTime"
import { prefixApi, prefixHeaderObj } from "./prefix"

export const NOT_STARTED = "not_started"
export const IN_PROGRESS = "in_progress"
export const FINISHED = "finished"
export const ABANDONED = "abandoned"
export const NOT_VALIDATED = "not_validated"
export const VALIDATED = "validated"

const api_roadmap_create = `${prefixApi}roadmaps/create/`
const api_send_questions_to_roadmap = `${prefixApi}roadmaps/add_questions/`
const api_get_questions_by_rmid = `${prefixApi}questions/retrieve_by_roadmap/`
const api_getchoises = `${prefixApi}choices/retrieve_by_question/`
const api_update_roadmap_name = `${prefixApi}roadmaps/update/`
const api_submit_ans_by_rm = prefixApi + "roadmaps/submit_answer/"
const api_update_the_questions = prefixApi + "roadmaps/update_supervision/"

export async function createNewRoadMap(idSv = "", id_title) {
  const date_time = await getTheTime()
  var details = {
    datetime: date_time.datetime,
    id_supervision: idSv,
    id_title,
  }
  var formBody = []
  for (var property in details) {
    var encodedKey = encodeURIComponent(property)
    var encodedValue = encodeURIComponent(details[property])
    formBody.push(encodedKey + "=" + encodedValue)
  }
  formBody = formBody.join("&")
  return fetch(api_roadmap_create, {
    method: "POST",
    headers: prefixHeaderObj,
    body: formBody,
  })
}

export async function send_quesions_to_roadmap(id = "", arr) {
  var details = {
    questions_array: JSON.stringify(arr),
  }
  var formBody = []
  for (var property in details) {
    var encodedKey = encodeURIComponent(property)
    var encodedValue = encodeURIComponent(details[property])
    formBody.push(encodedKey + "=" + encodedValue)
  }
  formBody = formBody.join("&")
  const res = await fetch(api_send_questions_to_roadmap + `${id}`, {
    method: "POST",
    headers: prefixHeaderObj,
    body: formBody,
  })
  return await res.json()
}

export const get_questions_by_rmid = (rmId) => {
  return fetch(api_get_questions_by_rmid + `${rmId}`, {
    method: "GET",
    headers: prefixHeaderObj,
    // body: formBody,
  })
}

export const getchoises_by_rmid = (rmId, idq) => {
  return fetch(api_getchoises + `${idq}/0/${rmId}`, {
    method: "GET",
    headers: prefixHeaderObj,
    // body: formBody,
  })
}

export function updateRoadmapName(rmId = "", name = "") {
  if (name.length === 0) return new Error("name must be not empty")
  var details = {
    name: name,
    // status,
  }
  var formBody = []
  for (var property in details) {
    var encodedKey = encodeURIComponent(property)
    var encodedValue = encodeURIComponent(details[property])
    formBody.push(encodedKey + "=" + encodedValue)
  }
  formBody = formBody.join("&")
  return fetch(api_update_roadmap_name + `${rmId}`, {
    method: "PUT",
    headers: prefixHeaderObj,
    body: formBody,
  })
}

export function update_selected_question_status(
  rmId = "",
  status = "questions_added"
) {
  var details = {
    status,
  }
  var formBody = []
  for (var property in details) {
    var encodedKey = encodeURIComponent(property)
    var encodedValue = encodeURIComponent(details[property])
    formBody.push(encodedKey + "=" + encodedValue)
  }
  formBody = formBody.join("&")
  return fetch(api_update_roadmap_name + `${rmId}`, {
    method: "PUT",
    headers: prefixHeaderObj,
    body: formBody,
  })
}

export function update_roadmap_to_validated(rmId = "", status = "validated") {
  var details = {
    status,
  }
  var formBody = []
  for (var property in details) {
    var encodedKey = encodeURIComponent(property)
    var encodedValue = encodeURIComponent(details[property])
    formBody.push(encodedKey + "=" + encodedValue)
  }
  formBody = formBody.join("&")
  return fetch(api_update_roadmap_name + `${rmId}`, {
    method: "PUT",
    headers: prefixHeaderObj,
    body: formBody,
  })
}

export function update_roadmap_to_abandoned(rmId = "", status = "abandoned") {
  var details = {
    status,
  }
  var formBody = []
  for (var property in details) {
    var encodedKey = encodeURIComponent(property)
    var encodedValue = encodeURIComponent(details[property])
    formBody.push(encodedKey + "=" + encodedValue)
  }
  formBody = formBody.join("&")
  return fetch(api_update_roadmap_name + `${rmId}`, {
    method: "PUT",
    headers: prefixHeaderObj,
    body: formBody,
  })
}

export function update_selected_threats(rmId = "", selectedThreats = "") {
  var details = {
    selected_threats: selectedThreats,
  }
  var formBody = []
  for (var property in details) {
    var encodedKey = encodeURIComponent(property)
    var encodedValue = encodeURIComponent(details[property])
    formBody.push(encodedKey + "=" + encodedValue)
  }
  formBody = formBody.join("&")
  return fetch(api_update_roadmap_name + `${rmId}`, {
    method: "PUT",
    headers: prefixHeaderObj,
    body: formBody,
  })
}
export function update_lst_question_idx(rmId = "", lst_idx = "") {
  var details = {
    last_question_index: lst_idx,
  }
  var formBody = []
  for (var property in details) {
    var encodedKey = encodeURIComponent(property)
    var encodedValue = encodeURIComponent(details[property])
    formBody.push(encodedKey + "=" + encodedValue)
  }
  formBody = formBody.join("&")
  return fetch(api_update_roadmap_name + `${rmId}`, {
    method: "PUT",
    headers: prefixHeaderObj,
    body: formBody,
  })
}

export function submit_ans_by_rm(idC = "", idQ = "", rmId = "") {
  var details = {
    id_roadmap: rmId,
    id_question: idQ,
    id_choice: idC,
  }
  var formBody = []
  for (var property in details) {
    var encodedKey = encodeURIComponent(property)
    var encodedValue = encodeURIComponent(details[property])
    formBody.push(encodedKey + "=" + encodedValue)
  }
  formBody = formBody.join("&")
  return fetch(api_submit_ans_by_rm + `${rmId}/${idQ}/${idC}`, {
    method: "POST",
    headers: prefixHeaderObj,
    body: formBody,
  })
}

export function update_the_questions(rmId = "", arr) {
  var details = {
    questions_array: JSON.stringify(arr),
  }
  var formBody = []
  for (var property in details) {
    var encodedKey = encodeURIComponent(property)
    var encodedValue = encodeURIComponent(details[property])
    formBody.push(encodedKey + "=" + encodedValue)
  }
  formBody = formBody.join("&")
  return fetch(api_update_the_questions + `${rmId}`, {
    method: "POST",
    headers: prefixHeaderObj,
    body: formBody,
  })
}
const api_check_rm_status = prefixApi + "roadmaps/status/"

export function get_the_status(idRm = "") {
  return fetch(api_check_rm_status + `${idRm}`, {
    method: "GET",
    headers: prefixHeaderObj,
    // body: formBody,
  })
}

const deleteSV_API = `${prefixApi}supervisions/delete/`
export async function deleteSuperVisonWithID(id) {
  const res = fetch(deleteSV_API + id, {
    method: "DELETE",
    headers: prefixHeaderObj,
  })
  return await (await res).json()
}
const deleteRM_API = `${prefixApi}roadmaps/delete/`
export async function deleteRoadmapWithID(id) {
  const res = fetch(deleteRM_API + id, {
    method: "DELETE",
    headers: prefixHeaderObj,
  })
  return await (await res).json()
}
