import { CircularProgress } from "@material-ui/core"
import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { useHistory } from "react-router"
import { get_protect_deng } from "../../../services/chart"
import { getContent } from "../../../services/Content"
import BackBtn from "../../utilities/Buttons/BackBtn"
import CustomNextBtn from "../../utilities/Buttons/NextButton"
import BarChartUtl from "./BarChartUtl"
// looks like tut4
import "./tut4.scss"

function Tut6({ svId }) {
  const [chartData, setchartData] = useState([])
  const [loading, setloading] = useState(false)
  const [chartNames, setchartNames] = useState([])

  const [title, settitle] = useState("")
  const [firstText, setfirstText] = useState("")
  const [secondText, setsecondText] = useState("")
  const [nextBtnText, setnextBtnText] = useState("")
  const [prevBtnText, setprevBtnText] = useState("")

  const go = useHistory()

  useEffect(() => {
    ;(async () => {
      setloading(true)
      getPageContent()
      await getThedata()
    })()
  }, [])

  const getPageContent = () => {
    getContent("tutorial-6").then((data) => {
      const arr = data.content
      settitle(arr[0].value)
      setfirstText(arr[1].value)
      setsecondText(arr[2].value)
      setprevBtnText(arr[3].value)
      setnextBtnText(arr[4].value)
    })
  }

  async function getThedata() {
    const res = await get_protect_deng(svId.id)
    const datares = await res.json()
    setchartNames(() => datares.data.map((item) => item.name))
    setchartData(() =>
      [...datares.data].map((item, idx) => ({
        name: "points",
        safety: parseInt(item.default_safety_percentage, 10),
        dangerousity: parseInt(item.threat_dangerousity_percentage, 10),
        text: idx + 1,
      }))
    )
    setloading(false)
  }
  function getTheColors(i) {
    return "blue"
  }
  function getTheColors2(i) {
    return "red"
  }

  if (loading) {
    return (
      <CircularProgress
        size={100}
        thickness={4}
        style={{ color: "#ff6500", margin: "auto" }}
      />
    )
  }
  return (
    <div>
      <div className="tut4__container">
        <p className="text" dangerouslySetInnerHTML={{ __html: firstText }}></p>
        <h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
        <div className="barchart__parent">
          <BarChartUtl
            width="75%"
            chartData={chartData}
            getTheColors={getTheColors}
            both={true}
            getTheColors2={getTheColors2}
            keyOfBar1="safety"
            keyOfBar2="dangerousity"
            hideX={true}
          />
          <div className="chartnames__">
            {chartNames.map((item) => (
              <p key={item}>{item}</p>
            ))}
          </div>
        </div>
        <p
          className="text"
          dangerouslySetInnerHTML={{ __html: secondText }}
        ></p>
        <div className="btn__holder">
          <BackBtn onClick={() => go.push("/home/questions_list/tut5")}>
            <span dangerouslySetInnerHTML={{ __html: nextBtnText }}></span>
          </BackBtn>
          <CustomNextBtn onClick={() => go.push("/home/questions_list/tut7")}>
            <span dangerouslySetInnerHTML={{ __html: prevBtnText }}></span>
          </CustomNextBtn>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ svId }) => ({ svId })
export default connect(mapStateToProps)(Tut6)
