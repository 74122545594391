import {
  SET_USER,
  SET_USER_ID,
  SET_USER_ID_AUDIT,
  SET_USER_ID_COMPANY,
  SET_USER_PROFILE_NAME,
  SET_USER_ID_ROLE,
} from "../constants"

const initStateUser = {
  user: null,
}

const id_userIntialState = {
  user_id: null,
  user_id_company: null,
  id_audit: null,
}

const initStateUserProfileName = {
  profileName: null,
}
export const userReducer = (state = initStateUser, action) => {
  switch (action.type) {
    case SET_USER:
      return { ...state, user: action.user }

    default:
      return state
  }
}

export const userIdReducer = (state = id_userIntialState, action) => {
  switch (action.type) {
    case SET_USER_ID:
      return { ...state, user_id: action.user_id }

    case SET_USER_ID_COMPANY:
      return { ...state, user_id_company: action.user_id_company }

    case SET_USER_ID_ROLE:
      return { ...state, user_id_role: action.user_id_role }

    case SET_USER_ID_AUDIT:
      return { ...state, id_audit: action.id }

    default:
      return state
  }
}

export const userProfileNameReducer = (
  state = initStateUserProfileName,
  action
) => {
  switch (action.type) {
    case SET_USER_PROFILE_NAME:
      return { ...state, profileName: action.profileName }

    default:
      return state
  }
}
