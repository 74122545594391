export const CUSTOM_TABLE_STYLE = {
  tableWrapper: {
    style: {
      borderRadius: "4px",
      display: "flex",
    },
  },
  table: {
    style: {
      maxHeight: "700px",
      borderRadius: "4px",
      backgroundColor: "none",
    },
  },
  headRow: {
    style: {
      fontSize: "1rem",
      color: "#ffffff",
      backgroundColor: "rgba(51, 51, 51)",
      borderBottomStyle: "unset",
      minHeight: "70px",
      position: "sticky",
    },
  },
  rows: {
    style: {
      color: "#fff",
      fontSize: "1rem",
      backgroundColor: "#000000",
      minHeight: "70px",
    },
  },
  cells: {
    style: {
      whiteSpace: "unset",
    },
  },
  noData: {
    style: {
      color: "black",
      backgroundColor: "black",
      minHeight: "100px",
    },
  },
}
