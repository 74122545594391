import React from "react"
import { Button } from "@material-ui/core"
import "./styles.scss"

const CustomNextBtn = (props) => {
  return (
    <div className="custom-next-btn">
      <Button
        className={props.styleBtn}
        type={props.btnType}
        onClick={props.clickBtn}
        {...props}
      >
        {props.children}
      </Button>
    </div>
  )
}

export default CustomNextBtn
