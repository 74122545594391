import React, { useEffect, useState } from "react"
import { useHistory } from "react-router"

import { getContent } from "../../../services/Content"

import { CircularProgress } from "@material-ui/core"
import BackBtn from "../../utilities/Buttons/BackBtn"

import "./page1styles.scss"

function Page4Tut() {
  const [firstText, setfirstText] = useState("")
  const [title, settitle] = useState("")
  const [secText, setsecText] = useState("")
  const [prevBtn, setprevBtn] = useState("")

  const [loading, setloading] = useState(false)

  useEffect(() => {
    getAllPageContent()
  }, [])

  const getAllPageContent = () => {
    setloading(true)
    getContent("rm_tutorial_4").then((data) => {
      const arr = data.content
      setfirstText(arr[0].value)
      settitle(arr[1].value)
      setsecText(arr[2].value)
      setprevBtn(arr[3].value)
      setloading(false)
    })
  }
  const go = useHistory()

  if (loading) {
    return (
      <CircularProgress
        size={80}
        thickness={4}
        style={{ color: "#ff6500", marginRight: "3px" }}
      />
    )
  }
  return (
    <div className="page__container">
      <p
        className="top__text"
        dangerouslySetInnerHTML={{ __html: firstText }}
      ></p>
      <div className="middle__text">
        <h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
      </div>
      <p
        className="down__text"
        dangerouslySetInnerHTML={{ __html: secText }}
      ></p>
      <div className="btn__holder">
        <BackBtn onClick={() => go.push("/home/roadmap/tut3")}>
          <span dangerouslySetInnerHTML={{ __html: prevBtn }}></span>
        </BackBtn>
      </div>
    </div>
  )
}

export default Page4Tut
