import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Bar } from "react-chartjs-2"
import "./Style.scss"
import { CircularProgress } from "@material-ui/core"

import { v4 } from "uuid"
import {
  get_chart,
  get_protect_deng,
  get_risk_value,
  get_spcific_bar_info,
} from "../../../services/chart"
import { useHistory, useRouteMatch } from "react-router"
import { get_second_connection } from "../../../services/secondconnection"
import {
  get_questions_by_rmid,
  get_the_status,
} from "../../../services/roadmap"
import {
  set_questions_finished_once,
  set_sv_id,
  set_sv_name,
} from "../../../redux/actions/superVision"
import { createNewSupervision } from "../../../services/superVision"
import NewAlert from "../../utilities/Modals/handmadeAlert/NewAlert"

import {
  set_roadmap_id,
  set_roadmap_name,
} from "../../../redux/actions/roadmap"
import { getContent } from "../../../services/Content"
import InfoModel from "../../utilities/Modals/infoModel"
import ModalModified from "../../utilities/Modals/infoModel/ModalModified"

// Images
import pdfFile from "../../../images/pdf_file.png"
import infoIcon from "../../../images/info-selected.png"
import infoIcons from "../../../images/not_checked.png"
import spcificbarImg from "../../../images/info-selected.png"
import ProcessModal from "../../utilities/Modals/ProcessModal"
import { set_log_time } from "../../../redux/actions/timeAction"
import { getSuperVisionTheFile } from "../../../services/files"
import { update_terms } from "../../../services/terms"
import SpeedChart from "./speedmeterchart/SpeedChart"
import TitleChartComp from "./chart_comps/TitleChartComp"
import IconsUnderChart from "./chart_comps/IconsUnderChart"
import AideButtonStaff from "./chart_comps/AideButtonStaff"
import RightOfChart from "./chart_comps/RightOfChart"
import {
  getRiskColor,
  optionsOfBarChart,
  handleTheTypeOfModels,
} from "./barchartHelper"
import NewSVmodal from "../../utilities/Modals/NewSVmodal/NewSVmodal"

const BarChart = ({
  svId,
  dispatch,
  footer,
  userId,
  roadMapId,
  checkTheTimeGab,
  handleLogOut,
}) => {
  useEffect(() => {
    handleTheautologout()
  }, [])
  function handleTheautologout() {
    if (checkTheTimeGab()) {
      handleLogOut()
    } else {
      dispatch(set_log_time(new Date().getTime()))
    }
  }
  const [curType, setcurType] = useState(0)
  const [leftInfo, setleftInfo] = useState()
  const [infoIdx, setinfoIdx] = useState(0)
  const [numOfBars, setnumOfBars] = useState(10)

  const [barNames, setBarNames] = useState([])
  const [chartData, setchartData] = useState([])

  const [chartTypesContent, setchartTypesContent] = useState([])

  const [openLeftInfo, setopenLeftInfo] = useState(false)
  const [loadingChart, setloadingChart] = useState(false)
  const [loading, setloading] = useState(false)
  const [loadingLeftInfo, setloadingLeftInfo] = useState(false)
  const [loadingRightChartTypes, setloadingRightChartTypes] = useState(false)
  const [loadingCreateContinueRmTexts, setloadingCreateContinueRmTexts] =
    useState(false)
  const [newSVStatus, setNewSVStatus] = useState(false)
  const [infoModal1, setinfoModal1] = useState(false)
  const [disableContiueRoadmap, setdisableContiueRoadmap] = useState(false)
  const [disableStartRoadMap, setDisableStartRoadMap] = useState(false)
  const [pdfLoading, setpdfLoading] = useState(false)
  const [finishModalStatus, setfinishModalStatus] = useState(false)

  const [textOfAlert, settextOfAlert] = useState("")
  const [leftInfoText, setLeftInfoText] = useState("")
  const [createContinueTitle, setcreateContinueTitle] = useState("")
  const [reminderText, setReminderText] = useState("")
  const [createRoadmapText, setCreateRoadmapText] = useState("")
  const [continueRoadmapText, setContinueRoadmapText] = useState("")
  const [auditTitle, setAuditTitle] = useState("")
  const [newSupervisionText, setNewSupervisionText] = useState("")
  const [paramTitle, setParamTitle] = useState("")
  const [protectionTitle, setProtectionTitle] = useState("")
  const [dangerTitle, setDangerTitle] = useState("")
  const [protectionDangerTitle, setProtectionDangerTitle] = useState("")
  const [labelProtection, setLabelProtection] = useState("")
  const [labelDanger, setLabelDanger] = useState("")
  const [pdfName, setPDFname] = useState("")
  const [chartsRightTitle, setChartsRightTitle] = useState("")
  const [levelParamText, setLevelParamText] = useState("")
  const [pdfText, setPdfText] = useState("")
  const [infoText, setInfoText] = useState("")
  const [alertFinishRmtext, setalertFinishRmtext] = useState("")
  const [riskValText, setriskValText] = useState("")
  const [paramModalTitle, setparamModalTitle] = useState("")
  const [alerturDataWillBeRomovedText, setalertUrDataWillBeRomovedText] =
    useState("")
  const [modalInfoTitle, setmodalInfoTitle] = useState("")
  const [speedmeterText, setspeedmeterText] = useState("")

  const [spicificBarInfo, setspicificBarInfo] = useState([])
  const [spcificBarIdx, setspcificBarIdx] = useState(-1)
  const [theFristChartBarIdx, settheFristChartBarIdx] = useState(-1)

  const [removeAlldata, setremoveAlldata] = useState(false)
  let { url } = useRouteMatch()
  const go = useHistory()

  async function getthRisk() {
    const res = await get_risk_value(userId.user_id_company)
    setrisk(res.risk)
  }
  const [risk, setrisk] = useState(0)

  useEffect(() => {
    ;(async () => {
      await getthRisk()
      if (svId.tut === 0) {
        go.push("/home/questions_list/tut1")
      }
    })()
  }, [])
  useEffect(() => {
    ;(async () => {
      setloadingLeftInfo(true)
      setloadingRightChartTypes(true)
      setloadingCreateContinueRmTexts(true)
      setloadingChart(true)

      const resWhereTo = await handleCheckRoadMap()
      const statusOfRoadMap = await getTheRoadMapStatus()

      if (resWhereTo?.supervision?.status !== "finished") {
        go.push("/home/questions_list")
      }

      if (
        resWhereTo.roadmap !== "not_found" &&
        roadMapId.name !== null &&
        resWhereTo?.roadmap?.status === "validated" &&
        statusOfRoadMap === "finished"
      ) {
        setdisableContiueRoadmap(true)
      }
      if (resWhereTo?.roadmap?.status === "abandoned") {
        setdisableContiueRoadmap(true)
      }
      if (
        resWhereTo.roadmap === "not_found" ||
        resWhereTo?.roadmap?.status === "validated" ||
        resWhereTo?.roadmap?.status === "abandoned"
      ) {
        setDisableStartRoadMap(true)
      }

      getcontentForAllThePage()

      getFirstChart()
    })()
  }, [])

  function getFirstChart() {
    get_chart(svId.id)
      .then((response) => response.json())
      .then((res) => {
        let labels = [...Array(11)].map((item, i) => i)
        setnumOfBars(11)
        // ----datasets
        const parameter = "Paramètres de l'audit"
        let label = parameter
        let data = Array(11).fill(0)
        // data.length = 11;
        let backgroundColor = [
          "red",
          "red",
          "red",
          "red",
          "orange",
          "orange",
          "yellow",
          "yellow",
          "yellow",
          "green",
          "green",
        ]
        res.data.map((item, i) => {
          data[item.points] = item.no_of_answers
        })

        let datasets = [
          {
            label,
            data,
            backgroundColor,
            borderColor: "#ff4600",
            // borderWidth: "1",
            barPercentage: 0.3,
            borderRadius: "7",
          },
        ]

        setchartData([{ labels, datasets }])
        setloadingChart(false)
      })
    return () => {
      setchartData([])
    }
  }

  function getcontentForAllThePage() {
    getContent("right_menu_chart_type").then((data) => {
      setchartTypesContent([1, ...data.content])
      setloadingRightChartTypes(false)
    })
    getContent("chart_left_info").then((data) => {
      setLeftInfoText(data.content[0].value)
      setloadingLeftInfo(false)
    })

    getContent("charts_content").then((data) => {
      const arr = data.content
      setParamTitle(arr[0].value)
      setProtectionTitle(arr[1].value)
      setDangerTitle(arr[2].value)
      setProtectionDangerTitle(arr[3].value)
      setLabelDanger(arr[4].value)
      setLabelProtection(arr[5].value)
      setPDFname(arr[6].value)
      setChartsRightTitle(arr[7].value)
      setLevelParamText(arr[8].value)
      setPdfText(arr[9].value)
      setInfoText(arr[10].value)
      setalertFinishRmtext(arr[11].value)
      setalertUrDataWillBeRomovedText(arr[12].value)
      setriskValText(arr[13].value)
      setspeedmeterText(arr[14].value)
      setparamModalTitle(arr[15].value)
    })

    getContent("right_chart_create_continue_roadmap").then((data) => {
      const { content } = data
      setcreateContinueTitle(content[0].value)
      setReminderText(content[1].value)
      setCreateRoadmapText(content[2].value)
      setContinueRoadmapText(content[3].value)
      setAuditTitle(content[4].value)
      setNewSupervisionText(content[5].value)
      setloadingCreateContinueRmTexts(false)
    })
  }

  async function get_both_charts() {
    if (chartData.length >= 4) return
    const response = await get_protect_deng(svId.id)
    const res = await response.json()
    let labels = []
    let dataP = []
    let dataD = []
    let backgroundColorP = "#0d90f5"
    let backgroundColorD = "#ff4600"
    setnumOfBars(res.data.length)
    res.data.map((item, i) => {
      setspicificBarInfo((arr) => [
        ...arr,
        { description: item.description, name: item.name },
      ])
      labels.push(item.name)
      dataD.push(item.threat_dangerousity_percentage)
      setBarNames(labels)
      dataP.push(item.default_safety_percentage)
    })

    let datasetsP = [
      {
        label: "Protection",
        data: dataP,
        backgroundColor: backgroundColorP,
        // borderWidth: "1",
        barPercentage: 0.6,
        borderRadius: "7",
      },
    ]
    let datasetsD = [
      {
        label: "dangerous",
        data: dataD,
        backgroundColor: backgroundColorD,
        // borderWidth: "1",
        barPercentage: 0.6,
        borderRadius: "7",
      },
    ]
    let datasetsBoth = [
      {
        label: "Protection",
        data: dataP,
        backgroundColor: backgroundColorP,
        // borderWidth: "1",
        barPercentage: 0.8,
        borderRadius: "7",
      },
      {
        label: "dangerous",
        data: dataD,
        backgroundColor: backgroundColorD,
        // borderWidth: "1",
        barPercentage: 0.8,
        borderRadius: "7",
      },
    ]

    setchartData([
      ...chartData,
      { labels, datasets: datasetsP },
      { labels, datasets: datasetsD },
      { labels, datasets: datasetsBoth },
    ])
  }

  async function handlespcificBarInfo(idx = "") {
    setloading(true)
    setopenLeftInfo(true)
    settheFristChartBarIdx(idx - 1)
    setmodalInfoTitle(`${paramModalTitle} ${idx - 1}`)
    return get_spcific_bar_info(svId.id, idx - 1)
      .then((res) => res.json())
      .then((res) => {
        setleftInfo(res.data)
        setinfoIdx(idx)
        setloading(false)
      })
  }
  function handleTypeSpcificIcon(idx) {
    setspcificBarIdx(idx)
  }
  function handleTheAdieFunc() {
    setinfoModal1(true)
  }
  async function getTheRoadMapStatus() {
    const response = await get_the_status(roadMapId.id)
    const res = await response.json()
    return res.status
  }

  async function handleContinueRoadMap() {
    const data = await handleCheckRoadMap()
    const statusOfRoadMap = await getTheRoadMapStatus()
    let selectedThearts = []
    if (data.roadmap.selected_threats !== "") {
      selectedThearts = JSON.parse(data.roadmap.selected_threats)
    }
    if (data.roadmap !== "not_found") {
      if (data.roadmap.last_question_index === null) {
        if (data.roadmap.status === "questions_added") {
          go.push({
            pathname: "/home/questions_list/chart2/seeimprovment",
          })
        } else {
          go.push({
            pathname: "/home/questions_list/chart2",
            state: {
              selected_threats: selectedThearts,
            },
          })
        }
      } else {
        if (statusOfRoadMap !== "finished") {
          go.push({
            pathname: "/home/questions_list/chart2/process",
          })
        } else {
          if (roadMapId.name === null) {
            go.push({
              pathname: "/home/finish_roadmap",
            })
          } else {
            go.push({
              pathname: "/home/after_finish_roadmap/checkbox-roadmap",
            })
          }
        }
      }
    }
  }

  async function handleCheckRoadMap() {
    const response = await get_second_connection(userId.user_id)
    const res = await response.json()
    dispatch(set_sv_id(res.supervision.id))
    return res
  }

  async function getTheQuestions() {
    const resopnse = await get_questions_by_rmid(roadMapId.id)
    const res = await resopnse.json()
    return res
  }

  async function createTheSuperVision() {
    const response = await createNewSupervision(
      "null",
      userId.user_id_company,
      0,
      0,
      userId.id_audit
    )
    const res = await response.json()
    return res
  }

  function skipToList() {
    createTheSuperVision()
      .then((data) => {
        dispatch(set_sv_id(data.id))
      })
      .then(async () => {
        dispatch(set_roadmap_id(null))
        dispatch(set_roadmap_name(null))
        dispatch(set_sv_name(null))
        await update_terms({ questions_finished_once: 0 }, userId.user_id)
        dispatch(set_questions_finished_once(0))
      })
      .then(() => {
        go.push("/home/questions_list")
      })
      .catch((e) => {
        console.log(e)
      })
  }
  async function handleCreateNewSVmodal() {
    skipToList()
  }

  async function handleNewSuperVison() {
    // here u will show the alert and when user click ok run skipToList();
    return setfinishModalStatus(true)
  }

  async function handleOkNewSV() {
    const statusOfRoadMap = await getTheRoadMapStatus()
    if (
      statusOfRoadMap === "not_started" ||
      statusOfRoadMap === "in_progress"
    ) {
      skipToList()
    } else if (statusOfRoadMap === "finished") {
      if (roadMapId.name === null) {
        go.push({
          pathname: "/home/finish_roadmap",
        })
      } else {
        go.push({
          pathname: "/home/after_finish_roadmap/checkbox-roadmap",
        })
      }
    }
    setNewSVStatus(false)
  }

  function handleCloseInfoModal1() {
    setinfoModal1(false)
  }

  function getTheNameAndDescription() {
    if (spicificBarInfo[spcificBarIdx] === undefined)
      return { description: "", name: "" }
    return {
      description: spicificBarInfo[spcificBarIdx].description,
      name: spicificBarInfo[spcificBarIdx].name,
    }
  }

  async function getSvPdfFile() {
    setpdfLoading(true)
    let res = await getSuperVisionTheFile(svId.id, userId.user_id_company)
    setpdfLoading(false)
  }

  function handleCloseSpecificIconModal() {
    settheFristChartBarIdx(-1)
    setleftInfo(null)
  }

  return (
    <div className="chart-container">
      <NewSVmodal
        type="create_sv_modal_content"
        finishModalStatus={finishModalStatus}
        handleCloseFinishModal={() => setfinishModalStatus(false)}
        // validateRoadmap={true}
        handleComplete={handleCreateNewSVmodal}
      />
      <NewAlert
        handleOkNewSV={handleOkNewSV}
        handleCloseNewSV={() => {
          setNewSVStatus(false)
          setremoveAlldata(false)
        }}
        removeAlldata={removeAlldata}
        text={textOfAlert}
        newSVStatus={newSVStatus}
      />
      <InfoModel
        infoModal={infoModal1}
        type={`${handleTheTypeOfModels(curType)}`}
        handleCloseInfoModal={handleCloseInfoModal1}
      />
      <ModalModified
        openModel={spcificBarIdx === -1 ? false : true}
        type={`${handleTheTypeOfModels(curType)}`}
        handleCloseInfoModal={() => setspcificBarIdx(-1)}
        description={getTheNameAndDescription().description}
        name={getTheNameAndDescription().name}
      />
      <ProcessModal
        openProcessModel={theFristChartBarIdx === -1 ? false : true}
        data={leftInfo}
        handleCloseProcessInfoModal={handleCloseSpecificIconModal}
        title={modalInfoTitle}
      />
      <TitleChartComp
        curType={curType}
        dangerTitle={dangerTitle}
        paramTitle={paramTitle}
        protectionDangerTitle={protectionDangerTitle}
        riskValText={riskValText}
        protectionTitle={protectionTitle}
      />
      {curType === 1 && (
        <p className="num-of-param-text">Nombre de pratiques</p>
      )}
      {curType === 2 && (
        <p className="num-of-param-text">Niveau de protection</p>
      )}
      {curType === 3 && <p className="num-of-param-text">Niveau de danger</p>}

      <div className="sub-container">
        <div
          style={curType === 0 ? { marginLeft: "0" } : null}
          className="middle"
        >
          {loadingChart ? (
            <div style={{ textAlign: "center", marginTop: "130px" }}>
              <CircularProgress
                size={100}
                thickness={4}
                style={{ color: "#ff6500", margin: "auto" }}
              />
            </div>
          ) : (
            <div>
              <div className="chart-container-middle">
                {curType >= 1 && (
                  <div
                    className={curType === 1 ? "first-chart" : "rest-of-charts"}
                  >
                    <Bar
                      data={chartData[curType - 1]}
                      width={110}
                      height={500}
                      options={optionsOfBarChart(curType)}
                    />
                  </div>
                )}
                {curType === 0 && (
                  <div className="speedchart__container">
                    <SpeedChart
                      type="main-chart"
                      size="400px"
                      percValue={risk / 100}
                    />
                    {curType === 0 && (
                      <p
                        style={{ color: getRiskColor(risk) }}
                        className="risk-value"
                      >
                        <span
                          dangerouslySetInnerHTML={{ __html: speedmeterText }}
                        ></span>{" "}
                        {risk}
                      </p>
                    )}
                  </div>
                )}
                {curType === 1 && <p>Niveau de sécurité</p>}
              </div>
              {/* Info buttons */}
              {
                <>
                  {curType >= 2 && (
                    <div className="bar-name">
                      {barNames.map((item) => (
                        <div key={v4()}>
                          <span>{item}</span>
                        </div>
                      ))}
                    </div>
                  )}

                  {curType >= 1 && (
                    <IconsUnderChart
                      curType={curType}
                      numOfBars={numOfBars}
                      spcificBarIdx={spcificBarIdx}
                      theFristChartBarIdx={theFristChartBarIdx}
                      spcificbarImg={spcificbarImg}
                      infoIcons={infoIcons}
                      handleTypeSpcificIcon={handleTypeSpcificIcon}
                      handlespcificBarInfo={handlespcificBarInfo}
                    />
                  )}
                </>
              }

              <AideButtonStaff
                getSvPdfFile={getSvPdfFile}
                pdfFile={pdfFile}
                pdfText={pdfText}
                curType={curType}
                handleTheAdieFunc={handleTheAdieFunc}
                infoText={infoText}
                infoIcon={infoIcon}
                labelProtection={labelProtection}
                labelDanger={labelDanger}
                pdfLoading={pdfLoading}
              />
              <div className="audit-copyright">
                <p>Audit de cybersécurité - © Cybalgoris</p>
              </div>
            </div>
          )}
        </div>
        {/* Right side info  */}
        <RightOfChart
          url={url}
          chartsRightTitle={chartsRightTitle}
          loadingRightChartTypes={loadingRightChartTypes}
          chartTypesContent={chartTypesContent}
          setspcificBarIdx_={(idx) => setspcificBarIdx(idx)}
          settheFristChartBarIdx_={(idx) => settheFristChartBarIdx(idx)}
          setcurType_={(type) => setcurType(type)}
          get_both_charts={get_both_charts}
          setnumOfBars_={(numBras) => setnumOfBars(numBras)}
          curType={curType}
          loadingCreateContinueRmTexts={loadingCreateContinueRmTexts}
          createContinueTitle={createContinueTitle}
          disableStartRoadMap={disableStartRoadMap}
          createRoadmapText={createRoadmapText}
          roadMapId={roadMapId}
          continueRoadmapText={continueRoadmapText}
          handleNewSuperVison={handleNewSuperVison}
          newSupervisionText={newSupervisionText}
          handleContinueRoadMap={handleContinueRoadMap}
          disableContiueRoadmap={disableContiueRoadmap}
          auditTitle={auditTitle}
          svId={svId}
        />
      </div>
    </div>
  )
}

const mapStateToProps = ({ svId, footer, userId, roadMapId }) => ({
  svId,
  footer,
  userId,
  roadMapId,
})

export default connect(mapStateToProps)(BarChart)
