import { Button } from "@material-ui/core"
import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { useHistory } from "react-router"
import Logo from "../../../images/logo.png"
import {
  set_user_id,
  set_user_id_company,
  set_user_id_role,
  set_user_profile_name,
} from "../../../redux/actions/user"
import UserAvatr from "../../avatar/UserAvatr"

import {
  set_state_from_tuts_humbrger,
  set_sv_id,
  set_sv_name,
} from "../../../redux/actions/superVision"
import { set_term_1 } from "../../../redux/actions/term"
import {
  set_roadmap_id,
  set_roadmap_name,
} from "../../../redux/actions/roadmap"
import { getContent } from "../../../services/Content"
import { get_user_data, update_user_logout } from "../../../services/auth"

import MenuItem from "@material-ui/core/MenuItem"

import { set_user_img } from "../../../redux/actions/profileAction"

import { withStyles } from "@material-ui/core/styles"
import Menu from "@material-ui/core/Menu"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import HumbergerImg from "../../../images/humberger.png"
import "./styles.scss"
import { prefix_api_set_img_content } from "../../../services/prefix"
const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    minWidth: 150,
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
))

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      // backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
    "&:hover": {
      "& .MuiListItem-button": {
        color: "red",
      },
    },
  },
  ".Component-paper-4": {
    minWidth: "150px",
  },
}))(MenuItem)

const Header = ({ dispatch, userId, profile, profileName, svId }) => {
  const history = useHistory()
  const [logoutBtn, setlogoutBtn] = useState("")
  const [historyBtn, sethistoryBtn] = useState("")
  const [defaultUserNmae, setdefaultUserNmae] = useState("")
  const [tutSv, settutSv] = useState("")
  const [tutsroadmap, settutsroadmap] = useState("")
  const styleLinks = {
    textDecoration: "none",
    color: "white",
    width: "100%",
    textAlign: "center",
    height: "50 !important",
  }
  const [userImg, setuserImg] = useState(profile.src)
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)
  const prevOpen = useRef(open)
  const [name, setname] = useState([])

  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorElHumberger, setAnchorElHumberger] = useState(null)

  function handleTheProfileName() {
    setname(profileName.profileName)
  }
  const [hideTutorial, sethideTutorial] = useState(true)
  useEffect(() => {
    sethideTutorial(svId.ques_finished_once === 0)
  }, [svId.ques_finished_once])

  const userRoleId = userId.user_id_role

  const currentPath = history.location.pathname
  const restrictedPaths = [
    "/profile/img",
    "/profile/name",
    "/profile/password",
    "/agreement-info",
    "/history",
    "/home/questions_list/tut1",
    "/home/questions_list/tut2",
    "/home/questions_list/tut3",
    "/home/questions_list/tut7",
  ]
  const canViewHeaderItems = !restrictedPaths.includes(currentPath)

  useEffect(async () => {
    handleTheProfileName()
    const res = await getContent("default_user_image")
    if (profile.src === null || profile.src === "") {
      setuserImg(prefix_api_set_img_content + res.content[0].file)
      dispatch(set_user_img(prefix_api_set_img_content + res.content[0].file))
    } else {
      setuserImg(profile.src)
    }
  }, [profile.src])

  useEffect(() => {
    setAnchorEl(null)
    setAnchorElHumberger(null)
    getAllPageContent()
  }, [])

  function getAllPageContent() {
    getContent("header_content").then((data) => {
      const arr = data.content
      sethistoryBtn(arr[0].value)
      setdefaultUserNmae(arr[1].value)
      settutSv(arr[2].value)
      settutsroadmap(arr[3].value)
    })
  }

  function handleHistory() {
    // const win = window.open("/history", "_blank");
    // if (win != null) {
    //   win.focus();
    // }
    // console.log(window.location.origin, " this is my location");
  }

  useEffect(() => {
    getContent("logout_btn").then((data) => {
      setlogoutBtn(data.content[0].value)
    })
  }, [])

  useEffect(() => {
    ;(async () => {
      if (userId.user_id === null) return
      const res = await get_user_data(userId.user_id)
      let { first_name, last_name } = res.data
      dispatch(set_user_profile_name(`${first_name} ${last_name}`))
    })()
  }, [profileName.profileName])

  function handleLogOut() {
    update_user_logout(userId.user_id)
    dispatch(set_user_id(null))
    dispatch(set_user_id_company(null))
    dispatch(set_user_id_role(null))
    dispatch(set_sv_id(null))
    dispatch(set_sv_name(null))
    dispatch(set_roadmap_name(null))
    dispatch(set_roadmap_id(null))
    dispatch(set_user_profile_name(null))
    dispatch(set_user_img(null))
    setAnchorEl(null)
    setAnchorElHumberger(null)
    dispatch(set_term_1(null))
    history.push("/auth/login")
  }

  function valid_to_go() {
    if (
      userId.user_id === null ||
      userId.user_id === "" ||
      userId.user_id_company === null ||
      userId.user_id_company === ""
    ) {
      return false
    }
    return true
  }

  // function checkPath(path) {
  //   return pathname.toString().includes(path);
  // }

  function handleSelectedItem(item) {
    switch (item) {
      case "image":
        return (() => {
          setAnchorEl(null)
          history.push("/profile/img")
        })()
      case "pass":
        return (() => {
          setAnchorEl(null)
          history.push("/profile/password")
        })()
      case "name":
        return (() => {
          setAnchorEl(null)
          history.push("/profile/name")
        })()
      case "logout":
        return handleLogOut()
      default:
        return
    }
  }

  // function handleToggleMenue() {
  //   setOpen((prevOpen) => !prevOpen);
  // }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClickHumbergerMenu = (event) => {
    setAnchorElHumberger(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setAnchorElHumberger(null)
  }

  return (
    <div className="header-container">
      <div className="audit-section">
        <img className="img-fluid" src={Logo} alt="" />
      </div>
      {valid_to_go() === true && canViewHeaderItems && (
        <div className="logout-continer">
          <div className="menu-content">
            <Button
              aria-controls="customized-menu"
              aria-haspopup="true"
              variant="contained"
              color="primary"
              onClick={handleClick}
              className="dropdown-btn"
            >
              <div className="avatar-container">
                <UserAvatr srcImg={userImg} />
              </div>

              {name !== "" ? (
                <>
                  <span>{profileName.profileName}</span>
                  <KeyboardArrowDownIcon />
                </>
              ) : (
                <span
                  dangerouslySetInnerHTML={{ __html: defaultUserNmae }}
                ></span>
              )}
            </Button>
            <StyledMenu
              id="customized-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <StyledMenuItem>
                <a
                  style={{
                    textDecoration: "none",
                    color: "white",
                    width: "100%",
                    textAlign: "center",
                  }}
                  target="_blank"
                  rel="noreferrer"
                  href={`${window.location.origin}/profile/img`}
                >
                  Photo
                </a>
              </StyledMenuItem>
              {/* <StyledMenuItem onClick={() => handleSelectedItem("name")}> */}
              <StyledMenuItem>
                <a
                  style={{
                    textDecoration: "none",
                    color: "white",
                    width: "100%",
                    textAlign: "center",
                  }}
                  target="_blank"
                  rel="noreferrer"
                  href={`${window.location.origin}/profile/name`}
                >
                  Nom
                </a>
              </StyledMenuItem>
              <StyledMenuItem>
                <a
                  style={{
                    textDecoration: "none",
                    color: "white",
                    width: "100%",
                    textAlign: "center",
                  }}
                  target="_blank"
                  rel="noreferrer"
                  href={`${window.location.origin}/profile/password`}
                >
                  Mot de passe
                </a>
              </StyledMenuItem>
              <StyledMenuItem onClick={() => handleSelectedItem("logout")}>
                Déconnexion
              </StyledMenuItem>
            </StyledMenu>
          </div>

          {userRoleId !== "2" && (
            <div className="humberger-container">
              <Button
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="contained"
                color="primary"
                onClick={handleClickHumbergerMenu}
                className="dropdown-btn"
              >
                <img className="huberger-img" src={HumbergerImg} alt="" />
              </Button>
              <StyledMenu
                id="customized-menu"
                anchorEl={anchorElHumberger}
                keepMounted
                open={Boolean(anchorElHumberger)}
                onClose={handleClose}
              >
                <StyledMenuItem
                  className="header__history"
                  onClick={handleHistory}
                >
                  <a
                    style={styleLinks}
                    target="_blank"
                    rel="noreferrer"
                    href={`${window.location.origin}/agreement-info`}
                    className="history-link"
                  >
                    Mentions légales
                  </a>
                </StyledMenuItem>
                
                <StyledMenuItem
                  className="header__history"
                  onClick={handleHistory}
                >
                  <a
                    style={styleLinks}
                    target="_blank"
                    rel="noreferrer"
                    href={`${window.location.origin}/history`}
                    className="history-link"
                    dangerouslySetInnerHTML={{ __html: historyBtn }}
                  ></a>
                </StyledMenuItem>
                
              </StyledMenu>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
const mapStateToProps = ({ userId, profile, profileName, svId }) => ({
  userId,
  profile,
  profileName,
  svId,
})
export default connect(mapStateToProps)(Header)



//todo: take out from jsx tag <StyledMenu> lowe side
// Supervision Tutorial and Roadmap Tutorial from hamburger menu
// {hideTutorial === false || svId.tut === 1 ? (
//   <>
//     <StyledMenuItem className="header__history">
//       <a
//         style={{
//           textDecoration: "none",
//           color: "white",
//           width: "100%",
//           textAlign: "center",
//           height: "50 !important",
//         }}
//         target="_blank"
//         rel="noreferrer"
//         href={`${window.location.origin}/home/questions_list/tut1`}
//         className="history-link"
//         onClick={() =>
//           dispatch(set_state_from_tuts_humbrger(1))
//         }
//         dangerouslySetInnerHTML={{ __html: tutSv }}
//       ></a>
//     </StyledMenuItem>
    
//     {/* <StyledMenuItem className="header__history">
//     <a
//       style={{
//         textDecoration: "none",
//         color: "white",
//         width: "100%",
//         textAlign: "center",
//         height: "50 !important",
//       }}
//       target="_blank"
//       rel="noreferrer"
//       href={`${window.location.origin}/home/roadmap/tut1`}
//       className="history-link"
//       onClick={() => dispatch(set_state_from_tuts_humbrger(1))}
//       dangerouslySetInnerHTML={{ __html: tutsroadmap }}
//     ></a>
//   </StyledMenuItem> */}
//   </>
// ) : null}
