import { prefixApi, prefixHeaderObj } from "./prefix";

const api_second_connection = `${prefixApi}user/where_to/`

export function get_second_connection(id = "") {
    return fetch(`${api_second_connection}${id}`, {
        method: "GET",
        headers: prefixHeaderObj,
        // body: formBody,
      });
}