import { SET_LAST_LOGIN_TIME, SET_LOGIN_TIME } from "../constants"

export const time_reducer = (
  state = { time: null, lst_login_time: null },
  action
) => {
  switch (action.type) {
    case SET_LOGIN_TIME:
      return { ...state, time: action.time }
    case SET_LAST_LOGIN_TIME:
      return { ...state, lst_login_time: action.time }
    default:
      return state
  }
}
