import {SET_LOGIN_TIME, SET_LAST_LOGIN_TIME} from '../constants'

export function set_log_time(time = "") {
    return {
        type : SET_LOGIN_TIME,
        time
    }
}

export function set_last_login_time(time = "") {
    return {
        type : SET_LAST_LOGIN_TIME,
        time
    }
}