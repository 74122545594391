import { CircularProgress } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import "./style2.scss"
import CheckBoxTerms from "../../terms/CheckBoxTerms"
import { getTheDataOfBarChartRoadMap } from "../../../services/chart"
import { useHistory } from "react-router"
import CustomNextBtn from "../../utilities/Buttons/NextButton"
import { send_quesions_to_roadmap } from "../../../services/roadmap"
import Alert from "../../utilities/Modals/alert/Alert"
import { getContent } from "../../../services/Content"
import infoIcon from "../../../images/info-selected.png"
import InfoModel from "../../utilities/Modals/infoModel"
import { set_log_time } from "../../../redux/actions/timeAction"
import { BarChart, Bar, Cell, Tooltip, ResponsiveContainer } from "recharts"
import { v4 } from "uuid"
import ExitBtn from "../../utilities/Buttons/ExitBtn"
const BarChart2 = ({
  svId,
  dispatch,
  roadMapId,
  checkTheTimeGab,
  handleLogOut,
}) => {
  const { location } = useHistory()
  const go = useHistory()
  const { id_title, newRoadMapId } = getStateFromRoute(location.state)

  const [selectedColmText, setselectedColmText] = useState("")
  const [sumSelectedColmText, setsumSelectedColmText] = useState("")

  useEffect(() => {
    handleTheautologout()
    if (!id_title || !newRoadMapId) {
      go.push("/home/questions_list/chart")
      return
    }
    getContent("select_threats_page").then((data) => {
      const { content } = data
      setTitle(content[0].value)
      setBtnText(content[1].value)
      setBtnTextInfo(content[2].value)
      setAlertText(content[3].value)
      setselectedColmText(content[4].value)
      setsumSelectedColmText(content[5].value)
    })
  }, [])

  function handleTheautologout() {
    if (checkTheTimeGab()) {
      handleLogOut()
    } else {
      dispatch(set_log_time(new Date().getTime()))
    }
  }

  const [selectedBoxIdx, setselectedBoxIdx] = useState(null)
  const [dataColordinP, setdataColordinP] = useState([])
  const [title, setTitle] = useState("")
  const [btnText, setBtnText] = useState("")
  const [btnTextInfo, setBtnTextInfo] = useState("")
  const [alertText, setAlertText] = useState("")

  const [loading, setloading] = useState(false)
  const [alertStatus, setAlertStatus] = useState(false)
  const [infoModal, setInfoModal] = useState(false)
  const [datanewCahrt, setdatanewCahrt] = useState([])

  useEffect(() => {
    setloading(true)
    getTheDataOfBarChar()
      .then((data) => {
        handleTheChartDataInState(data)
        setloading(false)
      })
      .catch((e) => console.log("something wrong", e.message))
  }, [])

  async function getTheDataOfBarChar() {
    const res = await getTheDataOfBarChartRoadMap(svId.id, id_title)
    return res.data
  }

  function getStateFromRoute() {
    if (location.state) {
      const { id_title, newRoadMapId } = location.state
      return {
        id_title,
        newRoadMapId,
      }
    }
    return {
      id_title: null,
      newRoadMapId: null,
    }
  }

  function handleTheChartDataInState(dataOfChart = []) {
    setdataColordinP([...Array(10)].map(() => "gray"))
    setdatanewCahrt(() => {
      let arr = [...Array(10)].map((_, idx) => {
        return {
          protection: 0,
          name: idx,
          ids: null,
        }
      })
      dataOfChart.forEach((item) => {
        let idx = +item.points
        if (idx < 10) {
          arr[idx].protection = +item.no_of_answers
          arr[idx].ids = item.ids
        }
      })
      return arr
    })
  }

  function sumOfRisksTillIdx(idx) {
    let sum = 0
    if (idx === null) return 0
    for (let i = 0; i <= idx; i++) sum += datanewCahrt[i].protection
    return sum
  }

  function handleCheckSpcificBox(idx) {
    if (idx === selectedBoxIdx) {
      setselectedBoxIdx(null)
      handleTheColorOfBars(null)
      return
    }
    setselectedBoxIdx(idx)
    handleTheColorOfBars(idx)
  }

  function getTheColorOfidx(idx) {
    if (idx >= 0 && idx <= 3) return "red"
    if (idx >= 4 && idx <= 5) return "orange"
    if (idx >= 6 && idx <= 8) return "yellow"
    return "green"
  }

  function handleTheColorOfBars(idx) {
    if (idx !== null)
      setdataColordinP((arr) =>
        arr.map((_, i) => (i <= idx ? getTheColorOfidx(i) : "gray"))
      )
    else setdataColordinP((arr) => arr.map(() => "gray"))
  }

  async function handleSendThecheckBoxs() {
    if (selectedBoxIdx === null) {
      return setAlertStatus(true)
    }
    try {
      let arrOfIds = []
      for (let i = 0; i <= selectedBoxIdx; i++) {
        if (datanewCahrt[i].ids) {
          arrOfIds = [...arrOfIds].concat(datanewCahrt[i].ids.split(","))
        }
      }
      await send_quesions_to_roadmap(newRoadMapId, arrOfIds)
      go.push({
        pathname: "/home/questions_list/chart2/RoadMapQuestions/",
        state: {
          newRoadMapId,
        },
      })
    } catch (error) {
      alert(
        "some thing wrong with choose the submit the checked boxes, try again"
      )
    }
  }

  function handleCloseAlert() {
    setAlertStatus(false)
  }

  function handleCloseInfoModal() {
    setInfoModal(false)
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            opacity: 0.7,
            fontSize: 12,
            backgroundColor: "black",
            color: "white",
            padding: 6,
            fontWeight: "bold",
          }}
        >
          <p>Niveau de protection : {payload[0].payload.name}</p>
          <p style={{ color: payload[0].color, fontWeight: "bolder" }}>
            Nombre de pratiques : {payload[0].payload.protection}
          </p>
        </div>
      )
    }

    return null
  }
  if (loading) {
    return (
      <div style={{ textAlign: "center" }}>
        <CircularProgress
          size={100}
          thickness={4}
          style={{ color: "#ff6500", marginRight: "3px" }}
        />
      </div>
    )
  } else {
    return (
      <div className="threats-chart">
        <ExitBtn routePage="/home/questions_list/chart/createroadmap" />

        <div className="container-checkbox">
          <Alert
            alertStatus={alertStatus}
            title={alertText}
            handleCloseAlert={handleCloseAlert}
          />
          <InfoModel
            infoModal={infoModal}
            type="app_info_select_threats"
            handleCloseInfoModal={handleCloseInfoModal}
          />
          <div className="title-container">
            <h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
          </div>
          <div className="bar-chart-conatiner">
            <div className="bar-chart">
              <ResponsiveContainer width="80%" height="100%" aspect={5}>
                <BarChart width={300} height={250} data={datanewCahrt}>
                  <Tooltip
                    content={<CustomTooltip />}
                    cursor={{ fill: "transparent" }}
                  />
                  <Bar
                    barSize={25}
                    dataKey="protection"
                    fill="#0d90f5"
                    name="protection"
                  >
                    {datanewCahrt.map((item, i) => (
                      <Cell key={v4()} fill={dataColordinP[i]} radius={5} />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
            <div className="bar-name">
              {[...Array(datanewCahrt.length)].map((_, idx) => (
                <div key={v4()}>
                  <span>{idx}</span>
                </div>
              ))}
            </div>
            <div className="spcific-bar">
              {datanewCahrt.map((item, i) => (
                <CheckBoxTerms
                  key={v4()}
                  idx={i}
                  selectedBoxIdx={selectedBoxIdx}
                  handleTheCheck={handleCheckSpcificBox}
                  item={item}
                />
              ))}
            </div>
          </div>
          <div className="realtime_info">
            <p className="p-text">
              <span
                dangerouslySetInnerHTML={{ __html: sumSelectedColmText }}
              ></span>
              <span className="value">{sumOfRisksTillIdx(selectedBoxIdx)}</span>
            </p>
          </div>
          <div className="btn-conatiner">
            <CustomNextBtn
              className="info-btn"
              onClick={() => setInfoModal(true)}
            >
              <span dangerouslySetInnerHTML={{ __html: btnTextInfo }}></span>
              <img className="info-select-icon" src={infoIcon} alt="" />
            </CustomNextBtn>
            <CustomNextBtn onClick={handleSendThecheckBoxs}>
              <span dangerouslySetInnerHTML={{ __html: btnText }}></span>
            </CustomNextBtn>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ svId, roadMapId }) => ({
  svId,
  roadMapId,
})

export default connect(mapStateToProps)(BarChart2)
