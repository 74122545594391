import React from "react";
import { Button } from "@material-ui/core";

const BackBtn = (props) => {
  return (
    <div className="back-btn-container">
      <Button
        className={`${props.styleBtn} back-btn`}
        type={props.btnType}
        onClick={props.clickBtn}
        {...props}
      >
        {props.children}
      </Button>
    </div>
  );
};

export default BackBtn;
