import { CircularProgress } from "@material-ui/core";
import React from "react";
import CustomNextBtn from "../../../utilities/Buttons/NextButton";

function AideButtonStaff({
  getSvPdfFile,
  pdfFile,
  pdfText,
  curType,
  handleTheAdieFunc,
  infoText,
  infoIcon,
  labelProtection,
  labelDanger,
  pdfLoading,
}) {
  return (
    <div className="adie-dang-protec-cont">
      <div className="pdf-section">
        {pdfLoading ? (
          <CircularProgress
            size={40}
            thickness={4}
            style={{ color: "#ff6500", margin: "auto" }}
          />
        ) : (
          <div className="pdf-wrapper" onClick={getSvPdfFile}>
            <img src={pdfFile} alt="" />
            <span dangerouslySetInnerHTML={{ __html: pdfText }}></span>
          </div>
        )}
      </div>
      <div className={` aide-cont ${curType === 1 ? "first-btn" : ""} `}>
        <CustomNextBtn className="info-btn" onClick={handleTheAdieFunc}>
          <span dangerouslySetInnerHTML={{ __html: infoText }}></span>
          <img className="info-select-icon" src={infoIcon} alt="" />
        </CustomNextBtn>
      </div>
      {curType !== 1 && (
        <div className="dang-protec-bg">
          {(curType === 2 || curType === 4) && (
            <div className="protection-container">
              <div className="protection-bg"></div>
              <p dangerouslySetInnerHTML={{ __html: labelProtection }}></p>
            </div>
          )}
          {(curType === 3 || curType === 4) && (
            <div className="danger-container">
              <div className="danger-bg"></div>
              <p dangerouslySetInnerHTML={{ __html: labelDanger }}></p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default AideButtonStaff;
