import { CircularProgress } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { getSuperVisionTheFile } from "../../../services/files"
import "./page3styles.scss"
import pdfImg from "../../../images/pdf_file.png"
import { connect } from "react-redux"
import BackBtn from "../../utilities/Buttons/BackBtn"
import CustomNextBtn from "../../utilities/Buttons/NextButton"
import { useHistory } from "react-router"
import { getContent } from "../../../services/Content"

function Page3Tut({ svId, userId }) {
  const [pdfLoading, setpdfLoading] = useState(false)

  const [firstText, setfirstText] = useState("")
  const [title, settitle] = useState("")
  const [secText, setsecText] = useState("")
  const [prevBtn, setprevBtn] = useState("")
  const [nextBtn, setnextBtn] = useState("")
  const [pdfText, setpdfText] = useState("")
  const [loading, setloading] = useState(false)

  const go = useHistory()

  useEffect(() => {
    getAllPageContent()
  }, [])

  function getAllPageContent() {
    setloading(true)
    getContent("rm_tutorial_3").then((data) => {
      const arr = data.content
      setfirstText(arr[0].value)
      settitle(arr[1].value)
      setsecText(arr[2].value)
      setprevBtn(arr[3].value)
      setnextBtn(arr[4].value)
      setpdfText(arr[5].value)
      setloading(false)
    })
  }

  async function getSvPdfFile() {
    setpdfLoading(true)
    let res = await getSuperVisionTheFile(svId.id, userId.user_id_company)
    setpdfLoading(false)
  }

  if (loading) {
    return (
      <CircularProgress
        size={80}
        thickness={4}
        style={{ color: "#ff6500", margin: "auto" }}
      />
    )
  }
  return (
    <div className="page__container">
      <p
        className="top__text"
        dangerouslySetInnerHTML={{ __html: firstText }}
      ></p>
      <div className="middle__text">
        <h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
        <div className="pdf__">
          {pdfLoading === true ? (
            <CircularProgress
              size={40}
              thickness={4}
              style={{ color: "#ff6500", margin: "auto" }}
            />
          ) : (
            <div className="pdf-content" onClick={getSvPdfFile}>
              <img src={pdfImg} alt="" />
              <p dangerouslySetInnerHTML={{ __html: pdfText }}></p>
            </div>
          )}
        </div>
      </div>
      <p
        className="down__text"
        dangerouslySetInnerHTML={{ __html: secText }}
      ></p>

      <div className="btn__holder">
        <BackBtn onClick={() => go.push("/home/roadmap/tut2")}>
          <span dangerouslySetInnerHTML={{ __html: prevBtn }}></span>
        </BackBtn>
        <CustomNextBtn onClick={() => go.push("/home/roadmap/tut4")}>
          <span dangerouslySetInnerHTML={{ __html: nextBtn }}></span>
        </CustomNextBtn>
      </div>
    </div>
  )
}

const mapStateToProps = ({ svId, userId }) => ({
  svId,
  userId,
})
export default connect(mapStateToProps)(Page3Tut)
