import React, { useEffect, useState, Fragmant } from "react";
import CustomNextBtn from "../../../../utilities/Buttons/NextButton";
import SpeedChart from "../../speedmeterchart/SpeedChart";
import Button from "@mui/material/Button";
import "./createNewroadMap.style.scss";
import { useHistory } from "react-router";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { getallSpeedChartsToCreateNewRoadMap } from "../../../../../services/chart";
import { connect } from "react-redux";
import vLine from "../../../../../images/v-line.png";
import {
  ABANDONED,
  createNewRoadMap,
  FINISHED,
  IN_PROGRESS,
  NOT_STARTED,
  VALIDATED,
} from "../../../../../services/roadmap";
import { useDispatch } from "react-redux";
import { set_roadmap_id } from "../../../../../redux/actions/roadmap";
import { getRiskColor } from "../../barchartHelper";
import { CircularProgress } from "@material-ui/core";
import ExitBtn from "../../../../utilities/Buttons/ExitBtn";
import { set_cur_roadmap_tablePage } from "../../../../../redux/actions/commonActions";

function CreateNewRoadMap({ userId, svId }) {
  const [arrOfRoadMaps, setarrOfRoadMaps] = useState([]);
  const [loading, setloading] = useState(true);

  const go = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      dispatch(set_cur_roadmap_tablePage(1))
      await getallCharts();
    })();
  }, []);

  const onCreateNewRoadMap = async (id) => {
    try {
      const newRoadMapId = await createTheRoadMap(id);
      if (!newRoadMapId) throw new Error("");
      go.push({
        pathname: "/home/questions_list/chart2/",
        state: {
          newRoadMapId,
          id_title: id,
        },
      });
    } catch (error) {
      alert("some thing wrong with the network , please try again");
    }
  };

  function onContinueRoadMap(id, roadmap) {
    if (roadmap.status === NOT_STARTED) {
      go.push({
        pathname: "/home/questions_list/chart2",
        state: {
          newRoadMapId: roadmap.id,
          id_title: id,
        },
      });
    } else {
      go.push({
        pathname: "/home/questions_list/chart2/RoadMapQuestions",
        state: {
          newRoadMapId: roadmap.id,
          id_title: id,
        },
      });
    }
  }

  async function getallCharts() {
    const res = await getallSpeedChartsToCreateNewRoadMap(
      userId.id_audit,
      svId.id
    );
    setarrOfRoadMaps(res);
    setloading(false);
  }

  async function createTheRoadMap(id_title) {
    try {
      const resopnse = await createNewRoadMap(svId.id, id_title);
      const res = await resopnse.json();
      if (!res.id) throw new Error("");
      dispatch(set_roadmap_id(res.id));
      return res.id;
    } catch (error) {
      alert("some thing wroong with creating new roadmap");
    }
  }

  if (loading) {
    return (
      <CircularProgress
        size={80}
        thickness={4}
        style={{ color: "#ff6500", margin: "auto" }}
      />
    );
  }
  return (
    <>
      <ExitBtn routePage="/home/questions_list/chart" />
      <div className="container">
        {/* <img alt="" className="v-line-table-right" src={vLine} />
        <img alt="" className="v-line-table-left" src={vLine} /> */}

        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell className="name-cloum" component="th" scope="row">
                  <p className="type__name_title">Nom</p>
                </TableCell>
                <TableCell className="name-cloum" component="th" scope="row">
                  <p className="type__name_title">Risque</p>
                </TableCell>
                <TableCell className="name-cloum" component="th" scope="row">
                  <p className="type__name_title">Accès</p>
                </TableCell>
              </TableRow>
              {arrOfRoadMaps.map(({ id, name, risk, roadmap }) => (
                <TableRow
                  key={id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell className="name-cloum" component="th" scope="row">
                    <p className="type__name">{name}</p>
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    className="middle-column"
                  >
                    <div className="speedmeter-container">
                      <SpeedChart size={"250px"} percValue={risk / 100} />
                      <p
                        style={{ color: getRiskColor(risk) }}
                        className="risk-value"
                      >
                        {/* <span>Risk: </span> */}
                        <p>{risk}</p>
                      </p>
                    </div>
                  </TableCell>
                  <TableCell className="btn-content">
                    {/* {handleShowBtn(id, roadmap)} */}
                    {/* roadmap.status === NOT_STARTED ? ( */}
                    {roadmap === null ||
                    roadmap.status === VALIDATED ||
                    roadmap.status === ABANDONED ? (
                      <CustomNextBtn
                      disabled={risk === 0}
                        onClick={() => onCreateNewRoadMap(id, roadmap)}
                      >
                        Créer
                      </CustomNextBtn>
                    ) : (
                      <CustomNextBtn
                      disabled={risk === 0}

                        onClick={() => onContinueRoadMap(id, roadmap)}
                      >
                        Suivi
                      </CustomNextBtn>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}

const mapStateToProps = ({ userId, svId, tr_ }) => ({
  userId,
  svId,
  tr_,
});
export default connect(mapStateToProps)(CreateNewRoadMap);
