import React from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogTitle from "@material-ui/core/DialogTitle"
import Slide from "@material-ui/core/Slide"
import "./styles.scss"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function ConfirmDeleteModal({
  text,
  handleCloseModal = (f) => f,
  handleConfirmDelete = (f) => f,
  type,
  confirmationmodal = false,
}) {
  return (
    <Dialog
      open={confirmationmodal}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseModal}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className="alert-modal"
    >
      <span onClick={handleCloseModal} className="close-icon">
        x
      </span>
      <DialogTitle id="alert-dialog-slide-title">
        <span dangerouslySetInnerHTML={{ __html: text }}></span>
      </DialogTitle>

      <DialogActions>
        <Button onClick={handleConfirmDelete} className="ok-btn">
          OK
        </Button>
        <Button onClick={handleCloseModal} className="ok-btn">
          Annuler
        </Button>
      </DialogActions>
    </Dialog>
  )
}
