import { CircularProgress } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useHistory } from "react-router"
import { getContent } from "../../../services/Content"
import {
  updateRoadmapName,
  update_the_questions,
} from "../../../services/roadmap"
import { set_roadmap_name } from "../../../redux/actions/roadmap"
import Alert from "../../utilities/Modals/alert/Alert"
import InfoModel from "../../utilities/Modals/infoModel"
import CustomNextBtn from "../../utilities/Buttons/NextButton"
import infoIcon from "../../../images/info-selected.png"
import "./style.scss"
import FinishModal from "../../utilities/Modals/finishModal"
import { set_log_time } from "../../../redux/actions/timeAction"

const FinishRoadmap = ({
  roadMapId,
  dispatch,
  checkTheTimeGab,
  handleLogOut,
}) => {
  useEffect(() => {
    handleTheautologout()
  }, [])
  function handleTheautologout() {
    if (checkTheTimeGab()) {
      handleLogOut()
    } else {
      dispatch(set_log_time(new Date().getTime()))
    }
  }
  const [roadmapName, setroadmapName] = useState("")
  const [alertStatus, setalertStatus] = useState(false)
  const [title, setTitle] = useState("")
  const [subTitle, setSubTitle] = useState("")
  const [inputText, setInputText] = useState("")
  const [btnText, setBtnText] = useState("")
  const [btnInfoText, setbtnInfoText] = useState("")
  const [alertText, setAlertText] = useState("")
  const [loading, setLoading] = useState(false)
  const [infoModal, setinfoModal] = useState(false)
  const [finishModalStatus, setFinishModalStatus] = useState(false)

  const { push, location } = useHistory()
  const { newRoadMapId, idsOfSelectedBoxes } = setTheStateOfRoute()

  useEffect(() => {
    if (!newRoadMapId) {
      push("/home/questions_list/chart")
      return
    }
    setLoading(true)
    getContent("finish_roadmap_page").then((data) => {
      const { content } = data
      setTitle(content[0].value)
      setSubTitle(content[1].value)
      setInputText(content[2].value)
      setBtnText(content[3].value)
      setbtnInfoText(content[4].value)
      setAlertText(data.content[5].value)
      setLoading(false)
    })
  }, [])

  const handleSubmintRoadmapName = () => {
    if (roadmapName.length === 0) {
      return setalertStatus(true)
    } else {
      updateRoadmapName(newRoadMapId, roadmapName, "finished")
        .then(() => {
          //setFinishModalStatus(true);
          dispatch(set_roadmap_name(roadmapName))
          updateTheQuetions()
        })
        .catch((e) => console.log(e))
    }
  }

  function setTheStateOfRoute() {
    if (location.state) {
      const { newRoadMapId, idsOfSelectedBoxes } = location.state
      return {
        newRoadMapId,
        idsOfSelectedBoxes,
      }
    }
    return {
      newRoadMapId: null,
      idsOfSelectedBoxes: null,
    }
  }

  async function updateTheQuetions() {
    await update_the_questions(newRoadMapId, idsOfSelectedBoxes)
    push("/home/questions_list/chart")
  }

  const handleCloseAlert = () => {
    setalertStatus(false)
  }

  function handleCloseInfoModal() {
    setinfoModal(false)
  }

  function handleCloseFinishModal() {
    setFinishModalStatus(false)
  }

  if (loading) {
    return (
      <CircularProgress
        size={80}
        thickness={4}
        style={{ color: "#ff6500", margin: "auto" }}
      />
    )
  }

  return (
    <div className="finish-roadmap-wrapper">
      <div className="finish-contianer">
        <Alert
          handleCloseAlert={handleCloseAlert}
          alertStatus={alertStatus}
          title={alertText}
        />
        <InfoModel
          infoModal={infoModal}
          type="app_info_finish_RM_page"
          handleCloseInfoModal={handleCloseInfoModal}
        />
        <FinishModal
          routePage="/home/after_finish_roadmap/checkbox-roadmap"
          newRoadMapId={newRoadMapId}
          type="after_finish_roadmap_page"
          finishModalStatus={finishModalStatus}
          handleCloseFinishModal={handleCloseFinishModal}
        />

        <div className="header-conatiner">
          <div className="header">
            <div className="line"></div>
            <h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
            <div className="line"></div>
          </div>
          <h3 dangerouslySetInnerHTML={{ __html: subTitle }}></h3>
        </div>
        <div className="middle">
          <p dangerouslySetInnerHTML={{ __html: inputText }}></p>
          <div className="input-container">
            <input
              id="outlined-basic"
              className="input"
              label="Name"
              placeholder="Nom"
              value={roadmapName}
              onChange={(e) => setroadmapName(e.target.value)}
            />
          </div>
        </div>
        <div className="btn">
          <CustomNextBtn
            className="info-btn"
            onClick={() => setinfoModal(true)}
          >
            <span dangerouslySetInnerHTML={{ __html: btnInfoText }}></span>
            <img className="info-select-icon" src={infoIcon} alt="" />
          </CustomNextBtn>
          <CustomNextBtn
            className="validate-btn"
            onClick={handleSubmintRoadmapName}
          >
            <span dangerouslySetInnerHTML={{ __html: btnText }}></span>
          </CustomNextBtn>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ roadMapId }) => ({
  roadMapId,
})

export default connect(mapStateToProps)(FinishRoadmap)
