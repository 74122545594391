import { CircularProgress } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router"
import { getContent } from "../../../services/Content"

import CustomNextBtn from "../../utilities/Buttons/NextButton"
import "./page1styles.scss"

function Page1Tut() {
  const [firstText, setfirstText] = useState("")
  const [title, settitle] = useState("")
  const [secText, setsecText] = useState("")
  const [nextBtn, setnextBtn] = useState("")

  const [loading, setloading] = useState(false)

  useEffect(() => {
    getAllPageContent()
  }, [])

  function getAllPageContent() {
    setloading(true)
    getContent("rm_tutorial_1").then((data) => {
      const arr = data.content
      setfirstText(arr[0].value)
      settitle(arr[1].value)
      setsecText(arr[2].value)
      setnextBtn(arr[3].value)
      setloading(false)
    })
  }
  const go = useHistory()

  if (loading) {
    return (
      <CircularProgress
        size={80}
        thickness={4}
        style={{ color: "#ff6500", margin: "auto" }}
      />
    )
  }
  return (
    <div className="page1__container">
      <p
        className="top__text"
        dangerouslySetInnerHTML={{ __html: firstText }}
      ></p>
      <div className="middle__text">
        <h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
      </div>
      <p
        className="down__text"
        dangerouslySetInnerHTML={{ __html: secText }}
      ></p>
      <div className="btn__holder">
        <CustomNextBtn onClick={() => go.push("/home/roadmap/tut2")}>
          <span dangerouslySetInnerHTML={{ __html: nextBtn }}></span>
        </CustomNextBtn>
      </div>
    </div>
  )
}

export default Page1Tut
