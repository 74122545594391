import { SET_ROAD_MAP_ID, SET_ROAD_MAP_NAME } from "../constants"

export const set_roadmap_id = (id) => ({
  type: SET_ROAD_MAP_ID,
  id,
})
export const set_roadmap_name = (name) => ({
  type: SET_ROAD_MAP_NAME,
  name,
})
