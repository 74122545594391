import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import { Provider } from 'react-redux';
import {store, persistor} from './redux/store'
import {PersistGate} from 'redux-persist/integration/react'
import './font/segoe-ui-4-cufonfonts-webfont/Segoe_UI.woff'
ReactDOM.render(
  <Provider store = {store} >
    <React.StrictMode>
      <PersistGate persistor = {persistor} >
        <App />
      </PersistGate>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

reportWebVitals();
