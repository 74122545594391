import {prefixApi, prefixHeaderObj} from './prefix'

const api_get_dateTime = `${prefixApi}misc/datetime`

export async function getTheTime() {
    // const formBody = createFormBody()
    const res = await fetch(api_get_dateTime, {
      method: "GET",
      headers: prefixHeaderObj,
    });
    
    return await (res.json());
  }