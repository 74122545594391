import { SET_CUR_ROADMAP_TABLE_PAGE } from "../constants"

export const commonReducer = (state = { roadMapTablePage: 1 }, action) => {
  switch (action.type) {
    case SET_CUR_ROADMAP_TABLE_PAGE:
      return { ...state, roadMapTablePage: action.num }
    default:
      return state
  }
}
