import { SET_FIRST_REPORT, SET_SV_ID } from "../constants"

export const set_first_report = (reports) => ({
  type: SET_FIRST_REPORT,
  reports,
})

export const set_supervision_id = (id) => ({
  type: SET_SV_ID,
  id,
})
