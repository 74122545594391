import { prefixApi, prefixHeaderObj } from "./prefix";

const count_answers_grouped_by_points_obtained = `${prefixApi}reports/count_answers_grouped_by_points_obtained`;

const update_reminder_api = `${prefixApi}supervisions/update/`;
const second_chart_api = `${prefixApi}reports/threats_dangerousity`;
const spcific_bar_info = `${prefixApi}reports/questions_and_answers_by_points_received/`;
const api_get_prot_deng = `${prefixApi}reports/calculate_improvement_values/`;
const api_get_all_speedCharts = `${prefixApi}titles/retrieve_with_roadmap_and_risk/`; // {id_audit}/{id_supervision}
const api_get_all_barchart_data = `${prefixApi}reports/count_answers_by_roadmap_title_grouped_by_points_obtained/`; // {id_audit}/{id_supervision}

const api_get_risk_percentage = `${prefixApi}reports/risk_percentage/`;

// parameters
export function get_chart(idSv = "") {
  const res = fetch(count_answers_grouped_by_points_obtained + `/${idSv}`, {
    method: "GET",
    headers: prefixHeaderObj,
  });
  return res;
}

export function get_chart_2() {
  const res = fetch(second_chart_api, {
    method: "GET",
    headers: prefixHeaderObj,
  });
  return res;
}

export function get_spcific_bar_info(idSv = "", points = "") {
  const res = fetch(`${spcific_bar_info}${idSv}/${points}`, {
    method: "GET",
    headers: prefixHeaderObj,
  });
  return res;
}
// protections and dang
export function get_protect_deng(idSv = "") {
  const res = fetch(`${api_get_prot_deng}${idSv}`, {
    method: "GET",
    headers: prefixHeaderObj,
  });
  return res;
}

export const set_reminder = (svId, date) => {
  var details = {
    datetime: date,
  };
  var formBody = [];
  for (var property in details) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  return fetch(`${update_reminder_api}${svId}`, {
    method: "PUT",
    headers: prefixHeaderObj,
    body: formBody,
  });
};

export const get_risk_value = async (idCompany) => {
  const res = await fetch(`${api_get_risk_percentage}${idCompany}`, {
    method: "GET",
    headers: prefixHeaderObj,
  });
  return await res.json();
};


export async function getallSpeedChartsToCreateNewRoadMap(audit_id, supervision_id) {
  const res = await fetch(api_get_all_speedCharts + audit_id + "/" + supervision_id, {
    method: "GET",
    headers: prefixHeaderObj,
  });
  return await res.json();
}

export async function getTheDataOfBarChartRoadMap(id_supervision, id_title) {
  
  const res = await fetch(api_get_all_barchart_data + id_supervision + "/" + id_title, {
    method: "GET",
    headers: prefixHeaderObj,
  });
  return await res.json();
}