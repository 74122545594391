import React, { useEffect, useState } from "react"
import { connect, useDispatch } from "react-redux"
import { useHistory } from "react-router"
import { getContent } from "../../../services/Content"
import { update_terms } from "../../../services/terms"
import { set_state_from_tuts_humbrger } from "../../../redux/actions/superVision"
import { set_user_tuts } from "../../../redux/actions/user"
import BackBtn from "../../utilities/Buttons/BackBtn"
import CustomNextBtn from "../../utilities/Buttons/NextButton"
// looks like tut4
import "./tut4.scss"

function Tut7({ userId, svId }) {
  const go = useHistory()
  const dispatch = useDispatch()

  const [title, settitle] = useState("")
  const [subTitle, setsubTitle] = useState("")
  const [firstText, setfirstText] = useState("")
  const [secondText, setsecondText] = useState("")
  const [nextBtnText, setnextBtnText] = useState("")
  const [prevBtnText, setprevBtnText] = useState("")

  useEffect(() => {
    getPageContent()
  }, [])

  async function handleUpdateTuts() {
    //console.log("!!!", svId.tut)
    // todo: commented for quick dirty fix on tutorial new tab block -> forward button not appearing on /tut7
    //svId.tut = 0
   //if (svId.tut === 0) {
      console.log("update tuto")
      dispatch(set_user_tuts(1))
      await update_terms({ tutorial: 1 }, userId.user_id)
      go.push("/home/questions_list/chart")
      return
    //}
    //window.close()
  }

  function getPageContent() {
    getContent("tutorial-7").then((data) => {
      const arr = data.content
      settitle(arr[0].value)
      setsubTitle(arr[1].value)
      setfirstText(arr[2].value)
      setsecondText(arr[3].value)
      setnextBtnText(arr[4].value)
      setprevBtnText(arr[5].value)
    })
  }

  return (
    <div>
      <div className="tut4__container">
        <p className="text" dangerouslySetInnerHTML={{ __html: firstText }}></p>
        <div className="middle__tut">
          <h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
          <h1 dangerouslySetInnerHTML={{ __html: subTitle }}></h1>
        </div>
        <p
          className="text"
          dangerouslySetInnerHTML={{ __html: secondText }}
        ></p>
        <div className="btn__holder">
          <BackBtn onClick={() => go.push("/home/questions_list/tut3")}>
            <span dangerouslySetInnerHTML={{ __html: prevBtnText }}></span>
          </BackBtn>
          {/*svId.tut === 0 && (*/
            <CustomNextBtn onClick={handleUpdateTuts}>
              <span dangerouslySetInnerHTML={{ __html: nextBtnText }}></span>
            </CustomNextBtn>
          /*)*/}
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = ({ userId, svId }) => ({ userId, svId })
export default connect(mapStateToProps)(Tut7)
