import { prefixApi, prefixHeaderObj } from "./prefix";

const api_supervision_content = `${prefixApi}content/get/`;

export const getContent = (type) => {
  var details = {
    // key: type,
    page: type,
  };
  var formBody = [];
  for (var property in details) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");

  return fetch(api_supervision_content, {
    method: "POST",
    headers: prefixHeaderObj,
    body: formBody,
  }).then((res) => res.json());
};
