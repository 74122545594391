import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import CheckBoxTerms from "../../terms/CheckBoxTerms"
import "./style.scss"
import CustomNextBtn from "../../utilities/Buttons/NextButton"
import {
  get_questions_by_rmid,
  update_the_questions,
} from "../../../services/roadmap"
import { CircularProgress } from "@material-ui/core"
import { useHistory } from "react-router"
import { getContent } from "../../../services/Content"
import AppInfo from "../../utilities/AppInfoComponents/appInfo"
import { getRoadmapFile } from "../../../services/files"
import checkedInfoIcon from "../../../images/checked.png"
import notCheckedInfoIcon from "../../../images/not_checked.png"
import FinishModal from "../../utilities/Modals/finishModal"
import checkboxChecked from "../../../images/checkbox_on_light.png"
import pdfFile from "../../../images/pdf_file.png"
import { set_log_time } from "../../../redux/actions/timeAction"
import { v4 } from "uuid"
import FinishProfileInfo from "../../utilities/Modals/finishProfileInfo/FinishProfileInfo"

import fileDownloadImg from "../../../images/pdf_file.png"

const QuesionsInfoCheckBox = ({
  roadMapId,
  userId,
  dispatch,
  checkTheTimeGab,
  handleLogOut,
}) => {
  useEffect(() => {
    handleTheautologout()
  }, [])

  function handleTheautologout() {
    if (checkTheTimeGab()) {
      handleLogOut()
    } else {
      dispatch(set_log_time(new Date().getTime()))
    }
  }

  const [selectedBoxes, setselectedBoxes] = useState([])
  const [questions, setquestions] = useState([])
  const [curInfo, setcurInfo] = useState(-1)
  const [menuTitle, setmenuTitle] = useState("")
  const [process, setprocess] = useState("")
  const [method, setmethod] = useState("")
  const [title, setTitle] = useState("")
  const [andonBtnText, setAbandonBtnText] = useState("")
  const [validateBtnText, setValidateBtnText] = useState("")
  const [prccessInfoText, setProcessInfoText] = useState("")
  const [methodInfoText, setMethodInfoText] = useState("")
  const [alertText, setAlertText] = useState("")
  const [pdfText, setPdfText] = useState("")
  const [fileDownload, setfileDownload] = useState("")
  const [eachRmFileText, seteachRmFileText] = useState("")
  const [seeTheLeftMenu, setseeTheLeftMenu] = useState(false)
  const [loading, setloading] = useState(true)
  const [alertStatus, setalertStatus] = useState(false)
  const [finishModalStatus, setFinishModalStatus] = useState(false)
  const [pdfLoading, setpdfLoading] = useState(false)
  const { location, push } = useHistory()
  const { newRoadMapId } = setTheStateOfRoute()

  useEffect(() => {
    if (!newRoadMapId) {
      push("/home/questions_list/chart/createroadmap")
      return
    }
    getTheQuesions()
    getContent("check_wt_u_did_page").then((data) => {
      const { content } = data
      setTitle(content[0].value)
      setAbandonBtnText(content[1].value)
      setValidateBtnText(content[2].value)
      setProcessInfoText(content[3].value)
      setMethodInfoText(content[4].value)
      setAlertText(content[5].value)
      setPdfText(content[6].value)
      seteachRmFileText(content[7].value)
    })
  }, [])

  function setTheStateOfRoute() {
    if (location.state) {
      const { newRoadMapId } = location.state
      return {
        newRoadMapId,
      }
    }
    return {
      newRoadMapId: null,
    }
  }

  function handleCheckSpcificBox(idx = "", type = "add") {
    if (type === "add") {
      setselectedBoxes((oldArr) => {
        if (oldArr.indexOf(idx) === -1) {
          return [...oldArr, idx]
        } else {
          const newArr = oldArr.filter((item) => item !== idx)
          return newArr
        }
      })
    } else {
      setselectedBoxes((arr) => arr.filter((item) => item !== idx))
    }
  }

  async function getTheQuesions() {
    const response = await get_questions_by_rmid(newRoadMapId)
    const res = await response.json()
    let newArr = []
    res.data.map((item, i) => {
      if (item.status === "validated") {
        newArr.push(i)
      }
    })
    setselectedBoxes(newArr)
    setquestions([...res.data])
    setloading(false)
  }

  function handleAbandon() {
    push("/home/after_finish_roadmap/checkbox-roadmap/abandon-roadmap")
  }

  function handleOpenModal() {
    if (!selectedBoxes.length) {
      return setalertStatus(true)
    }
    if (selectedBoxes.length < questions.length) {
      setFinishModalStatus(true)
    } else {
      return sendTheChosenFromCheckbox().then(() => {
        push("/home/after_finish_roadmap/checkbox-roadmap/completed-roadmap")
      })
    }
  }

  async function sendTheChosenFromCheckbox() {
    const idsOfSelectedBoxes = await getIdsOfIdxOfSelctedBoxes()

    const response = await update_the_questions(
      newRoadMapId,
      idsOfSelectedBoxes
    )
    const res = await response.json()
    return res
  }

  async function getIdsOfIdxOfSelctedBoxes() {
    let arr = selectedBoxes.map((item) => questions[item].id)
    let newQuestions = questions.map((item) => ({ id: item.id }))
    return convertArrayToObject(newQuestions, "id")
  }

  async function convertArrayToObject(arr, key) {
    const initialValue = {}

    return arr.reduce((obj, item, i) => {
      return {
        ...obj,
        [item.id]: selectedBoxes.includes(i) ? 1 : 0,
      }
    }, initialValue)
  }

  async function getInfoForQuestion(idx) {
    setcurInfo(idx)
    setseeTheLeftMenu(true)
    setmenuTitle(questions[idx].question)
  }

  if (loading) {
    return (
      <CircularProgress
        size={80}
        thickness={4}
        style={{ color: "#ff6500", margin: "auto" }}
      />
    )
  }
  async function handleTheFile() {
    setpdfLoading(true)
    const response = await getRoadmapFile(newRoadMapId, userId.user_id_company)
    setpdfLoading(false)
  }

  function handleCloseFinishModal() {
    setFinishModalStatus(false)
  }
  return (
    <div className="check-wt-u-did-container">
      <div className="continer-parent">
        <FinishProfileInfo
          handleCloseFinishModal={() => setalertStatus(false)}
          finishModalStatus={alertStatus}
          text={alertText}
          shouldCloseOnClick={false}
          isItForValidateRoadmap={true}
        />

        <FinishModal
          routePage="/home/questions_list/chart"
          type="after_checking_some_of_checkboxe"
          finishModalStatus={finishModalStatus}
          handleCloseFinishModal={handleCloseFinishModal}
          validateRoadmap={true}
          handleComplete={sendTheChosenFromCheckbox}
        />

        <div className="left">
          <AppInfo type="app_info_check_wt_u_did" />
        </div>

        <div className="info-check-conatiner">
          <div className="checkbox-container">
            <h3
              className="title"
              dangerouslySetInnerHTML={{ __html: title }}
            ></h3>
            <div className="check-box-conatiner">
              {questions.map((item, i) => (
                <div key={v4()} className="chaeck-quesion-icon">
                  <div className="check-question">
                    {item.status === "validated" ? (
                      <>
                        <div className="checkbox-style">
                          <img src={checkboxChecked} alt="" />;
                        </div>
                        <p style={{ color: "#505050" }}>{item.question}</p>
                      </>
                    ) : (
                      <>
                        <CheckBoxTerms
                          key={i}
                          idx={i}
                          handleTheCheck={handleCheckSpcificBox}
                          selectedBoxes={selectedBoxes}
                        />
                        <p>{item.question}</p>
                      </>
                    )}
                  </div>
                  {i === curInfo ? (
                    <img
                      onClick={() => {
                        getInfoForQuestion(i)
                      }}
                      src={checkedInfoIcon}
                      alt=""
                    />
                  ) : (
                    <img
                      onClick={() => {
                        getInfoForQuestion(i)
                      }}
                      src={notCheckedInfoIcon}
                      alt=""
                    />
                  )}
                </div>
              ))}
            </div>
            <div style={{ width: "100%" }}></div>
            <div className="check-wt-u-did-btns">
              <div className="abandon-btn">
                <CustomNextBtn onClick={handleAbandon}>
                  <span
                    dangerouslySetInnerHTML={{ __html: andonBtnText }}
                  ></span>
                </CustomNextBtn>
              </div>
              {/* <CustomNextBtn onClick={handleComplete}> */}
              <CustomNextBtn onClick={handleOpenModal}>
                <span
                  dangerouslySetInnerHTML={{ __html: validateBtnText }}
                ></span>
              </CustomNextBtn>
            </div>
          </div>
        </div>
        <div className="right">
          {pdfLoading ? (
            <CircularProgress
              size={40}
              thickness={4}
              style={{ color: "#ff6500", margin: "auto" }}
            />
          ) : (
            <div className="pdf-btn" onClick={handleTheFile}>
              <img src={pdfFile} alt="" />
              <span dangerouslySetInnerHTML={{ __html: pdfText }}></span>
            </div>
          )}
          <div className={`menu ${seeTheLeftMenu === false ? "gone" : ""}`}>
            <h5 className="title">{menuTitle}</h5>
            <div className="block">
              <h6 dangerouslySetInnerHTML={{ __html: prccessInfoText }}></h6>
              <p dangerouslySetInnerHTML={{ __html: process }}></p>
            </div>
            <div className="block">
              <h6 dangerouslySetInnerHTML={{ __html: methodInfoText }}></h6>
              <p dangerouslySetInnerHTML={{ __html: method }}></p>
              {fileDownload !== "" && (
                <div className="each-rm-file">
                  <a href={fileDownload}>
                    <img src={fileDownloadImg} alt="" />
                    <span
                      dangerouslySetInnerHTML={{ __html: eachRmFileText }}
                    ></span>
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ roadMapId, userId }) => ({
  roadMapId,
  userId,
})

export default connect(mapStateToProps)(QuesionsInfoCheckBox)
