import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { getContent } from "../../../services/Content";
import {
  set_roadmap_id,
  set_roadmap_name,
} from "../../../redux/actions/roadmap";
import CustomNextBtn from "../../utilities/Buttons/NextButton";
import "./style.scss";
import vLine from "../../../images/v-line.png";
import { set_log_time } from "../../../redux/actions/timeAction";

const CompletedRoadmap = ({checkTheTimeGab, handleLogOut,}) => {
  const dispatch = useDispatch()
  useEffect(() => {
    handleTheautologout()
  } , [])
  function handleTheautologout() {
    if(checkTheTimeGab()) {
      handleLogOut()
    } else {
      dispatch(set_log_time((new Date()).getTime()))
    }
  }
  let go = useHistory();
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [text, setText] = useState("");
  const [btnText, setBtnText] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    getContent("complete_roadmap_page").then((data) => {
      setTitle(data.content[0].value);
      setSubTitle(data.content[1].value);
      setText(data.content[2].value);
      setBtnText(data.content[3].value);
      setLoading(false);
    });
  }, []);
  const handleTheFinish = () => {
    dispatch(set_roadmap_id(null));
    dispatch(set_roadmap_name(null));
    go.push("/home/questions_list/chart");
  };
  if (loading) {
    return (
      <div style={{ textAlign: "center" }}>
        <CircularProgress
          size={50}
          thickness={4}
          style={{ color: "#ff6500", margin: "auto" }}
        />
      </div>
    );
  }

  return (
    <div  className="completed-roadmap-wrapper">
      <h3 className="title" dangerouslySetInnerHTML={{ __html: title }}></h3>
      <div className="completed-roadmap">
        <img src={vLine} alt="" className="left-line" />
        <p
          className="sub-title"
          dangerouslySetInnerHTML={{ __html: subTitle }}
        ></p>
        <p className="text" dangerouslySetInnerHTML={{ __html: text }}></p>

        <CustomNextBtn onClick={handleTheFinish}>
          <span dangerouslySetInnerHTML={{ __html: btnText }}></span>
        </CustomNextBtn>
        <img src={vLine} alt="" className="right-line" />
      </div>
     
    </div>
  );
};

export default CompletedRoadmap;
