import { SET_FIRST_REPORT } from "../constants"

export const firstReportReducer = (state = [], action) => {
  switch (action.type) {
    case SET_FIRST_REPORT:
      return [...state, ...action]
    default:
      return state
  }
}
