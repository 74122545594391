import { prefixApi, prefixHeaderObj } from "./prefix"

const api_login = `${prefixApi}auth/login`
// const api_content = `${prefixApi}content/get`;
const api_email_reset_password = `${prefixApi}auth/reset_password`
const api_user_update = `${prefixApi}user/update/`
const check_forget_pass_code = `${prefixApi}auth/check_forgot_password_code/`
const api_get_user = `${prefixApi}/user/retrieve/`

const api_reset_pass = `${prefixApi}auth/change_password`

async function loginApi(email = "", password = "") {
  var details = {
    email: email,
    password: password,
  }
  var formBody = []
  for (var property in details) {
    var encodedKey = encodeURIComponent(property)
    var encodedValue = encodeURIComponent(details[property])
    formBody.push(encodedKey + "=" + encodedValue)
  }
  formBody = formBody.join("&")
  return fetch(api_login, {
    method: "POST",
    headers: prefixHeaderObj,
    body: formBody,
  }).then((res) => res.json())
}

async function email_reset_password(email = "") {
  var details = {
    email: email,
  }
  var formBody = []
  for (var property in details) {
    var encodedKey = encodeURIComponent(property)
    var encodedValue = encodeURIComponent(details[property])
    formBody.push(encodedKey + "=" + encodedValue)
  }
  formBody = formBody.join("&")
  return fetch(api_email_reset_password, {
    method: "POST",
    headers: prefixHeaderObj,
    body: formBody,
  }).then((res) => res.json())
}

export const update_user_logout = (userId) => {
  var details = {
    logged_in: 0,
  }
  var formBody = []
  for (var property in details) {
    var encodedKey = encodeURIComponent(property)
    var encodedValue = encodeURIComponent(details[property])
    formBody.push(encodedKey + "=" + encodedValue)
  }
  formBody = formBody.join("&")
  return fetch(`${api_user_update}${userId}`, {
    method: "PUT",
    headers: prefixHeaderObj,
    body: formBody,
  })
}

export const update_user_profile = async (userId) => {
  var details = {
    json: JSON.stringify({ profile_image: null }),
  }
  var formBody = []
  for (var property in details) {
    var encodedKey = encodeURIComponent(property)
    var encodedValue = encodeURIComponent(details[property])
    formBody.push(encodedKey + "=" + encodedValue)
  }
  formBody = formBody.join("&")
  const res = await fetch(`${api_user_update}${userId}`, {
    method: "PUT",
    headers: prefixHeaderObj,
    body: formBody,
    // body: JSON.stringify({ profile_image: null })
  })
  const respo = res.json()
  return respo
}

export const update_user_first_last_name = async (userId, details) => {
  var formBody = []
  for (var property in details) {
    var encodedKey = encodeURIComponent(property)
    var encodedValue = encodeURIComponent(details[property])
    formBody.push(encodedKey + "=" + encodedValue)
  }
  formBody = formBody.join("&")
  const res = await fetch(`${api_user_update}${userId}`, {
    method: "PUT",
    headers: prefixHeaderObj,
    body: formBody,
  })
  const respo = res.json()
  return respo
}

export async function get_user_data(userId = "") {
  const res = fetch(`${api_get_user}${userId}`, {
    method: "GET",
    headers: prefixHeaderObj,
  })
  const respo = await (await res).json()
  return respo
}

export async function checkThecode(token) {
  var details = {
    code: token,
  }
  var formBody = []
  for (var property in details) {
    var encodedKey = encodeURIComponent(property)
    var encodedValue = encodeURIComponent(details[property])
    formBody.push(encodedKey + "=" + encodedValue)
  }
  formBody = formBody.join("&")
  const res = await fetch(check_forget_pass_code + token, {
    method: "POST",
    headers: prefixHeaderObj,
    body: formBody,
  })
  const respo = await res.json()
  return respo
}
export const resetPassword_api = async (pass1, pass2, id, code) => {
  var details = {
    code: code,
    id_user: id,
    password: pass1,
    confirm_password: pass2,
  }
  var formBody = []
  for (var property in details) {
    var encodedKey = encodeURIComponent(property)
    var encodedValue = encodeURIComponent(details[property])
    formBody.push(encodedKey + "=" + encodedValue)
  }
  formBody = formBody.join("&")
  const res = await fetch(api_reset_pass, {
    method: "POST",
    headers: prefixHeaderObj,
    body: formBody,
  })
  const respo = await res.json()
  return respo
}

export { loginApi, email_reset_password }
