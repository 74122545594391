import React from "react"
import { connect } from "react-redux"
import "./style.scss"

const UserAvatar = ({
  iconClick = (f) => f,
  src = null,
  size = 250,
  sizeOficon = 30,
  profile,
}) => {
  return (
    <div
      style={{
        width: size + 5,
        margin: 0,
        padding: 0,
      }}
      className="container"
    >
      {src && (
        <img
          style={{ width: size, height: size }}
          className="img"
          src={src}
          alt=""
        />
      )}
      <div
        onClick={iconClick}
        style={{ width: sizeOficon, height: sizeOficon }}
        className="icon-container"
      ></div>
    </div>
  )
}

const mapStateToProps = ({ profile }) => ({
  profile,
})

export default connect(mapStateToProps)(UserAvatar)
