import React, { useEffect, useState } from "react";

import "./style.scss";

import {
  get_all_raodmaps_for_company,
  get_all_supervisions_for_company,
} from "../../services/superVision";
import { connect } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import moment from "moment";
import { get_question } from "../../services/questions";
import { set_log_time } from "../../redux/actions/timeAction";
import { v4 } from "uuid";
import { getRoadmapFile, getSuperVisionTheFile } from "../../services/files";
import Line from "../../images/line.png";
import infoIcons from "../../images/not_checked.png";
import { getContent } from "../../services/Content";
import {
  deleteRoadmapWithID,
  deleteSuperVisonWithID,
} from "../../services/roadmap";

import ConfirmDeleteModal from "../utilities/Modals/confirmationModal/ConfirmationModal";
import Alert from "../utilities/Modals/alert/Alert";
import SuperVisonList from "./SuperVisonList";
import RoadMapList from "./RoadMapList";

const HistoryComp = ({ userId, checkTheTimeGab, handleLogOut, dispatch }) => {
  let { path, url } = useRouteMatch();
  const go = useHistory();

  const [allSupervision, setallSupervision] = useState([]);
  const [allRoadmaps, setallRoadmaps] = useState();
  const [loading, setLoading] = useState(false);
  const [confirmationmodal, setconfirmationmodal] = useState(false);
  const [pdfModalStatus, setpdfModalStatus] = useState(false);
  const [itemId, setitemId] = useState(null);
  const [refresh, setrefresh] = useState(false);

  const [historyType, sethistoryType] = useState("");
  const [historyText, sethistoryText] = useState("");
  const [roadmapText, setroadmapText] = useState("");
  const [confirmationText, setconfirmationText] = useState("");
  const [pdfmodalTitle, setpdfmodalTitle] = useState("");

  useEffect(() => {
    handleTheautologout();
  }, []);

  useEffect(() => {
    getAllpageContent();
  }, []);

  useEffect(() => {
    setLoading(true);
    get_all_supervisions_for_company(userId.user_id_company)
      .then((data) => {
        return data.json();
      })
      .then((res) => {
        setallSupervision(
          res.data.sort(function (a, b) {
            var dateA = new Date(a.datetime),
              dateB = new Date(b.datetime);
            return dateB - dateA;
          })
        );
        setLoading(false);
      });

    get_all_raodmaps_for_company(userId.user_id_company)
      .then((data) => {
        return data.json();
      })
      .then((res) => {
        setallRoadmaps(
          res.data.sort(function (a, b) {
            var dateA = new Date(a.datetime),
              dateB = new Date(b.datetime);
            return dateB - dateA;
          })
        );
        setLoading(false);
      });
  }, [refresh]);

  const getAllpageContent = () => {
    getContent("history_page").then((data) => {
      const arr = data.content;
      sethistoryText(arr[0].value);
      setroadmapText(arr[1].value);
      setconfirmationText(arr[2].value);
      setpdfmodalTitle(arr[3].value);
    });
  };

  function handleTheautologout() {
    if (checkTheTimeGab()) {
      handleLogOut();
    } else {
      dispatch(set_log_time(new Date().getTime()));
    }
  }
  async function deleteSV(curSV) {
    const res = await deleteSuperVisonWithID(curSV);
    setrefresh(!refresh);
  }

  async function deleteRM(curRM) {
    const res = await deleteRoadmapWithID(curRM);
    setrefresh(!refresh);
  }

  const setTheCategoryQuestions = async (lst_cat_idx) => {
    const response1 = await get_question(lst_cat_idx);
    const res1 = await response1.json();
    const questions_cat = res1.data;
    return questions_cat;
  };

  const handleSpecificSV = async (id) => {
    setpdfModalStatus(true);
    await getSuperVisionTheFile(id, userId.user_id_company);
    setpdfModalStatus(false);
  };

  const handleSpecificRoadMap = async (id) => {
    setpdfModalStatus(true);
    await getRoadmapFile(id, userId.user_id_company);
    setpdfModalStatus(false);
  };

  const handleConfirmDelete = () => {
    if (historyType === "rm") {
      deleteRM(itemId);
    }
    if (historyType === "sv") {
      deleteSV(itemId);
    }
    setconfirmationmodal(false);
  };

  const handleOpenModal = (item, type) => {
    setitemId(item);
    sethistoryType(type);
    setconfirmationmodal(true);
  };

  const handleCloseAlert = () => {
    setpdfModalStatus(false);
  };
  return (
    <div className="home-container">
      <ConfirmDeleteModal
        text={confirmationText}
        confirmationmodal={confirmationmodal}
        handleCloseModal={() => setconfirmationmodal(false)}
        handleConfirmDelete={handleConfirmDelete}
      />
      <Alert
        title={pdfmodalTitle}
        alertStatus={pdfModalStatus}
        handleCloseAlert={handleCloseAlert}
      />
      <div className="archive-container">
        <SuperVisonList
          loading={loading}
          historyText={historyText}
          Line={Line}
          allSupervision={allSupervision}
          handleSpecificSV={handleSpecificSV}
          infoIcons={infoIcons}
          handleOpenModal={handleOpenModal}
        />

        <RoadMapList
          roadmapText={roadmapText}
          Line={Line}
          allRoadmaps={allRoadmaps}
          handleSpecificRoadMap={handleSpecificRoadMap}
          infoIcons={infoIcons}
          handleOpenModal={handleOpenModal}
          loading={loading}
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({ userId }) => ({
  userId,
});

export default connect(mapStateToProps)(HistoryComp);
