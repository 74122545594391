import React, { useEffect, useState } from "react";
import ProgressBar from "./ProgressBar";
import "./style.scss";
import AdjustIcon from "@material-ui/icons/Adjust";
import PanoramaFishEyeIcon from "@material-ui/icons/PanoramaFishEye";
import { Button, CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import {
  set_curQuestionNum,
  set_numOfQuestions,
} from "../../../redux/actions/questios";
import { useHistory } from "react-router";
import Formation from "./Formation";
import { get_choices } from "../../../services/questions";
import CustomNextBtn from "../../utilities/Buttons/NextButton";
import { submitAnswer } from "../../../services/answers";
import { update_supervision } from "../../../services/superVision";
import Alert from "../../utilities/Modals/alert/Alert";
import InfoModel from "../../utilities/Modals/infoModel";
import infoIcon from "../../../images/info-selected.png";
import selectedRadioImg from "../../../images/Group 2081.png";
import notSelectedRadioImg from "../../../images/radio_off.png";
import { getContent } from "../../../services/Content";
import { set_log_time } from "../../../redux/actions/timeAction";
import { v4 } from "uuid";
import BackBtn from "../../utilities/Buttons/BackBtn";
import { get_second_connection } from "../../../services/secondconnection";
import { set_sv_id } from "../../../redux/actions/superVision";

function Questions({
  questionsInfo,
  dispatch,
  checkTheTimeGab,
  handleLogOut,
  userId,
  svId,
  tr_,
  ...rest
}) {
  const go = useHistory();
  useEffect(() => {
    if (!tr_.tr_1) {
      go.push("/home/info/2");
    }
    handleTheautologout();
  }, []);
  function handleTheautologout() {
    if (checkTheTimeGab()) {
      handleLogOut();
    } else {
      dispatch(set_log_time(new Date().getTime()));
    }
  }

  const [answerNum, setanswerNum] = useState(0);
  const [curQuestion, setcurQuestion] = useState(
    rest.history.location.state.lst_q_idx
  );
  const [showInfo, setshowInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingForSendanswer, setLoadingForSendanswer] = useState(false);
  const [category, setcategory] = useState(
    rest.history.location.state.category || ""
  );
  const [categ_questions, setcateg_questions] = useState(
    rest.history.location.state.categorys_Question || []
  );
  const [curChoices, setcurChoices] = useState([]);
  const [title, settitle] = useState("");
  const [subTitle, setsubTitle] = useState("");

  const [alertStatus, setalertStatus] = useState(false);
  const [infoModal, setinfoModal] = useState(false);

  const [nextBtn, setnextBtn] = useState("");
  const [skipBtn, setskipBtn] = useState("");
  const [infoBtn, setinfoBtn] = useState("");
  const [glossaireBtn, setglossaireBtn] = useState("");
  const [noGlossaireText, setnoGlossaireText] = useState("");
  const [alertText, setAlertText] = useState("");
  const [precSelectedText, setprecSelectedText] = useState("");

  useEffect(() => {
    setcurQuestion(rest.history.location.state.lst_q_idx);
    setcateg_questions(rest.history.location.state.categorys_Question);
    getChoiscesForQuestion(rest.history.location.state.lst_q_idx);
    dispatch(set_curQuestionNum(rest.history.location.state.lst_q_idx));
    dispatch(
      set_numOfQuestions(rest.history.location.state.categorys_Question.length)
    );
    setcategory(rest.history.location.state.category);
    settitle(
      rest.history.location.state.categorys_Question[curQuestion].question
    );
    setsubTitle(
      rest.history.location.state.categorys_Question[curQuestion].subtitle
    );

    getContent("questionnaire_page").then((data) => {
      const arr = data.content;
      setnextBtn(arr[0].value);
      setskipBtn(arr[1].value);
      setinfoBtn(arr[2].value);
      setglossaireBtn(arr[3].value);
      setnoGlossaireText(arr[4].value);
      setAlertText(arr[5].value);
      setprecSelectedText(arr[6].value);
    });
  }, []);

  useEffect(() => {
    (async () => {
      if (await checkWhereToSvStatusFinished()) {
        return go.push("/home/questions_list");
      }
      update_supervision(
        rest.history.location.state.category,
        curQuestion,
        svId.id,
        "in_progress"
      );
    })();
    return () => {
      // if (checkWhereToSvStatusFinished()) {
      //   return go.push("/home/questions_list");
      // }
      update_supervision(category, curQuestion, svId.id, "in_progress");
    };
  }, [curQuestion]);

  async function checkWhereToSvStatusFinished() {
    let res = await get_second_connection(userId.user_id);
    let resJsn = await res.json();
    dispatch(set_sv_id(resJsn.supervision.id));

    if (resJsn.supervision.status === "finished") {
      return true;
    }
    return false;
  }

  function getChoiscesForQuestion(idx = "") {
    const idd = categ_questions[idx].id;
    setLoading(true);
    get_choices(idd, svId.id)
      .then(async (res) => {
        const response = await res.json();
        return response.data;
      })
      .then(async (data) => {
        const { selectedIdx, previosIdx } = await getTheselected_previo(data);
        if (selectedIdx !== -1) handleAnswer(selectedIdx + 1);
        else if (previosIdx !== -1) handleAnswer(previosIdx + 1);
        let arr = [...data];
        setcurChoices([...arr]);
        setLoading(false);
      });
  }
  async function getTheselected_previo(arr = []) {
    let selectedIdx = -1,
      previosIdx = -1;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].selected !== undefined) selectedIdx = i;
      if (arr[i].previously_selected !== undefined) previosIdx = i;
    }
    return { selectedIdx, previosIdx };
  }
  function handleAnswer(idx = 0) {
    setanswerNum(idx);
  }

  function set_CurTitle(idx) {
    settitle(categ_questions[idx].question);
    setsubTitle(categ_questions[idx].subtitle);
  }
  async function handlePreviousQuestion() {
    let cur = parseInt(curQuestion, 10);
    setLoadingForSendanswer(true);
    if (cur === 0) {
      go.push("/home/questions_list");
      setcurQuestion(0);
      dispatch(set_curQuestionNum(0));
      setLoadingForSendanswer(false);
      return;
    }

    await update_supervision(
      rest.history.location.state.category,
      cur,
      svId.id,
      "in_progress"
    );
    dispatch(set_curQuestionNum(cur - 1));
    setcurQuestion(cur - 1);
    getChoiscesForQuestion(cur - 1);
    set_CurTitle(cur - 1);
    setLoadingForSendanswer(false);
  }
  async function handleNextQuesion(skip = false) {
    setLoadingForSendanswer(true);
    let nxt = parseInt(curQuestion, 10) + 1;

    if (skip === false) {
      await handlesubmitAnswer();
      setLoadingForSendanswer(false);
    } else {
      if (await checkWhereToSvStatusFinished()) {
        return go.push("/home/questions_list");
      }
      await update_supervision(
        rest.history.location.state.category,
        curQuestion,
        svId.id,
        "in_progress"
      );
      setanswerNum(0);
      setLoadingForSendanswer(false);
    }
    if (nxt >= categ_questions.length) {
      go.push("/home/questions_list");
      // window.location.reload();
      setcurQuestion(0);
      dispatch(set_curQuestionNum(0));
      setLoadingForSendanswer(false);
      return;
    }
    dispatch(set_curQuestionNum(nxt));
    setcurQuestion(nxt);
    getChoiscesForQuestion(nxt);
    set_CurTitle(nxt);
    setLoadingForSendanswer(false);
  }

  async function handlesubmitAnswer() {
    if (await checkWhereToSvStatusFinished()) {
      return go.push("/home/questions_list");
    }
    if (categ_questions.length > 0 && curChoices.length > 0) {
      let idC;
      idC = curChoices[answerNum - 1].id;
      let idQ = categ_questions[curQuestion].id;
      await submitAnswer(svId.id, idQ, idC);

      await update_supervision(
        rest.history.location.state.category,
        curQuestion,
        svId.id,
        "in_progress"
      );
    }
  }

  function handleCloseAlert() {
    setalertStatus(false);
  }

  function handleCloseInfoModal() {
    setinfoModal(false);
  }

  return (
    <div className="questions-page">
      <Alert
        // title="Choisissez une réponse"
        title={alertText}
        handleCloseAlert={handleCloseAlert}
        alertStatus={alertStatus}
      />
      <InfoModel
        infoModal={infoModal}
        type="questions-info-modal"
        handleCloseInfoModal={handleCloseInfoModal}
      />

      <div className="middle-bar">
        <ProgressBar
          number={questionsInfo.numOfQues}
          cur={parseInt(curQuestion, 10)}
        />
      </div>
      <div className="question-formation">
        <div className="quistion-container">
          <div className="question-title-chosses">
            <div>
              <h3 className="title">{title}</h3>
              {subTitle && <h5 className="subtitle">{subTitle}</h5>}
            </div>
            <ul className="answers">
              {loading || loadingForSendanswer ? (
                <CircularProgress
                  size={50}
                  thickness={4}
                  style={{ color: "#ff6500", marginRight: "3px" }}
                />
              ) : (
                curChoices.map((item, i) => {
                  // ix
                  return (
                    <li
                      onClick={() => handleAnswer(i + 1)}
                      className="answer"
                      key={v4()}
                    >
                      {item.previously_selected && (
                        <p
                          className="previously_selected"
                          dangerouslySetInnerHTML={{ __html: precSelectedText }}
                        ></p>
                      )}
                      {/* <AdjustIcon className="selected-icon" /> */}
                      {answerNum === i + 1 ? (
                        !item.previously_selected ? (
                          <img src={selectedRadioImg} alt="" />
                        ) : (
                          <div className="prev-selected-choice"></div>
                        )
                      ) : (
                        <img src={notSelectedRadioImg} alt="" />
                      )}
                      <p
                        className="answer-text"
                        dangerouslySetInnerHTML={{ __html: item.text }}
                      ></p>
                    </li>
                  );
                })
              )}
            </ul>
            <div className="btns-container">
              <BackBtn onClick={handlePreviousQuestion}>Précédent</BackBtn>
              <CustomNextBtn
                className="info-btn"
                onClick={() => setinfoModal(true)}
              >
                <span dangerouslySetInnerHTML={{ __html: infoBtn }}></span>
                <img className="info-select-icon" src={infoIcon} alt="" />
              </CustomNextBtn>

              <div className="skip-question">
                <CustomNextBtn onClick={() => handleNextQuesion(true)}>
                  <span dangerouslySetInnerHTML={{ __html: skipBtn }}></span>
                </CustomNextBtn>
              </div>
              <CustomNextBtn
                styleBtn="go-next-btn"
                clickBtn={() => {
                  if (answerNum !== 0 || curChoices.length === 0) {
                    handleNextQuesion(false);
                    setanswerNum(0);
                  } else {
                    setalertStatus(true);
                  }
                }}
              >
                <span dangerouslySetInnerHTML={{ __html: nextBtn }}></span>
              </CustomNextBtn>
            </div>
          </div>
        </div>

        <Formation
          idQuestion={categ_questions[curQuestion].id}
          showInfo={showInfo}
          setshowInfo={setshowInfo}
          categoryId={category}
          glossaireBtn={glossaireBtn}
          noGlossaireText={noGlossaireText}
        />
      </div>
      {/* </div> */}
    </div>
  );
}
const mapStateToProps = ({ questionsInfo, userId, svId, tr_ }) => ({
  questionsInfo,
  userId,
  svId,
  tr_,
});
export default connect(mapStateToProps)(Questions);
