import $ from "jquery";
import { prefixFileApi, prefixHeaderObj } from "./prefix";

const fileUrl = `${prefixFileApi}/roadmap_export/`;
const svFileApi = `${prefixFileApi}/supervision_export/`;

export function getRoadmapFile(rmId, idCompany) {
  return $.ajax({
    type: "POST",
    url: fileUrl + rmId,
    xhrFields: { responseType: "blob" },
    crossDomain: true,
    headers: prefixHeaderObj,
    data: {
      id_company: idCompany,
    },
    success: function (data, textStatus, jqXHR) {
      var file = new Blob([data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    },
    error: function () {
      console.log("error");
    },
  });
}

export function getSuperVisionTheFile(svId, idCompany) {
  return $.ajax({
    type: "POST",
    url: svFileApi + svId,
    xhrFields: { responseType: "blob" },
    crossDomain: true,
    headers: prefixHeaderObj,
    data: {
      id_company: idCompany,
    },
    success: function (data, textStatus, jqXHR) {
      var file = new Blob([data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    },
    error: function () {
      console.log("error");
    },
  });
}
