import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import { getContent } from "../../../../services/Content";
import { set_log_time } from "../../../../redux/actions/timeAction";
import AppInfo from "../../../utilities/AppInfoComponents/appInfo";
import CustomNextBtn from "../../../utilities/Buttons/NextButton";
import "./style.scss";

const AfterFinishRoadmap = ({checkTheTimeGab, handleLogOut,}) => {
  const go = useHistory();
  const { path, url } = useRouteMatch();
  const dispatch = useDispatch()
  useEffect(() => {
    handleTheautologout()
  } , [])
  function handleTheautologout() {
    if(checkTheTimeGab()) {
      handleLogOut()
    } else {
      dispatch(set_log_time((new Date()).getTime()))
    }
  }
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [text, setText] = useState("");
  const [validateBtnText, setValidateBtnText] = useState("");
  // const [infoModal, setinfoModal] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getContent("after_finish_roadmap_page").then((data) => {
      const { content } = data;
      setTitle(content[0].value);
      setSubTitle(content[1].value);
      // setText(content[2].value);
      setValidateBtnText(content[2].value);
      setLoading(false);
    });
  }, []);

  if (loading) {
    return (
      <CircularProgress
        size={80}
        thickness={4}
        style={{ color: "#ff6500", margin: "auto" }}
      />
    );
  }

  return (
    <div  className="after-finish-wrapper">
      <div className="after-finish-roadmap-container">
        <div className="after-finish-roadmap-content">
          <h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
          <p dangerouslySetInnerHTML={{ __html: subTitle }}></p>
        </div>
        {/* <p className="after-finish-roadmap-text">
        <p dangerouslySetInnerHTML={{ __html: text }}></p>

        <CustomNextBtn onClick={() => go.push("/home")} className="history-btn">
          <span dangerouslySetInnerHTML={{ __html: historyBtnText }}></span>
        </CustomNextBtn>
      </p> */}
      {/* <CustomNextBtn onClick={()=> setinfoModal(true)}>
            Info
          </CustomNextBtn> */}

        <CustomNextBtn onClick={() => go.push(`${url}/checkbox-roadmap`)}>
          <span dangerouslySetInnerHTML={{ __html: validateBtnText }}></span>
        </CustomNextBtn>
        {/* <CustomNextBtn onClick={()=> go.push(`/home/questions_list/chart`)}>Voir les résultats</CustomNextBtn> */}
      </div>
     
    </div>
  );
};

export default AfterFinishRoadmap;
