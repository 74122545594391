import React from 'react';

function TitleChartComp({ curType, riskValText,
    paramTitle, protectionTitle, dangerTitle, protectionDangerTitle }) {
    return (
        <p
            style={{
                color: "white",
                height: "30px",
                width: "80%",
                textAlign: "center",
            }}
        >
            {curType === 0 && (
                <h3 dangerouslySetInnerHTML={{ __html: riskValText }}></h3>
            )}

            {curType === 1 && (
                <h3 dangerouslySetInnerHTML={{ __html: paramTitle }}></h3>
            )}
            {curType === 2 && (
                <h3 dangerouslySetInnerHTML={{ __html: protectionTitle }}></h3>
            )}
            {curType === 3 && (
                <h3 dangerouslySetInnerHTML={{ __html: dangerTitle }}></h3>
            )}
            {curType === 4 && (
                <h3 dangerouslySetInnerHTML={{ __html: protectionDangerTitle }}></h3>
            )}
        </p>
    )
}

export default TitleChartComp;
