import { Button, CircularProgress } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import CustomNextBtn from "../../utilities/Buttons/NextButton"
import { getContent } from "../../../services/Content"

function QuestionInfo({
  dataOfCategory,
  title = "tilte",
  numberQuestions = 0,
  goToQuestions = (f) => f,
}) {
  const [defaultTextInfo, setDefaultTextInfo] = useState("")
  const [questionText, setQuestionText] = useState("")
  const [btnText, setBtnText] = useState("")

  useEffect(() => {
    getContent("question_category_info").then((data) => {
      const arr = data.content
      setDefaultTextInfo(arr[0].value)
      setQuestionText(arr[1].value)
      setBtnText(arr[2].value)
    })
  }, [])
  return (
    <div className="title-number-container">
      {title && numberQuestions <= 0 ? (
        <div style={{ textAlign: "center" }}>
          <CircularProgress
            size={50}
            thickness={4}
            style={{ color: "#ff6500", marginRight: "3px" }}
          />
        </div>
      ) : (
        <div className="title-number-data">
          <h1 className="title">{title}</h1>
          {title && (
            <h4 className="number">
              {numberQuestions === 0 ? "There is no" : numberQuestions}{" "}
              <span dangerouslySetInnerHTML={{ __html: questionText }}></span>
            </h4>
          )}
          {title && (
            <p
              className="text"
              dangerouslySetInnerHTML={{ __html: dataOfCategory.description }}
            ></p>
          )}
        </div>
      )}
      {!title && (
        <p
          className="default-text"
          dangerouslySetInnerHTML={{ __html: defaultTextInfo }}
        ></p>
      )}
      <div>
        {title && numberQuestions > 0 && (
          <CustomNextBtn onClick={goToQuestions}>
            <span dangerouslySetInnerHTML={{ __html: btnText }}></span>
          </CustomNextBtn>
        )}
      </div>
    </div>
  )
}

export default QuestionInfo
