import { CircularProgress } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import "./style.scss"
import { useHistory } from "react-router-dom"
import { get_terms, update_terms } from "../../services/terms"
import CustomNextBtn from "../utilities/Buttons/NextButton"
import Logo from "../../images/icon-1.png"
import Alert from "../utilities/Modals/alert/Alert"
import { set_term_1 } from "../../redux/actions/term"
import checkboxChecked from "../../images/checkbox_on_light.png"
import notCheckboxChecked from "../../images/checkbox_notChecked.png"

function Term({ dispatch, userId, tr_, checkTheTimeGab, handleLogOut }) {
  const history = useHistory()
  const [title, settitle] = useState("")
  const [text, settext] = useState("")
  const [acceptText, setAcceptText] = useState("")
  const [alertText, setAlertText] = useState("")

  const [checkedBox, setcheckedBox] = useState(false)
  const [loading, setLoading] = useState(false)
  const [alertStatus, setalertStatus] = useState(false)

  useEffect(() => {
    if (tr_.tr_1) {
      history.push("/home/info/3")
    }
    if (
      userId.user_id === null ||
      userId.user_id === "" ||
      userId.user_id_company === null ||
      userId.user_id_company === ""
    ) {
      history.push("/auth/login")
    }
  }, [])

  useEffect(() => {
    setLoading(true)
    get_terms("terms1")
      .then(async (res) => {
        const response = await res.json()
        return response.content
      })
      .then((data) => {
        settitle(data[1].value)
        settext(data[0].value)
        setAcceptText(data[2].value)
        setAlertText(data[3].value)
        setLoading(false)
      })
  }, [])

  const handleCheckBox = () => {
    setcheckedBox(!checkedBox)
  }

  const handleCloseAlert = () => {
    setalertStatus(false)
  }

  if (loading) {
    return (
      <div className="term-container">
        <CircularProgress
          size={50}
          thickness={4}
          style={{ color: "#ff6500", marginRight: "3px" }}
        />
      </div>
    )
  } else {
    return (
      <div className="term-container">
        <Alert
          handleCloseAlert={handleCloseAlert}
          alertStatus={alertStatus}
          title={alertText}
        />
        <img src={Logo} alt="" />
        <p className="title" dangerouslySetInnerHTML={{ __html: title }}></p>
        <div className="checkbox-textarea">
          <div className="terms-p">
            <p dangerouslySetInnerHTML={{ __html: text }}></p>
          </div>
        </div>
        <div className="check-box-conatianer" onClick={handleCheckBox}>
          <div className="checkbox-styles">
            <img
              // onClick={() => handleCheckBox(idx)}
              src={checkedBox ? checkboxChecked : notCheckboxChecked}
              alt=""
            />
          </div>
          <span
            style={{ marginLeft: "12px" }}
            dangerouslySetInnerHTML={{ __html: acceptText }}
          ></span>
        </div>
        <CustomNextBtn
          styleBtn="terms1-btn"
          onClick={() => {
            if (checkedBox === true) {
              history.push("/home/info/3")

              dispatch(set_term_1(1))
              update_terms({ terms_first: 1 }, userId.user_id)
            } else {
              setalertStatus(true)
            }
          }}
        >
          SUITE
        </CustomNextBtn>
      </div>
    )
  }
}
const mapStateToProps = ({ userId, tr_ }) => ({
  userId,
  tr_,
})
export default connect(mapStateToProps)(Term)
