import React from "react"
import { useHistory } from "react-router-dom"

import "./style.scss"

import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import Slide from "@material-ui/core/Slide"

import vLine from "../../../../images/v-line.png"
import CustomNextBtn from "../../Buttons/NextButton"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function FinishProfileInfo({
  handleCloseFinishModal = (f) => f,
  text,
  finishModalStatus = false,
  shouldCloseOnClick = false,
  isItForValidateRoadmap = false,
}) {
  const go = useHistory()

  //   const [title, setTitle] = useState("");
  //   const [text, setText] = useState("");
  //   const [btnText, setBtnText] = useState("");
  //   const [loading, setLoading] = useState(false);

  //   useEffect(() => {
  //     setLoading(true);
  //     supervisionContent(type).then((data) => {
  //       setTitle(data.content[0].value);
  //       setText(data.content[1].value);
  //       setBtnText(data.content[2].value);
  //       setLoading(false);
  //     });
  //   }, []);

  const handleCloseTab = () => {
    window.close()
  }

  const handleGoToChart = () => {
    go.push("/home/questions_list/chart")
  }
  return (
    <Dialog
      open={finishModalStatus}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseFinishModal}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className="finish-profile-modal"
    >
      <div className="info-modal-content">
        {/* <span onClick={handleCloseFinishModal} className="close-icon"></span> */}
        {!isItForValidateRoadmap && (
          <DialogTitle id="alert-dialog-slide-title">
            <span>Bravo!</span>
          </DialogTitle>
        )}
        <div className="img-text-content">
          <img src={vLine} alt="" className="left-line" />

          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {isItForValidateRoadmap ? (
                <span dangerouslySetInnerHTML={{ __html: text }}></span>
              ) : (
                <span>{text}</span>
              )}
            </DialogContentText>
          </DialogContent>
          <img src={vLine} alt="" className="right-line" />
          <DialogActions>
            <CustomNextBtn
              className="ok-btn"
              onClick={() => {
                if (shouldCloseOnClick) {
                  handleCloseTab()
                } else {
                  handleGoToChart()
                }
              }}
            >
              <span>OK</span>
            </CustomNextBtn>
          </DialogActions>
        </div>
      </div>
    </Dialog>
  )
}
