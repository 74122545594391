import React from "react";
import GaugeChart from "react-gauge-chart";
import './styles.scss'

function SpeedChart({ size, percValue, type = "speed-chart" }) {
  return (
    <div className={type} style={{ width: size, maxHeight: size, display: 'flex', alignItems: 'center' }}>
      <GaugeChart
        // style={chartStyle}
        id="gauge-chart3"
        nrOfLevels={4}
        colors={["green", "#d48e04", "#d46604", "#c90700"]}
        arcWidth={0.2}
        percent={percValue}
        hideText={true}
        animDelay={10}
      />
    </div>
  );
}

export default SpeedChart;
