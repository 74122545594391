import React, { useEffect, useState } from "react";
import { Button, CircularProgress } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";
import { prefixApi } from "../../../services/prefix";
import {
  get_all_gloassary,
  get_specific_glossary,
} from "../../../services/glossary";
import { v4 } from "uuid";

// const Formation = ({ questionsInfo, showInfo, setshowInfo, idQuestion = f => f }) => {
const Formation = ({
  questionsInfo,
  showInfo,
  setshowInfo,
  idQuestion,
  categoryId,
  glossaireBtn,
  noGlossaireText,
}) => {
  const [glossary, setglossary] = useState([]);
  const [allGlossary, setAllGlossary] = useState([]);

  useEffect(() => {
    setshowInfo(false);
    get_specific_glossary(idQuestion).then((res) => {
      setglossary(res.data);
    });
  }, [idQuestion]);

  const handleGetAllGlossary = () => {
    get_all_gloassary(categoryId)
    .then((res) => {
      setAllGlossary(res.data);
    });
    setshowInfo(true);
  };

  return (
    <div
      className={`formation-container ${
        showInfo === true ? "formation-open" : ""
      }`}
    >
      {!showInfo && (
        <Button
          className="glossary-btn"
          onClick={() => handleGetAllGlossary()}
          color="secondary"
        >
          <span dangerouslySetInnerHTML={{__html: glossaireBtn}}></span>
        </Button>
      )}

      {showInfo && (
        <div className="close-container">
          <Button onClick={() => setshowInfo(false)}>
            <CloseIcon className="icon" />
          </Button>
        </div>
      )}

      <div className={`formation ${showInfo === true ? "full-info" : ""}`}>
        {showInfo ? (
          <div>
            {/* <div className="close-container">
              <Button onClick={() => setshowInfo(false)}>
                <CloseIcon className="icon" />
              </Button>
            </div> */}

            <div className="info-bg">
              {/* <div className="close-container">
              <Button onClick={() => setshowInfo(false)}>
                <CloseIcon className="icon" />
              </Button>
            </div> */}

              {!allGlossary ? (
                <div style={{ textAlign: "center" }}>
                  <CircularProgress
                    size={50}
                    thickness={4}
                    style={{ color: "#ff6500", marginRight: "3px" }}
                  />
                  {/* <div key={i + Math.floor(Math.random() * 100)}> */}
                </div>
              ) : allGlossary.length ? (
                allGlossary.map((glos, i) => (
                  <div key = {v4()} >
                    <span className="header-container">
                      <h5>{glos.name}</h5>
                    </span>
                    <div className="text-info-container">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: glos.value,
                        }}
                      ></p>
                    </div>
                  </div>
                ))
              ) : (
                <div dangerouslySetInnerHTML={{__html: noGlossaireText}}></div>
              )}
            </div>
          </div>
        ) : glossary.length ? (
          <div className="big-info">
            {glossary.map((glos, i) => (
              <div key={v4()}>
                <span className="header-container">
                  <h5>{glos.name}</h5>
                </span>
                <div className="text-info-container">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: glos.value,
                    }}
                  ></p>
                </div>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = ({ questionsInfo }) => ({
  questionsInfo,
});

export default connect(mapStateToProps)(Formation);
