import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useRouteMatch } from "react-router"

import { CUSTOM_TABLE_STYLE } from "../../constants/tableStyle"

import { fetchRetrieveSupervisionAnswers } from "../../services/reports"

import ExitBtn from "../utilities/Buttons/ExitBtn"
import { CircularProgress } from "@material-ui/core"
import DataTable from "react-data-table-component"

import "./styles.scss"

const Answers = ({ supervisionId }) => {
  const { url } = useRouteMatch()
  const svIdFromUrl = /[^/]*$/.exec(url)[0]

  const [loading, setLoading] = useState(false)
  const [answers, setAnswers] = useState([])

  useEffect(() => {
    setLoading(true)
    fetchRetrieveSupervisionAnswers(supervisionId.id || svIdFromUrl)
      .then((data) => {
        return data.answers
      })
      .then((res) => {
        setAnswers(res)
      })
    setLoading(false)
  }, [])

  const columns = [
    {
      name: "Theme",
      selector: (row) => row.theme,
      maxWidth: "200px",
    },
    {
      name: "Sujet",
      selector: (row) => row.sujet,
      maxWidth: "400px",
    },
    {
      name: "Practique de l'entreprise",
      selector: (row) => row.practique,
      maxWidth: "900px",
    },
    {
      name: "Niveau du risque",
      selector: (row) => row.niveau,
      maxWidth: "200px",
      sortable: true,
      center: true,
    },
  ]

  const data = answers.map((answer, index) => {
    return {
      id: index,
      theme: answer.name,
      sujet: answer.question,
      practique: (
        <div
          className={"fixedEllipsis"}
          dangerouslySetInnerHTML={{ __html: answer.text }}
        ></div>
      ),
      niveau: answer.points,
    }
  })

  if (loading) {
    return (
      <div style={{ textAlign: "center" }}>
        <CircularProgress
          size={50}
          thickness={4}
          style={{ color: "#ff6500", marginRight: "3px" }}
        />
      </div>
    )
  }

  return (
    <>
      <div className="answersContainer">
        <ExitBtn routePage={"/reports"} />
        <div className="improvement-container">
          <DataTable
            className={"customTableStyle"}
            columns={columns}
            data={data}
            customStyles={CUSTOM_TABLE_STYLE}
            fixedHeader
            persistTableHead
            responsive
            noDataComponent={
              <div style={{ textAlign: "center", background: "black" }}>
                <CircularProgress
                  size={50}
                  thickness={4}
                  style={{
                    color: "#ff6500",
                    marginRight: "3px",
                  }}
                />
              </div>
            }
          />
        </div>
      </div>
      <div className="audit-copyright">
        <p>Audit de cybersécurité - © Cybalgoris</p>
      </div>
    </>
  )
}

const mapStateToProps = ({ supervisionId }) => ({
  supervisionId,
})

export default connect(mapStateToProps)(Answers)
