import { CircularProgress } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useHistory } from "react-router"
import { getContent } from "../../../services/Content"
import { prefix_api_set_img_content } from "../../../services/prefix"
import { get_second_connection } from "../../../services/secondconnection"
import { createNewSupervision } from "../../../services/superVision"
import { set_sv_id } from "../../../redux/actions/superVision"
import CustomNextBtn from "../../utilities/Buttons/NextButton"
import "./styles.scss"

const IntroInfo = ({
  apiPage,
  userId,
  svId,
  tr_,
  dispatch,
  nextPage = (f) => f,
  checkTheTimeGab,
  handleLogOut,
}) => {
  const go = useHistory()

  useEffect(() => {
    if (!tr_.tr_1) {
      go.push("/home/info/2")
    }
  }, [])
  const [text, settext] = useState("")
  const [skipBtn, setSkipBtn] = useState("")
  const [nextBtn, setNextBtn] = useState("")
  const [image, setimage] = useState("")

  const [loading, setloading] = useState(false)

  useEffect(() => {
    setloading(true)
    getContent(apiPage).then((data) => {
      settext(data.content[0].value)
      setNextBtn(data.content[1].value)
      setSkipBtn(data.content[2].value)
      setimage(`${prefix_api_set_img_content}${data.content[3].file}`)

      setloading(false)
    })
  }, [])

  async function createTheSuperVision() {
    const response = await createNewSupervision(
      "null",
      userId.user_id_company,
      0,
      0,
      userId.id_audit
    )

    const res = await response.json()
    return res
  }
  async function checkWhereTo() {
    const res = await get_second_connection(userId.user_id)
    return res
  }
  async function skipToList() {
    const datawhereTo = await checkWhereTo()
    const res = await datawhereTo.json()
    // debugger;
    if (res.supervision === "not_found") {
      createTheSuperVision()
        .then((data) => {
          dispatch(set_sv_id(data.id))
        })
        .then(() => {
          go.push("/home/questions_list")
          // window.location.reload();
        })
        .catch((e) => {
          console.log(e)
        })
    } else {
      dispatch(set_sv_id(res.supervision.id))
      if (
        res.supervision === "in_progress" ||
        svId.name === null ||
        svId.name === "null"
      ) {
        go.push("/home/questions_list")
      } else {
        go.push("/home/questions_list/tut1")
        return
      }
    }
  }

  if (loading) {
    return (
      <CircularProgress
        size={50}
        thickness={4}
        style={{ color: "#ff6500", margin: "auto" }}
      />
    )
  } else {
    return (
      <div className="info-intro">
        <p className="title" dangerouslySetInnerHTML={{ __html: text }}></p>
        <img className="img" src={image} alt="" />
        <div className="btns-intro-comtainer">
          <CustomNextBtn onClick={skipToList}>
            <span dangerouslySetInnerHTML={{ __html: skipBtn }}></span>
          </CustomNextBtn>
          <CustomNextBtn onClick={nextPage}>
            <span dangerouslySetInnerHTML={{ __html: nextBtn }}></span>
          </CustomNextBtn>
        </div>
      </div>
    )
  }
}
const mapStateToProps = ({ userId, svId, tr_ }) => ({
  userId,
  svId,
  tr_,
})
export default connect(mapStateToProps)(IntroInfo)
