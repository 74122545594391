import { CircularProgress } from "@material-ui/core"
import moment from "moment"
import React from "react"
import { v4 } from "uuid"
import DeleteIcon from "@material-ui/icons/Delete"

function SuperVisonList({
  historyText,
  Line,
  allSupervision,
  handleSpecificSV,
  infoIcons,
  handleOpenModal,
  loading,
}) {
  return (
    <div className="archive-supervision">
      <h3 dangerouslySetInnerHTML={{ __html: historyText }}></h3>
      <img className="img-line" src={Line} alt="" />
      <ul>
        {loading ? (
          <div style={{ margin: "auto" }}>
            <CircularProgress
              size={50}
              thickness={4}
              style={{ color: "#ff6500", marginRight: "3px" }}
            />
          </div>
        ) : (
          [...allSupervision].map((item, i) => {
            const curDate = moment(item.datetime, "YYYY/MM/DD")

            if (item.name) {
              return (
                <div className="sv-content">
                  <div
                    className="sv-text"
                    onClick={() => handleSpecificSV(item.id)}
                    key={v4()}
                  >
                    <span className="item_name">
                      {item.name === "null" ||
                      item.name === null ||
                      item.name === ""
                        ? "Supervision"
                        : item.name}
                    </span>
                    <div className="item_date_info_delete_container">
                      <span className="item_date">
                        <span className="date">{`${curDate.format(
                          "D"
                        )}/${curDate.format("M")}/${curDate.format(
                          "YYYY"
                        )}`}</span>
                        <img src={infoIcons} alt="" />
                      </span>
                    </div>
                  </div>
                  <DeleteIcon
                    onClick={() => {
                      if (i === 0) return
                      handleOpenModal(item.id, "sv")
                    }}
                    style={{ color: `${i === 0 ? "black" : ""}` }}
                    className={i === 0 ? "" : "delete-btn"}
                  />
                </div>
              )
            }
          })
        )}
      </ul>
    </div>
  )
}

export default SuperVisonList
