import React, { useEffect, useState } from "react";
import "./styles.scss";
import CachedIcon from "@material-ui/icons/Cached";
import { Button } from "@material-ui/core";
import { useHistory, useRouteMatch } from "react-router";
import { connect } from "react-redux";

const Footer = ({ userId, footer }) => {
  // let { path, url } = useRouteMatch();
  const [check, setcheck] = useState(false);
  const history = useHistory();
  const [pathname, setPathname] = useState(window.location);
  useEffect(() => {
    checkIfOnLoginOrTerms();
  }, [pathname])

  const checkIfOnLoginOrTerms = () => {
    if (pathname.toString().includes("info") || pathname.toString().includes("auth")) {
      setcheck(true);
    } else {
      setcheck(false);
    }
  };

  // return (
  //   (footer.cur === true) && <div className="footer-container">
  //     {!check && (
  //       <Button
  //         onClick={() => {
  //           window.location.reload();
  //         }}
  //       >
  //         <CachedIcon />
  //       Reload
  //       </Button>
  //     )}
  //   </div>
  // );
  return <span></span>
};
const mapStateToProps = ({ userId, footer }) => ({ userId, footer })
export default connect(mapStateToProps)(Footer);
