import { CircularProgress } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { connect, useDispatch } from "react-redux"
import { useHistory } from "react-router"
import { getContent } from "../../../services/Content"
import {
  updateRoadmapName,
  update_roadmap_to_abandoned,
} from "../../../services/roadmap"
import {
  set_roadmap_id,
  set_roadmap_name,
} from "../../../redux/actions/roadmap"
import CustomNextBtn from "../../utilities/Buttons/NextButton"
import "./styles.scss"
import FinishRoadmapModal from "../../utilities/Modals/FinishRoadmapModal/FinishRoadmap"
import { set_cur_roadmap_tablePage } from "../../../redux/actions/commonActions"

const AbandonPage = ({ checkTheTimeGab, handleLogOut, roadMapId }) => {
  const dispatch = useDispatch()
  const { location } = useHistory()
  const [newRoadMapId] = useState(
    location.state ? location.state.newRoadMapId : null
  )

  const [confirmModal, setconfirmModal] = useState(false)
  const [inputFormError, setinputFormError] = useState("")
  const [roadmapName, setroadmapName] = useState("")
  const [modalTitleText, setmodalTitleText] = useState("")
  const [modalErrorText, setmodalErrorText] = useState("")

  let go = useHistory()
  const [title, setTitle] = useState("")
  const [text, setText] = useState("")
  const [btnText, setBtnText] = useState("")
  const [btnGoBackText, setBtnGoBackText] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    getAllPageContent()
  }, [])

  function getAllPageContent() {
    getContent("abandon_page").then((data) => {
      setTitle(data.content[0].value)
      setText(data.content[1].value)
      setBtnText(data.content[2].value)
      setBtnGoBackText(data.content[3].value)
      setmodalTitleText(data.content[4].value)
      setmodalErrorText(data.content[5].value)
      setLoading(false)
    })
  }

  function handleAbandonRoadmap() {
    setconfirmModal(true)
  }

  function handleCancelConfirm() {
    setconfirmModal(false)
    setinputFormError("")
    setroadmapName("")
  }

  async function handleConfirm() {
    if (roadmapName === "") {
      return setinputFormError(modalErrorText)
    }

    await updateRoadMapNameApi()
    dispatch(set_roadmap_id(null))
    dispatch(set_roadmap_name(null))
    dispatch(set_cur_roadmap_tablePage(1))
    update_roadmap_to_abandoned(newRoadMapId, "abandoned").then(() => {
      go.push("/home/questions_list/chart")
    })
    setinputFormError("")
    setconfirmModal(false)
    setroadmapName("")
  }

  async function updateRoadMapNameApi() {
    await updateRoadmapName(newRoadMapId, roadmapName)
  }

  function handleGoBack() {
    go.push("/home/after_finish_roadmap/checkbox-roadmap")
  }

  if (loading) {
    return (
      <div style={{ textAlign: "center" }}>
        <CircularProgress
          size={50}
          thickness={4}
          style={{ color: "#ff6500", margin: "auto" }}
        />
      </div>
    )
  }
  return (
    <div className="abandon-container">
      <FinishRoadmapModal
        title={modalTitleText}
        alertStatus={confirmModal}
        cancel={handleCancelConfirm}
        handleConfirm={handleConfirm}
        roadmapName={roadmapName}
        setroadmapName={setroadmapName}
        inputFormError={inputFormError}
      />
      <h3 className="title" dangerouslySetInnerHTML={{ __html: title }}></h3>
      <p dangerouslySetInnerHTML={{ __html: text }}></p>
      <div className="btns-container">
        <CustomNextBtn styleBtn="back-to-list" onClick={handleGoBack}>
          <span dangerouslySetInnerHTML={{ __html: btnGoBackText }}></span>
        </CustomNextBtn>
        <CustomNextBtn onClick={handleAbandonRoadmap}>
          <span dangerouslySetInnerHTML={{ __html: btnText }}></span>
        </CustomNextBtn>
      </div>
    </div>
  )
}

const mapStateToProps = ({ roadMapId }) => ({
  roadMapId,
})

export default connect(mapStateToProps)(AbandonPage)
