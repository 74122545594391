import { SET_ROAD_MAP_ID, SET_ROAD_MAP_NAME } from "../constants"

export const roadMapReducer = (state = { id: null, name: null }, action) => {
  switch (action.type) {
    case SET_ROAD_MAP_ID:
      return { ...state, id: action.id }
    case SET_ROAD_MAP_NAME:
      return { ...state, name: action.name }
    default:
      return state
  }
}
