import React from 'react'
import Term from '../../Term'

function Page2() {
    return (
        <Term />
    )
}

export default Page2
