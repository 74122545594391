import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getContent } from "../../../services/Content";
import "./AgreementInfo.scss";

const AgreementInfo = ({ userId }) => {
  const [infoText, setinfoText] = useState("");
  useEffect(() => {
    getContent("agreement-info-page").then((data) => {
      const arr = data.content;
      setinfoText(arr[0].value);
    });
  }, []);
  return (
    <div className="info-agreement-container">
      <p dangerouslySetInnerHTML={{ __html: infoText }}></p>
    </div>
  );
};

const mapStateToProps = ({ userId }) => ({
  userId,
});

export default connect(mapStateToProps)(AgreementInfo);

