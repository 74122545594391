import React from "react"
import { connect } from "react-redux"
import { Route, Redirect, withRouter } from "react-router-dom"

import { set_user_img } from "../../redux/actions/profileAction"
import { set_roadmap_id, set_roadmap_name } from "../../redux/actions/roadmap"
import { set_sv_id, set_sv_name } from "../../redux/actions/superVision"
import { set_term_1 } from "../../redux/actions/term"
import { set_log_time } from "../../redux/actions/timeAction"
import {
  set_user_id,
  set_user_id_company,
  set_user_profile_name,
  set_user_id_role,
} from "../../redux/actions/user"

const PrivateRoute = ({
  userId,
  login_time,
  dispatch,
  canAccess,
  component: Component,
  ...rest
}) => {
  async function handleLogOut() {
    dispatch(set_user_id(null))
    dispatch(set_user_id_company(null))
    dispatch(set_user_id_role(null))
    dispatch(set_sv_id(null))
    dispatch(set_sv_name(null))
    dispatch(set_term_1(null))
    dispatch(set_roadmap_name(null))
    dispatch(set_roadmap_id(null))
    dispatch(set_log_time(null))
    dispatch(set_user_img(null))
    dispatch(set_user_profile_name(null))
    dispatch(set_term_1(null))
    // const res = await update_user_logout(userId.user_id).then(() => {
    //   history.push("/auth/login")
    // })
  }
  function checkTheTimeGab() {
    const curtime = new Date().getTime()
    if (curtime - login_time.time > 10000000) return true

    return false
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        userId.user_id !== null ? (
          canAccess ? (
            <Component
              checkTheTimeGab={checkTheTimeGab}
              handleLogOut={handleLogOut}
              {...props}
            />
          ) : (
            <Redirect
              to={{
                pathname: userId.user_id_role === "2" ? "/reports" : "/home",
              }}
            />
          )
        ) : (
          <Redirect
            to={{ pathname: "/auth/login", state: { from: props.location } }}
          />
        )
      }
    />
  )
}
const mapStateToProps = ({ userId, login_time }) => ({ userId, login_time })
export default withRouter(connect(mapStateToProps)(PrivateRoute))
