import Layout from "./components/Layout"
import Login from "./components/Account/Login/Login"
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom"
import QuestionsList from "./components/Supervision/QuestionsList"
import Questions from "./components/Supervision/questions/Questions"
import Finish from "./components/Supervision/FinishQuestions/Finish"
import Reminder from "./components/Supervision/reminder/Reminder"
import BarChart from "./components/Supervision/Chart/BarChart"
import ForgotPass from "./components/Account/ForgotPsssword/ForgotPass"
import ResetPass from "./components/Account/ResetPass/ResetPass"
import { connect } from "react-redux"
import History from "./components/History/History"
import BarChart2 from "./components/Supervision//Chart/BarChart2"
import PrivateRoute from "./components/routesRestricted/PrivateRoute"
import ChoosenImprovment from "./components/roudmap/thechoosenImprovment/ChoosenImprovment"
import ProccessAndAction from "./components/roudmap/thechoosenImprovment/processandaction/ProccessAndAction"
import AfterFinish from "./components/Supervision/FinishQuestions/AfterFinish/AfterFinish"
import FinishRoadmap from "./components/roudmap/FinishRoadmap"
import QuesionsInfoCheckBox from "./components/roudmap/questionscheckBox/QuesionsInfoCheckBox"
import AfterFinishRoadmap from "./components/roudmap/FinishRoadmap/AfterFinishRoadmap"
import Page1 from "./components/terms/modifTerms/page1/Page1"
import Page6 from "./components/terms/modifTerms/page6/Page6"
import Page2 from "./components/terms/modifTerms/page2/Page2"
import Page3 from "./components/terms/modifTerms/page3/Page3"
import Page4 from "./components/terms/modifTerms/page4/Page4"
import Page5 from "./components/terms/modifTerms/page5/Page5"
import CompletedRoadmap from "./components/roudmap/CompletedRoadmap"
import AbandonPage from "./components/roudmap/AbandonPage"
import ProfileImage from "./components/profile/ProfileImage"
import ChangeName from "./components/profile/ChangeName"
import ChangePassword from "./components/profile/Password"
import BarData from "./components/Supervision/Chart/chart_comps/BarData"
import Tutorial1 from "./components/Supervision/tutorials/Tut1"
import Tuto2 from "./components/Supervision/tutorials/Tut2"
import Tut3 from "./components/Supervision/tutorials/Tut3"
import Tut4 from "./components/Supervision/tutorials/Tut4"
import Tut5 from "./components/Supervision/tutorials/Tut5"
import Tut6 from "./components/Supervision/tutorials/Tut6"
import Tut7 from "./components/Supervision/tutorials/Tut7"
import Page1Tut from "./components/roudmap/tutsroadmap/Page1Tut"
import Page4Tut from "./components/roudmap/tutsroadmap/Page4Tut"
import Page2Tut from "./components/roudmap/tutsroadmap/Page2Tut"
import Page3Tut from "./components/roudmap/tutsroadmap/Page3Tut"
import CreateNewRoadMap from "./components/Supervision/Chart/chart_comps/createNewRoadMap/CreateNewRoadMap"
import AgreementInfo from "./components/Header_footer/AgreementInfo/AgreementInfo"
import Reports from "./components/Reports"
import Answers from "./components/Answers"

function App({ userId, login_time, dispatch }) {
  const userRoleId = userId.user_id_role

  return (
    <Router>
      <Layout>
        <Switch>
          <Route path="/auth/login" component={Login} />
          <Route path="/auth/forgot_pass" component={ForgotPass} />
          <Route path="/auth/reset_pass/:token" component={ResetPass} />

          <PrivateRoute
            canAccess
            path="/profile/img"
            component={ProfileImage}
          />
          <PrivateRoute canAccess path="/profile/name" component={ChangeName} />
          <PrivateRoute
            canAccess
            path="/profile/password"
            component={ChangePassword}
          />

          <PrivateRoute
            canAccess={userRoleId !== "2"}
            path="/home/info/1"
            component={Page1}
          />
          <PrivateRoute
            canAccess={userRoleId !== "2"}
            path="/home/info/2"
            component={Page2}
          />
          <PrivateRoute
            canAccess={userRoleId !== "2"}
            path="/home/info/3"
            component={Page3}
          />
          <PrivateRoute
            canAccess={userRoleId !== "2"}
            path="/home/info/4"
            component={Page4}
          />
          <PrivateRoute
            canAccess={userRoleId !== "2"}
            path="/home/info/5"
            component={Page5}
          />
          <PrivateRoute
            canAccess={userRoleId !== "2"}
            path="/home/info/6"
            component={Page6}
          />
          <PrivateRoute
            canAccess={userRoleId !== "2"}
            exact
            path="/history"
            component={History}
          />
          <PrivateRoute
            canAccess={userRoleId !== "2"}
            path="/agreement-info"
            component={AgreementInfo}
          />

          <PrivateRoute
            exact
            canAccess={userRoleId !== "2"}
            path="/home/questions_list"
            component={QuestionsList}
          />
          <PrivateRoute
            canAccess={userRoleId !== "2"}
            path="/home/questions_list/questions"
            component={Questions}
          />
          <PrivateRoute
            canAccess={userRoleId !== "2"}
            path="/home/questions_list/finish"
            component={Finish}
          />
          <PrivateRoute
            canAccess={userRoleId !== "2"}
            path="/home/after-finish"
            component={AfterFinish}
          />
          <PrivateRoute
            canAccess={userRoleId !== "2"}
            path="/home/finish_roadmap"
            component={FinishRoadmap}
          />

          <PrivateRoute
            exact
            canAccess={userRoleId !== "2"}
            path="/home/after_finish_roadmap"
            component={AfterFinishRoadmap}
          />
          <PrivateRoute
            exact
            canAccess={userRoleId !== "2"}
            path="/home/after_finish_roadmap/checkbox-roadmap"
            component={QuesionsInfoCheckBox}
          />
          <PrivateRoute
            canAccess={userRoleId !== "2"}
            path="/home/after_finish_roadmap/checkbox-roadmap/completed-roadmap"
            component={CompletedRoadmap}
          />
          <PrivateRoute
            canAccess={userRoleId !== "2"}
            path="/home/questions_list/chart/reminder"
            component={Reminder}
          />
          <PrivateRoute
            exact
            canAccess={userRoleId !== "2"}
            path="/home/questions_list/chart"
            component={BarChart}
          />
          <PrivateRoute
            canAccess={userRoleId !== "2"}
            path="/home/questions_list/chart/createroadmap"
            component={CreateNewRoadMap}
          />
          <PrivateRoute
            exact
            canAccess={userRoleId !== "2"}
            path="/home/questions_list/chart2"
            component={BarChart2}
          />
          <PrivateRoute
            exact
            canAccess={userRoleId !== "2"}
            path="/home/questions_list/chart2/RoadMapQuestions"
            component={ChoosenImprovment}
          />
          <PrivateRoute
            canAccess={userRoleId !== "2"}
            path="/home/questions_list/chart2/RoadMapQuestions/question"
            component={ProccessAndAction}
          />

          <PrivateRoute
            canAccess={userRoleId !== "2"}
            path="/home/questions_list/chart2/RoadMapQuestions/abandon-roadmap"
            component={AbandonPage}
          />

          <PrivateRoute
            canAccess={userRoleId !== "2"}
            path="/test"
            component={BarData}
          />
          <PrivateRoute
            canAccess={userRoleId !== "2"}
            path="/home/questions_list/tut1"
            component={Tutorial1}
          />
          <PrivateRoute
            canAccess={userRoleId !== "2"}
            path="/home/questions_list/tut2"
            component={Tuto2}
          />
          <PrivateRoute
            canAccess={userRoleId !== "2"}
            path="/home/questions_list/tut3"
            component={Tut3}
          />
          <PrivateRoute
            canAccess={userRoleId !== "2"}
            path="/home/questions_list/tut4"
            component={Tut4}
          />
          <PrivateRoute
            canAccess={userRoleId !== "2"}
            path="/home/questions_list/tut5"
            component={Tut5}
          />
          <PrivateRoute
            canAccess={userRoleId !== "2"}
            path="/home/questions_list/tut6"
            component={Tut6}
          />
          <PrivateRoute
            canAccess={userRoleId !== "2"}
            path="/home/questions_list/tut7"
            component={Tut7}
          />
          <PrivateRoute
            canAccess={userRoleId !== "2"}
            path="/home/roadmap/tut1"
            component={Page1Tut}
          />
          <PrivateRoute
            canAccess={userRoleId !== "2"}
            path="/home/roadmap/tut2"
            component={Page2Tut}
          />
          <PrivateRoute
            canAccess={userRoleId !== "2"}
            path="/home/roadmap/tut3"
            component={Page3Tut}
          />
          <PrivateRoute
            canAccess={userRoleId !== "2"}
            path="/home/roadmap/tut4"
            component={Page4Tut}
          />

          <PrivateRoute
            canAccess={userRoleId === "2"}
            path="/reports"
            component={Reports}
          />

          <PrivateRoute
            canAccess={userRoleId === "2"}
            path="/answers/*"
            component={Answers}
          />

          <Redirect to="/auth/login" />
        </Switch>
      </Layout>
    </Router>
  )
}

const mapStateToProps = ({ userId, login_time }) => ({
  userId,
  login_time,
})
export default connect(mapStateToProps)(App)
