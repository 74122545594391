import React from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Slide from "@material-ui/core/Slide"
import "./styles.scss"
import { showErrorMessage } from "../../../Account/FormsService"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function FinishRoadmapModal({
  title,
  handleConfirm = (f) => f,
  cancel = (f) => f,
  alertStatus = false,
  roadmapName,
  setroadmapName,
  inputFormError,
}) {
  const handleOnEnter = (e) => {
    if (e.keyCode === 13) {
      handleConfirm()
    }
  }

  return (
    <Dialog
      open={alertStatus}
      TransitionComponent={Transition}
      keepMounted
      onClose={cancel}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className="confirm-finish-rm-modal"
    >
      <span onClick={cancel} className="close-icon">
        x
      </span>
      <DialogTitle id="alert-dialog-slide-title">
        <span dangerouslySetInnerHTML={{ __html: title }}></span>
      </DialogTitle>
      <DialogContent>
        <div className="input-container">
          <input
            id="outlined-basic"
            className="input"
            label="Name"
            placeholder="Nom"
            value={roadmapName}
            onChange={(e) => setroadmapName(e.target.value)}
            onKeyDown={(e) => handleOnEnter(e)}
          />
          <div className="error-section">
            {inputFormError && showErrorMessage(inputFormError)}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm} className="ok-btn">
          Valider
        </Button>
        <Button onClick={cancel} className="cancel-btn">
          Annuler
        </Button>
      </DialogActions>
    </Dialog>
  )
}
