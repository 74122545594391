import { SET_CUR_QUESTION_NUMBER, SET_NUMBER_QUESTION } from "../constants"
// import {set_curQuestionNum , set_numOfQuestions} from '../actions/questios'
const initialState = {
  numOfQues: 0,
  curQuestionNum: 0,
}

const questionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CUR_QUESTION_NUMBER:
      return { ...state, curQuestionNum: action.number }
    case SET_NUMBER_QUESTION:
      return { ...state, numOfQues: action.number }
    default:
      return state
  }
}

export default questionReducer
