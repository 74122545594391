import {
  SET_USER,
  SET_TERM_NUMBER,
  SET_USER_ID,
  SET_USER_ID_COMPANY,
  SET_USER_PROFILE_NAME,
  SET_USER_TUT,
  SET_USER_ID_AUDIT,
  SET_USER_ID_ROLE,
} from "../constants"

export const set_user = (user) => ({
  user,
  type: SET_USER,
})

export const set_termNumber = (termNumber) => ({
  termNumber,
  type: SET_TERM_NUMBER,
})

export const set_user_id = (id) => ({
  type: SET_USER_ID,
  user_id: id,
})

export const set_user_id_company = (id) => ({
  type: SET_USER_ID_COMPANY,
  user_id_company: id,
})

export const set_user_id_role = (id) => ({
  type: SET_USER_ID_ROLE,
  user_id_role: id,
})

export const set_user_profile_name = (profileName) => ({
  type: SET_USER_PROFILE_NAME,
  profileName,
})
export const set_user_tuts = (tut) => ({
  type: SET_USER_TUT,
  tut,
})
export const set_user_id_audit = (id) => ({
  type: SET_USER_ID_AUDIT,
  id,
})
