import { SET_SV_ID } from "../constants"

export const supervisionId = (state = "", action) => {
  switch (action.type) {
    case SET_SV_ID:
      return action
    default:
      return state
  }
}
