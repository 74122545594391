import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getSuperVisionTheFile } from "../../../services/files";
import "./tut3styles.scss";
import pdfImg from "../../../images/pdf_file.png";
import { CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router";
import CustomNextBtn from "../../utilities/Buttons/NextButton";
import BackBtn from "../../utilities/Buttons/BackBtn";
import { getContent } from "../../../services/Content";

const rondomText =
  "Chart with other corner radius and larger padding between arcs";

function Tut3({ svId, userId }) {
  const [pdfLoading, setpdfLoading] = useState(false);

  const [loading, setloading] = useState(false);

  const [title, settitle] = useState("");
  const [firstText, setfirstText] = useState("");
  const [secondText, setsecondText] = useState("");
  const [nextBtnText, setnextBtnText] = useState("");
  const [prevBtnText, setprevBtnText] = useState("");
  const [pdfText, setpdfText] = useState("");

  const go = useHistory();

  useEffect(() => {
    getPageContent();
  }, []);

  async function getSvPdfFile() {
    setpdfLoading(true);
    let res = await getSuperVisionTheFile(svId.id, userId.user_id_company);
    setpdfLoading(false);
  }

  const getPageContent = () => {
    setloading(true);
    getContent("tutorial-3").then((data) => {
      const arr = data.content;
      settitle(arr[0].value);
      setfirstText(arr[1].value);
      setsecondText(arr[2].value);
      setnextBtnText(arr[3].value);
      setprevBtnText(arr[4].value);
      setpdfText(arr[5].value);
      setloading(false);
    });
  };

  const loadingCircule = (size) => {
    return (
      <CircularProgress
        size={size}
        thickness={4}
        style={{ color: "#ff6500", margin: "auto" }}
      />
    );
  };

  if (loading) {
    return loadingCircule(80);
  }

  return (
    <div className="tut3__container">
      <p className="text" dangerouslySetInnerHTML={{ __html: firstText }}></p>
      <h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
      <div className="pdf__">
        {pdfLoading === true ? (
          loadingCircule(40)
        ) : (
          <div className="pdf-content" onClick={getSvPdfFile}>
            <img src={pdfImg} alt="" />
            {/* <p dangerouslySetInnerHTML={{ __html: pdfText }}></p> */}
          </div>
        )}
      </div>
      <p className="text" dangerouslySetInnerHTML={{ __html: secondText }}></p>
      <div className="btn__holder">
        <BackBtn onClick={() => go.push("/home/questions_list/tut2")}>
          <span dangerouslySetInnerHTML={{ __html: prevBtnText }}></span>
        </BackBtn>

        <CustomNextBtn onClick={() => go.push("/home/questions_list/tut7")}>
          <span dangerouslySetInnerHTML={{ __html: nextBtnText }}></span>
        </CustomNextBtn>
      </div>
    </div>
  );
}
const mapStateToProps = ({ svId, footer, userId, roadMapId }) => ({
  svId,
  footer,
  userId,
  roadMapId,
});
export default connect(mapStateToProps)(Tut3);
