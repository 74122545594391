import React from "react";
import { useHistory } from "react-router";
import IntroInfo from "../../IntroInfo/IntroInfo";

function Page5() {
  const go = useHistory();
  return <IntroInfo apiPage="fifth_info_page" nextPage={() => go.push("/home/info/6")} />;
}

export default Page5;
