import { Button, CircularProgress } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import AdjustIcon from "@material-ui/icons/Adjust"
import PanoramaFishEyeIcon from "@material-ui/icons/PanoramaFishEye"
import { set_reminder } from "../../../services/chart"
import "./style.scss"
import moment from "moment"
import CustomNextBtn from "../../utilities/Buttons/NextButton"

import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormControl from "@material-ui/core/FormControl"
import FormLabel from "@material-ui/core/FormLabel"
import { useHistory } from "react-router"
import Alert from "../../utilities/Modals/alert/Alert"
import { getContent } from "../../../services/Content"
import imgLine from "../../../images/line.png"
import { set_log_time } from "../../../redux/actions/timeAction"

const Reminder = ({ svId, checkTheTimeGab, handleLogOut, dispatch }) => {
  useEffect(() => {
    handleTheautologout()
  }, [])
  function handleTheautologout() {
    if (checkTheTimeGab()) {
      handleLogOut()
    } else {
      dispatch(set_log_time(new Date().getTime()))
    }
  }

  const [reminderTime, setreminderTime] = useState(0)

  const [value, setValue] = useState("")
  const [title, settitle] = useState("")
  const [btnText, setbtnText] = useState("")
  const [alertText, setAlertText] = useState("")

  const [btnCancelText, setbtnCancelText] = useState("")
  const [alertStatus, setalertStatus] = useState(false)
  const [loading, setloading] = useState(false)

  const go = useHistory()

  const oneWeek = moment().add(1, "W").format("YYYY-MM-DD HH:mm:ss")
  const oneMonth = moment().add(1, "M").format("YYYY-MM-DD HH:mm:ss")
  const threeMonths = moment().add(3, "M").format("YYYY-MM-DD HH:mm:ss")

  useEffect(() => {
    setloading(true)
    getContent("reminder_page").then((data) => {
      const arr = data.content
      settitle(arr[0].value)
      setbtnText(arr[1].value)
      setbtnCancelText(arr[2].value)
      setAlertText(arr[3].value)
      setloading(false)
    })
  }, [])

  const handleRminder = () => {
    if (reminderTime === 0) {
      return setalertStatus(true)
    }
    set_reminder(svId.id, reminderTime)
      .then((res) => res.json())
      .then(() => {
        go.push("/home/questions_list/chart")
      })
  }

  const handleChange = (event) => {
    const chosenVal = event.target.value
    setValue(event.target.value)

    if (chosenVal === "oneWeek") {
      setreminderTime(oneWeek)
    }
    if (chosenVal === "oneMonth") {
      setreminderTime(oneMonth)
    }
    if (chosenVal === "threeMonths") {
      setreminderTime(threeMonths)
    }
  }

  const handleCloseAlert = () => {
    setalertStatus(false)
  }

  const handleGoBack = () => {
    go.push("/home/questions_list/chart")
  }

  if (loading) {
    return (
      <div style={{ textAlign: "center" }}>
        <CircularProgress
          size={100}
          thickness={4}
          style={{ color: "#ff6500", marginRight: "3px" }}
        />
      </div>
    )
  }

  return (
    <div className="reminder-container">
      <Alert
        alertStatus={alertStatus}
        handleCloseAlert={handleCloseAlert}
        // title="veuillez sélectionner une heure de rappel"
        title={alertText}
      />
      <div
        className="info-contaier"
        dangerouslySetInnerHTML={{ __html: title }}
      ></div>

      <FormControl component="fieldset" className="options-container">
        <RadioGroup value={value} onChange={handleChange} className="item">
          <FormControlLabel
            className="radio-icons"
            value="oneWeek"
            control={<Radio />}
            label="1 Semaine"
            className={`${value === "oneWeek" ? "active" : ""} radio-icons`}
          />
          <img src={imgLine} alt="" />
          <FormControlLabel
            className="radio-icons"
            value="oneMonth"
            control={<Radio />}
            label="1 Mois"
            className={`${value === "oneMonth" ? "active" : ""} radio-icons`}
          />
          <img src={imgLine} alt="" />
          <FormControlLabel
            className="radio-icons"
            value="threeMonths"
            control={<Radio />}
            label="3 Mois"
            className={`${value === "threeMonths" ? "active" : ""} radio-icons`}
          />
          {/* <img src={imgLine} alt="" /> */}
        </RadioGroup>
      </FormControl>
      <div className="btns-container">
        <CustomNextBtn className="btn cancel" onClick={handleGoBack}>
          <span dangerouslySetInnerHTML={{ __html: btnCancelText }}></span>
        </CustomNextBtn>
        <CustomNextBtn className="btn" onClick={handleRminder}>
          <span dangerouslySetInnerHTML={{ __html: btnText }}></span>
        </CustomNextBtn>
      </div>
    </div>
  )
}

const mapStateToProps = ({ svId }) => ({ svId })
export default connect(mapStateToProps)(Reminder)
