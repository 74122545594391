import React, { useEffect, useState } from "react"
import { v4 } from "uuid"
import CustomTooltip from "./CustomTooltip"
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts"
function BarChartUtl({
  hideX = false,
  chartData,
  keyOfBar1,
  keyOfBar2,
  getTheColors = (f) => f,
  both,
  getTheColors2 = (f) => f,
  width = "100%",
  //   height="95%"
}) {
  return (
    <ResponsiveContainer width={width} height="unset" aspect={4}>
      <BarChart height={550} data={chartData}>
        {/* <Tooltip
              content={<CustomTooltip />}
              cursor={{ fill: "transparent" }}/> 
        */}
        <YAxis />
        {hideX === false && <XAxis dataKey="text" />}
        <Bar barSize={30} dataKey={keyOfBar1} fill="#00CED1">
          {chartData.map((item, i) => (
            <Cell key={v4()} radius={5} fill={getTheColors(i)} />
          ))}
        </Bar>
        {both && (
          <Bar barSize={30} dataKey={keyOfBar2} fill="#00CED1">
            {chartData.map((item, i) => (
              <Cell key={v4()} radius={5} fill={getTheColors2(i)} />
            ))}
          </Bar>
        )}
      </BarChart>
    </ResponsiveContainer>
  )
}

export default BarChartUtl
