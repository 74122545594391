import { prefixApi, prefixHeaderObj } from "./prefix";

import {getTheTime} from './dateTime'

const update_answerApi = `${prefixApi}answers/create_or_update`

export const submitAnswer = async (
  id_supervision = "",
  id_question = "",
  id_choice = ""
) => {
  const dateTime = await getTheTime()
  
  var details = {
    id_supervision: id_supervision,
    id_question: id_question,
    id_choice: id_choice,
    dateTime : dateTime.datetime
  };
  var formBody = [];
  for (var property in details) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  return fetch(update_answerApi, {
    method: "POST",
    headers: prefixHeaderObj,
    body: formBody,
  });
};
