import React, { useEffect, useState } from "react"
import "./styles.scss"
import { getContent } from "../../../../services/Content"
import { CircularProgress } from "@material-ui/core"
import vLine from "../../../../images/v-line.png"
import { prefix_api_set_img_content } from "../../../../services/prefix"

const AppInfo = ({ type }) => {
  const [img, setimg] = useState("")
  const [title, settitle] = useState("")
  const [text, setText] = useState()
  const [loading, setloading] = useState(false)

  useEffect(() => {
    setloading(true)
    getContent(type).then((data) => {
      let arr = data.content
      settitle(arr[0].value)
      setText(arr[1].value)
      if (arr[2]) {
        setimg(`${prefix_api_set_img_content}${arr[2].file}`)
      }
      setloading(false)
    })
  }, [])

  return (
    <div className="app-info-container">
      {loading ? (
        <div style={{ margin: "auto" }}>
          <CircularProgress
            size={50}
            thickness={4}
            style={{ color: "#ff6500", marginRight: "3px" }}
          />
        </div>
      ) : (
        <>
          <img src={vLine} alt="" className="left-line" />
          <div className="title-container">
            {img && <img className="logo" src={img} alt="" />}
            <h5
              className="title"
              dangerouslySetInnerHTML={{ __html: title }}
            ></h5>
          </div>
          <p className="text" dangerouslySetInnerHTML={{ __html: text }}></p>
          <img src={vLine} alt="" className="right-line" />
        </>
      )}
    </div>
  )
}

export default AppInfo
