import React from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogTitle from "@material-ui/core/DialogTitle"
import Slide from "@material-ui/core/Slide"
import "./style.scss"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function Alert({
  title,
  handleCloseAlert = (f) => f,
  okButton = (f) => f,
  type = "warning",
  alertStatus = false,
}) {
  return (
    <Dialog
      open={alertStatus}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseAlert}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className="alert-modal"
    >
      <span onClick={handleCloseAlert} className="close-icon">
        x
      </span>
      <DialogTitle id="alert-dialog-slide-title">
        <span dangerouslySetInnerHTML={{ __html: title }}></span>
      </DialogTitle>
      {/* <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Let Google help apps determine location. This means sending anonymous
          location data to Google, even when no apps are running.
        </DialogContentText>
      </DialogContent> */}
      <DialogActions>
        <Button onClick={okButton} className="ok-btn">
          OK
        </Button>
        {/* <Button onClick={handleCloseAlert} color="primary">
          Agree
        </Button> */}
      </DialogActions>
    </Dialog>
  )
}
