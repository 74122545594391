import React, { useEffect, useCallback } from "react"
import { connect } from "react-redux"
import { useHistory } from "react-router"
import Main from "../pages1-6/Main"
import "./styelpage1.scss"

const Page1 = ({
  svId,
  tr_,
  dispatch,
  roadMapId,
  checkTheTimeGab,
  handleLogOut,
}) => {
  const go = useHistory()

  const handleGoToNextPage = useCallback(() => {
    go.push("/home/info/2")
  }, [go])

  useEffect(() => {
    if (tr_.tr_1) {
      handleGoToNextPage()
    }
  }, [])

  useEffect(() => {
    if (roadMapId.id !== null) {
      go.push("/home/questions_list/chart")
    } else if (svId.id !== null) {
      go.push("/home/questions_list")
    }
  }, [])

  return (
    <div className="page-1-conatiner">
      <Main pageContent="first_page_info" clickBtn={handleGoToNextPage} />
    </div>
  )
}

const mapStateToProps = ({ svId, roadMapId, tr_ }) => ({
  svId,
  roadMapId,
  tr_,
})

export default connect(mapStateToProps)(Page1)
