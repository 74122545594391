import {
  userReducer,
  userIdReducer,
  userProfileNameReducer,
} from "./userReducer"
import questionReducer from "./questionReducer"
import { combineReducers } from "redux"
import { persistReducer } from "redux-persist"
import { sVReducer } from "./supervisionReducer"
import storage from "redux-persist/lib/storage"
import { termRedu } from "./termRed"
import { roadMapReducer } from "./roadmapRducer"
import { time_reducer } from "./timeReducer"
import { profileReducer } from "./profileReducer"
import { commonReducer } from "./commonReducer"
import { firstReportReducer } from "./reportsReducer"
import { supervisionId } from "./supervisionId"

const persistConfig = {
  key: "root1",
  storage,
  whitelist: [
    "userId",
    "tr_",
    "svId",
    "roadMapId",
    "login_time",
    "profile",
    "profileName",
  ],
}
const rootRedducer = combineReducers({
  user: userReducer,
  questionsInfo: questionReducer,
  userId: userIdReducer,
  svId: sVReducer,
  tr_: termRedu,
  roadMapId: roadMapReducer,
  login_time: time_reducer,
  profile: profileReducer,
  profileName: userProfileNameReducer,
  common: commonReducer,
  firstReport: firstReportReducer,
  supervisionId: supervisionId,
})
export default persistReducer(persistConfig, rootRedducer)
