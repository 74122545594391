import {
  SET_SUPERVISION_ID,
  SET_SUPERVISION_NAME,
  SET_QUESTIONS_FINISHED_ONCE,
  SET_STATE_TUTS_HUMBURGER,
} from "../constants"

export const set_sv_id = (id) => ({
  type: SET_SUPERVISION_ID,
  id,
})

export const set_sv_name = (name) => ({
  type: SET_SUPERVISION_NAME,
  name,
})

export const set_questions_finished_once = (finished) => ({
  type: SET_QUESTIONS_FINISHED_ONCE,
  finished,
})

export const set_state_from_tuts_humbrger = (cur) => ({
  type: SET_STATE_TUTS_HUMBURGER,
  cur,
})
