const initState = { tr_1: null }
// to check user check the term in page3 or not
export const termRedu = (state = initState, action) => {
  switch (action.type) {
    case "set_term1":
      return { ...state, tr_1: action.cur }
    default:
      return state
  }
}
