import { CircularProgress } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import "./style.scss"
import { useHistory } from "react-router"
import {
  change_supervision_flow,
  get_status_sv,
  supervisionContent,
} from "../../../services/superVision"
import CustomNextBtn from "../../utilities/Buttons/NextButton"
import Alert from "../../utilities/Modals/alert/Alert"
import {
  set_questions_finished_once,
  set_sv_name,
} from "../../../redux/actions/superVision"
import imgLine from "../../../images/line.png"
import FinishModal from "../../utilities/Modals/finishModal"
import { set_log_time } from "../../../redux/actions/timeAction"
import { update_terms } from "../../../services/terms"
import { get_second_connection } from "../../../services/secondconnection"
const Finish = ({
  userId,
  svId,
  dispatch,
  handleLogOut,
  checkTheTimeGab,
  ...rest
}) => {
  const [supervisionName, setsupervisionName] = useState("")
  const [title, setTitle] = useState("")
  const [subTitle, setSubTitle] = useState("")
  const [text, setText] = useState("")
  const [inputText, setInputText] = useState("")
  const [btnText, setBtnText] = useState("")
  const [alertText, setAlertText] = useState("")
  const [alertStatus, setalertStatus] = useState(false)
  const [loading, setLoading] = useState(false)
  const [finishModalStatus, setFinishModalStatus] = useState(false)

  const go = useHistory()
  useEffect(() => {
    handleTheautologout()
  }, [])
  function handleTheautologout() {
    if (checkTheTimeGab()) {
      handleLogOut()
    } else {
      dispatch(set_log_time(new Date().getTime()))
    }
  }

  async function getSuperVisionStatus() {
    const res = await get_status_sv(svId.id)
    const data = await res.json()
    return data
  }
  async function getWhereToData() {
    const res = await get_second_connection(userId.user_id)
    return await res.json()
  }
  useEffect(() => {
    ;(async () => {
      const data = await getSuperVisionStatus()
      if (data.status !== "finished") {
        alert("please finish the quesions frist")
        go.push("/home/questions_list")
      } else {
        const resWhereTo = await getWhereToData()
        if (
          resWhereTo.supervision.name !== null &&
          resWhereTo.supervision.name !== "null" &&
          resWhereTo.supervision.name !== "" &&
          resWhereTo.supervision.name !== "new super Vision"
        ) {
          dispatch(set_sv_name(resWhereTo.supervision))
          go.push("/home/questions_list/chart")
        }
      }
    })()
  }, [])
  useEffect(() => {
    setLoading(true)
    supervisionContent("finish_supervision").then((data) => {
      setTitle(data.content[0].value)
      setSubTitle(data.content[1].value)
      setText(data.content[2].value)
      setInputText(data.content[3].value)
      setBtnText(data.content[4].value)
      setAlertText(data.content[5].value)
      setLoading(false)
    })
  }, [])

  async function changeTheSVflow() {
    const tmpName = supervisionName.replace(/\s/g, "")
    if (!tmpName.length) {
      return setalertStatus(true)
    }
    const response = await change_supervision_flow(
      svId.id,
      supervisionName,
      "finished"
    )

    const res = await response.json()
    if (res.message === "Supervision updated successfully.") {
      dispatch(set_sv_name(supervisionName))
      setFinishModalStatus(true)
      await update_terms({ questions_finished_once: 1 }, userId.user_id)
      dispatch(set_questions_finished_once(1))
      // go.push("/home/after-finish");
    } else {
      console.log("something goes wrong")
    }
  }

  function handleCloseAlert() {
    setalertStatus(false)
  }

  function handleCloseFinishModal() {
    setFinishModalStatus(false)
  }
  return (
    <div className="finish-content">
      <Alert
        alertStatus={alertStatus}
        handleCloseAlert={handleCloseAlert}
        title={alertText}
      />
      <FinishModal
        routePage="/home/questions_list/chart"
        type="after_finish_supervision"
        finishModalStatus={finishModalStatus}
        handleCloseFinishModal={handleCloseFinishModal}
        spv={true}
      />
      {loading ? (
        <div style={{ textAlign: "center" }}>
          <CircularProgress
            size={80}
            thickness={4}
            style={{ color: "#ff6500", margin: "auto" }}
          />
        </div>
      ) : (
        <div className="finish-container">
          <div className="title-container">
            <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>
            <h2 dangerouslySetInnerHTML={{ __html: subTitle }}></h2>
          </div>
          {/* <div className="line-horizontal"></div> */}
          <img src={imgLine} className="img-line" alt="" />

          <div
            className="info-container"
            dangerouslySetInnerHTML={{ __html: text }}
          ></div>

          <div className="input-container">
            <p
              style={{ margin: 0 }}
              dangerouslySetInnerHTML={{ __html: inputText }}
            ></p>
            <input
              id="outlined-basic"
              className="input"
              label="Name"
              placeholder="Nom"
              value={supervisionName}
              onChange={(e) => setsupervisionName(e.target.value)}
            />
            <div style={{ marginTop: "100px" }}>
              <CustomNextBtn className="btn" onClick={changeTheSVflow}>
                <span dangerouslySetInnerHTML={{ __html: btnText }}></span>
              </CustomNextBtn>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = ({ svId, userId }) => ({ svId, userId })

export default connect(mapStateToProps)(Finish)
