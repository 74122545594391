import { CircularProgress } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router"
import { supervisionContent } from "../../../../services/superVision"
import CustomNextBtn from "../../../utilities/Buttons/NextButton"
import imgLine from "../../../../images/line.png"
import "./style.scss"
import { set_log_time } from "../../../../redux/actions/timeAction"
import { useDispatch } from "react-redux"

const AfterFinish = ({ checkTheTimeGab, handleLogOut }) => {
  const go = useHistory()
  const dispatch = useDispatch()
  useEffect(() => {
    handleTheautologout()
  }, [])
  function handleTheautologout() {
    if (checkTheTimeGab()) {
      handleLogOut()
    } else {
      dispatch(set_log_time(new Date().getTime()))
    }
  }
  const [title, setTitle] = useState("")
  const [text, setText] = useState("")
  const [btnText, setBtnText] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    supervisionContent("after_finish_supervision").then((data) => {
      setTitle(data.content[0].value)
      setText(data.content[1].value)
      setBtnText(data.content[3].value)
      setLoading(false)
    })
  }, [])

  if (loading) {
    return (
      <CircularProgress
        size={80}
        thickness={4}
        style={{ color: "#ff6500", margin: "auto" }}
      />
    )
  } else {
    return (
      <div className="after-finish-wrapper">
        <div className="after-finish-container">
          <div className="after-finish-content">
            <h1
              className="title"
              dangerouslySetInnerHTML={{ __html: title }}
            ></h1>
            <p dangerouslySetInnerHTML={{ __html: text }}></p>
            <img src={imgLine} alt="" />
          </div>

          <CustomNextBtn onClick={() => go.push(`/home/questions_list/chart`)}>
            <span dangerouslySetInnerHTML={{ __html: btnText }}></span>
          </CustomNextBtn>
        </div>
      </div>
    )
  }
}

export default AfterFinish
