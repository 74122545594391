import {prefixApi, prefixHeaderAuth} from './prefix'

import $ from "jquery";

const api_uplaod_img = `${prefixApi}user/change_profile_image/`
export async function uploadTheimg(file, userId) {
    var form = new FormData();
    form.append("file", file);
    var settings = {
        "async": true,
        "crossDomain": true,
        "url": api_uplaod_img + userId,
        "method": "POST",
        "processData": false,
        "contentType": false,
        "mimeType": "multipart/form-data",
        "data": form,
        headers: prefixHeaderAuth,
      };
      const res = $.ajax(settings).done(function (response) {
        return response
      });
      return res

}