import React, { useEffect, useState } from "react"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import Slide from "@material-ui/core/Slide"
import "./style.scss"
import { supervisionContent } from "../../../../services/superVision"
import { useHistory } from "react-router-dom"
import { CircularProgress } from "@material-ui/core"
import vLine from "../../../../images/v-line.png"
import CustomNextBtn from "../../Buttons/NextButton"
import { connect } from "react-redux"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

function FinishModal({
  handleCloseFinishModal = (f) => f,
  handleComplete = (f) => f,
  type,
  finishModalStatus = false,
  routePage,
  validateRoadmap = false,
  spv,
  svId,
  newRoadMapId,
}) {
  const { push } = useHistory()
  const [title, setTitle] = useState("")
  const [text, setText] = useState("")
  const [btnText, setBtnText] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    supervisionContent(type).then((data) => {
      setTitle(data.content[0].value)
      setText(data.content[1].value)
      setBtnText(data.content[2].value)
      setLoading(false)
    })
  }, [])

  const handleRoadmapValidation = () => {
    handleComplete().then(() => {
      push("/home/questions_list/chart")
    })
  }

  function checkWtNext() {
    if (spv === true) {
      if (svId.tut !== "0") {
        push("/home/questions_list/chart")
        return
      }
      push("/home/questions_list/tut1")
    } else {
      push({
        pathname: routePage,
        state: {
          newRoadMapId,
        },
      })
    }
  }
  return (
    <Dialog
      open={finishModalStatus}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseFinishModal}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className="finish-modal"
    >
      {loading ? (
        <div style={{ textAlign: "center", margin: "auto" }}>
          <CircularProgress
            size={50}
            thickness={4}
            style={{ color: "#ff6500" }}
          />
        </div>
      ) : (
        <div className="info-modal-content">
          {/* <span onClick={handleCloseFinishModal} className="close-icon"></span> */}
          <DialogTitle id="alert-dialog-slide-title">
            <span dangerouslySetInnerHTML={{ __html: title }}></span>
          </DialogTitle>
          <div className="img-text-content">
            <img src={vLine} alt="" className="left-line" />

            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                <span dangerouslySetInnerHTML={{ __html: text }}></span>
              </DialogContentText>
            </DialogContent>
            <img src={vLine} alt="" className="right-line" />
            <DialogActions>
              <CustomNextBtn
                className="ok-btn"
                onClick={() => {
                  if (validateRoadmap) {
                    handleRoadmapValidation()
                  } else {
                    checkWtNext()
                  }
                }}
              >
                <span dangerouslySetInnerHTML={{ __html: btnText }}></span>
              </CustomNextBtn>
            </DialogActions>
          </div>
        </div>
      )}
    </Dialog>
  )
}
const mapStateToProps = ({ svId }) => ({ svId })
export default connect(mapStateToProps)(FinishModal)
