import { SET_CUR_QUESTION_NUMBER, SET_NUMBER_QUESTION } from "../constants"

export const set_numOfQuestions = (number) => ({
  type: SET_NUMBER_QUESTION,
  number,
})
export const set_curQuestionNum = (number) => ({
  type: SET_CUR_QUESTION_NUMBER,
  number,
})
