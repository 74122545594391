import { CircularProgress } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { getContent } from "../../../../services/Content"
import img_ from "../../../../images/icon-1.png"
import CustomNextBtn from "../../../utilities/Buttons/NextButton"
import "./style.scss"

const Main = ({ clickBtn, pageContent }) => {
  const [text, settext] = useState("")
  const [loading, setloading] = useState(false)
  const [btnText, setbtnText] = useState("")

  useEffect(() => {
    setloading(true)
    getContent(pageContent).then((data) => {
      settext(data.content[0].value)
      setbtnText(data.content[1].value)
      setloading(false)
    })
  }, [])

  if (loading) {
    return (
      <div style={{ textAlign: "center" }}>
        <CircularProgress
          size={50}
          thickness={4}
          style={{ color: "#ff6500", margin: "auto" }}
        />
      </div>
    )
  } else {
    return (
      <div className="page-1-6">
        <div className="main-info-container">
          <img src={img_} alt="" />
          <div className="main-info-p">
            <p dangerouslySetInnerHTML={{ __html: text }}></p>
          </div>
        </div>
        <div className="next-btn">
          <CustomNextBtn onClick={clickBtn}>
            <span dangerouslySetInnerHTML={{ __html: btnText }}></span>
          </CustomNextBtn>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps)(Main)
