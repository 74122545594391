import { Button } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import "./styles.scss";

const ExitBtn = ({ text = "ACCUEIL", routePage = "" }) => {
  let go = useHistory();
  return (
    <div className="exit-btn-container">
      <Button className="back-btn" onClick={() => go.push(routePage)}>
        <span dangerouslySetInnerHTML={{ __html: text }}></span>
      </Button>
    </div>
  );
};

export default ExitBtn;
