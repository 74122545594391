import { prefixApi, prefixHeaderObj } from "./prefix";

const api_terms = `${prefixApi}content/get/`;
const api_update_terms = `${prefixApi}user/update/`;

export const get_terms = (kind) => {
  var details = {
    // key: kind,
    page: kind
  };
  var formBody = [];
  for (var property in details) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");

  return fetch(api_terms, {
    method: "POST",
    headers: prefixHeaderObj,
    body: formBody,
  });
};

export const update_terms = (details, userId) => {
  
  var formBody = [];
  for (var property in details) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");

  return fetch(`${api_update_terms}${userId}`, {
    method: "PUT",
    headers: prefixHeaderObj,
    body: formBody,
  })
};
