import {
  SET_SUPERVISION_ID,
  SET_SUPERVISION_NAME,
  SET_USER_TUT,
  SET_QUESTIONS_FINISHED_ONCE,
  SET_STATE_TUTS_HUMBURGER,
} from "../constants"

const svInitState = {
  id: "null",
  name: null,
  tut: null,
  ques_finished_once: null,
  fromTutsHumberger: 0,
}

export const sVReducer = (state = svInitState, action) => {
  switch (action.type) {
    case SET_SUPERVISION_ID:
      return { ...state, id: action.id }
    case SET_USER_TUT:
      return { ...state, tut: action.tut }
    case SET_SUPERVISION_NAME:
      return { ...state, name: action.name }
    case SET_QUESTIONS_FINISHED_ONCE:
      return { ...state, ques_finished_once: action.finished }
    case SET_STATE_TUTS_HUMBURGER:
      return { ...state, fromTutsHumberger: action.cur }
    default:
      return state
  }
}
