import React, { useEffect, useState } from "react";
import "./tut2styls.scss";

import BarChartUtl from "./BarChartUtl";
import { useHistory } from "react-router";
import CustomNextBtn from "../../utilities/Buttons/NextButton";
import BackBtn from "../../utilities/Buttons/BackBtn";
import { get_chart } from "../../../services/chart";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { getContent } from "../../../services/Content";
const rondomText =
  "Chart with other corner radius and larger padding between arcs";

function Tuto2({ svId }) {
  const [chartData, setchartData] = useState([]);

  const [title, settitle] = useState("");
  const [firstText, setfirstText] = useState("");
  const [secondText, setsecondText] = useState("");
  const [nextBtnText, setnextBtnText] = useState("");
  const [prevBtnText, setprevBtnText] = useState("");

  const [loading, setloading] = useState(false);
  const go = useHistory();

  useEffect(() => {
    (async () => {
      setloading(true);
      await getParamChart();
      getPageContent();
    })();
  }, []);

  const getPageContent = () => {
    getContent("tutorial-2").then((data) => {
      const arr = data.content;
      settitle(arr[0].value);
      setfirstText(arr[1].value);
      setsecondText(arr[2].value);
      setnextBtnText(arr[3].value);
      setprevBtnText(arr[4].value);
      setloading(false);
    });
  };

  async function getParamChart() {
    const res = await get_chart(svId.id);
    let response = await res.json();

    if (response.data.length > 10) {
      setchartData(() => {
        let arr = [...response.data].map((item, i) => ({
          name: "points",
          Paramètres: parseInt(item.no_of_answers, 10),
          text: i,
        }));
        return arr;
      });
    } else {
      setchartData(() => {
        let arr = [...response.data].map((item, i) => ({
          name: "points",
          Paramètres: parseInt(item.no_of_answers, 10),
          text: i + 1,
        }));
        if (arr.length < 10) {
          while (arr.length < 10)
            arr.push({ name: "points", Paramètres: 0, text: arr.length + 1 });
        }

        return [{ name: "points", Paramètres: 0, text: 0 }, ...arr];
      });
    }
  }

  function getTheColors(idx) {
    if (idx >= 0 && idx <= 2) return "red";
    if (idx >= 3 && idx <= 4) return "orange";
    if (idx >= 5 && idx <= 6) return "yellow";
    if (idx >= 7) return "green";
  }

  if (loading) {
    return (
      <CircularProgress
        size={100}
        thickness={4}
        style={{ color: "#ff6500", margin: "auto" }}
      />
    );
  }
  return (
    <div className="tut2__container">
      <p className="text" dangerouslySetInnerHTML={{ __html: firstText }}></p>
      <div className="barchart__parent">
        <h1 className="title" dangerouslySetInnerHTML={{ __html: title }}></h1>
        <BarChartUtl
          width="90%"
          keyOfBar1={"Paramètres"}
          chartData={chartData}
          getTheColors={getTheColors}
        />
      </div>
      <p className="bottom-text" dangerouslySetInnerHTML={{ __html: secondText }}></p>
      <div className="btn__holder">
        <BackBtn onClick={() => go.push("/home/questions_list/tut1")}>
          <span dangerouslySetInnerHTML={{ __html: prevBtnText }}></span>
        </BackBtn>
        <CustomNextBtn onClick={() => go.push("/home/questions_list/tut3")}>
          <span dangerouslySetInnerHTML={{ __html: nextBtnText }}></span>
        </CustomNextBtn>
      </div>
    </div>
  );
}

const mapStateToProps = ({ svId }) => ({
  svId,
});

export default connect(mapStateToProps)(Tuto2);
