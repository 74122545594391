import React, { useEffect, useState } from "react"
import { connect, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { get_user_data } from "../../services/auth"
import { getContent } from "../../services/Content"

import { set_user_img } from "../../redux/actions/profileAction"
import { set_roadmap_id, set_roadmap_name } from "../../redux/actions/roadmap"
import { set_sv_id, set_sv_name } from "../../redux/actions/superVision"
import { set_term_1 } from "../../redux/actions/term"
import { set_log_time } from "../../redux/actions/timeAction"
import {
  set_user_id,
  set_user_id_company,
  set_user_id_role,
  set_user_profile_name,
} from "../../redux/actions/user"
import Footer from "../Header_footer/Footer"
import Header from "../Header_footer/Header/index"
import Alert from "../utilities/Modals/alert/Alert"
import "./styles.scss"

const Layout = ({ userId, login_time, ...props }) => {
  // const [timeOflogin, settimeOflogin] = useState(new Date().getTime());

  const [finishSessionText, setfinishSessionText] = useState("")
  const [loggedInFromAnohterDivceText, setloggedInFromAnohterDivceText] =
    useState("")

  const [userExpiredSession, setuserExpiredSession] = useState(false)
  const [userLoggedInFromAnotherAcc, setuserLoggedInFromAnotherAcc] =
    useState(false)
  const [ifModalOpen, setifModalOpen] = useState(false)

  useEffect(() => {
    getContent("auto_logout").then((data) => {
      const arr = data.content
      setfinishSessionText(arr[0].value)
      setloggedInFromAnohterDivceText(arr[1].value)
    })
  }, [])

  const dispatch = useDispatch()
  const history = useHistory()
  async function handleLogOut() {
    dispatch(set_user_id(null))
    dispatch(set_user_id_company(null))
    dispatch(set_user_id_role(null))

    dispatch(set_sv_id(null))
    dispatch(set_sv_name(null))
    dispatch(set_term_1(null))
    dispatch(set_roadmap_name(null))
    dispatch(set_roadmap_id(null))
    dispatch(set_log_time(null))
    dispatch(set_user_img(null))
    dispatch(set_user_profile_name(null))
    history.push("/auth/login")
  }

  async function globalClick() {
    if (userId.user_id !== null) {
      const res = await get_theUserdata()

      if (res.logged_in !== "1") {
        if (userId.user_id !== null) return
        if (ifModalOpen) {
          return
        }
        setifModalOpen(true)
        setuserLoggedInFromAnotherAcc(true)
        return
      }

      if (res.last_login_date !== login_time.lst_login_time) {
        if (ifModalOpen) {
          return
        }
        setifModalOpen(true)
        setuserLoggedInFromAnotherAcc(true)
      }
    }
  }

  async function get_theUserdata() {
    const res = await get_user_data(userId.user_id)
    return {
      id: res.data.id,
      last_login_date: res.data.last_login_date,
      logged_in: res.data.logged_in,
      role: res.data.role,
    }
  }

  function handleUserLoggedinFromAnotherAcc() {
    setuserLoggedInFromAnotherAcc(false)
    handleLogOut()
    setifModalOpen(false)
  }

  function handleUserExpiredSession() {
    setuserExpiredSession(false)
    handleLogOut()
    setifModalOpen(false)
  }

  return (
    // <div onClick={globalClick} className="layout font">
    <div onClick={globalClick} className="layout font">
      <Alert
        title="Vous allez devoir vous reconnecter."
        alertStatus={userLoggedInFromAnotherAcc}
        handleCloseAlert={handleUserLoggedinFromAnotherAcc}
      />

      <Alert
        title="Votre session a expiré"
        alertStatus={userExpiredSession}
        handleCloseAlert={handleUserExpiredSession}
      />
      <Header />
      {props.children}
      <Footer />
    </div>
  )
}
const mapStateToProps = ({ userId, login_time }) => ({ userId, login_time })
export default connect(mapStateToProps)(Layout)
