import React, { useEffect, useState } from "react"
import "./style.scss"
import checkboxChecked from "../../images/checkbox_on_light.png"
import notCheckboxChecked from "../../images/checkbox_notChecked.png"

function CheckBoxTerms({
  idx = 0,
  handleTheCheck = (f) => f,
  selectedBoxIdx,
  item,
}) {
  const [boxCheck, setboxCheck] = useState(false)
  useEffect(() => {
    if (selectedBoxIdx !== null) {
      if (item) {
        if (item.protection === 0) return
        if (selectedBoxIdx >= idx) setboxCheck(true)
      } else {
        if (selectedBoxIdx >= idx) setboxCheck(true)
      }
    }
  }, [])

  return (
    <div className="checkbox-styles">
      {item && item.protection ? (
        <img
          onClick={() => {
            if (item) {
              item.protection !== 0 && handleTheCheck(idx)
            } else handleTheCheck(idx)
          }}
          src={boxCheck ? checkboxChecked : notCheckboxChecked}
          alt=""
        />
      ) : (
        <p className="disabled-checkbox"></p>
      )}
    </div>
  )
}

export default CheckBoxTerms
