import React from "react";
import { Button } from "@material-ui/core";
import "./styles.scss";

const AccountsBtn = (props) => {
  return (
    <div className="account-btn-container">
      <Button
        className={`${props.styleBtn} accounts-btn`}
        type={props.btnType}
        onClick={props.clickBtn}
        {...props}
      >
        {props.children}
      </Button>
    </div>
  );
};

export default AccountsBtn;
