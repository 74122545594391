import { prefixApi, prefixHeaderObj } from "./prefix";

const api_get_glossary = `${prefixApi}glossary/retrieve_by_question/`;
const api_get_all_glossary = `${prefixApi}glossary/retrieve_by_category/`

export const get_specific_glossary = (idQuestion) => {
  return fetch(`${api_get_glossary}${idQuestion}`, {
    method: "Get",
    headers: prefixHeaderObj,
  }).then((res) => res.json());
};

export const get_all_gloassary = (categoryId) => {
    return fetch(`${api_get_all_glossary}${categoryId}`, {
        method: "Get",
        headers: prefixHeaderObj,
      }).then((res) => res.json());
};
