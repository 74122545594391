import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { v4 } from "uuid";
import DeleteIcon from "@material-ui/icons/Delete";

function RoadMapList({
  roadmapText,
  Line,
  allRoadmaps,
  handleSpecificRoadMap,
  infoIcons,
  handleOpenModal,
  loading,
}) {
  return (
    <div className="archive-roadmaps">
      <h3 dangerouslySetInnerHTML={{ __html: roadmapText }}></h3>
      <img className="img-line" src={Line} alt="" />

      <ul>
        {loading ? (
          <div style={{ margin: "auto" }}>
            <CircularProgress
              size={50}
              thickness={4}
              style={{ color: "#ff6500", marginRight: "3px" }}
            />
          </div>
        ) : allRoadmaps ? (
          [...allRoadmaps].map((item, i) => {
            const curDate = moment(item.datetime, "YYYY/MM/DD");
            if (item.name) {
              return ( 
                <div className="rm-content">
                  <div
                    onClick={() => handleSpecificRoadMap(item.id)}
                    key={v4()}
                    className="rm-text"
                  >
                    {/* <div key={i} onClick={() => handleSpecificSV(item.id)}> */}
                    <span className="item_name">{item.name}</span>
                    <div className="item_date_info_delete_container">
                      <span className="item_date">
                        <span>{`${curDate.format("D")}/${curDate.format(
                          "M"
                        )}/${curDate.format("YYYY")}`}</span>

                        <img src={infoIcons} alt="" />
                      </span>
                    </div>
                  </div>
                  <DeleteIcon
                    onClick={() => handleOpenModal(item.id, "rm")}
                    className="delete-btn"
                  />
                </div>
              );
            } 
            
          })
        ) : (
          <h6>Something went Wrong</h6>
        )}
      </ul>
    </div>
  );
}

export default RoadMapList;
