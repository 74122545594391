import { prefixApi, prefixHeaderObj } from "./prefix";

const api_questions_list = `${prefixApi}categories/retrieve_by_supervision/`;

const api_question = `${prefixApi}questions/retrieve_by_category/`;

const api_choices = `${prefixApi}choices/retrieve_by_question/`;

// function createFormBody() {
//     var formBody = [];
//     for (var property in details) {
//         var encodedKey = encodeURIComponent(property);
//         var encodedValue = encodeURIComponent(details[property]);
//         formBody.push(encodedKey + "=" + encodedValue);
//     }
//     formBody = formBody.join("&");
//     return formBody
// }

export async function get_questins_list(id = "1") {
  // const formBody = createFormBody()
  const res = fetch(api_questions_list + `${id}`, {
    method: "GET",
    headers: prefixHeaderObj,
  });
  return res;
}

export async function get_question(id = "1") {
  // const formBody = createFormBody()
  const res = fetch(api_question + `${id}`, {
    method: "GET",
    headers: prefixHeaderObj,
  });
  return res;
}

//
export async function get_choices(id = "1", idSv = 180) {
  // const formBody = createFormBody()
  const res = fetch(api_choices + `${id}/${idSv}`, {
    method: "GET",
    headers: prefixHeaderObj,
  });
  return res;
}

// export async function update_supervisoin(id = "1") {
//   // const formBody = createFormBody()
//   const res = fetch(api_choices + `${id}`, {
//     method: "GET",
//     headers: {
//       Authorization: "Basic " + btoa("api" + ":" + "P4r0|@876!api"),
//       "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
//       Accept: "application/json, text/javascript",
//     },
//   });
//   return res;
// }
