export function checkValid(pass = "") {
    let hasNum = false,
      hasUprCase = false,
      hasLowCase = false;
    for (let i = 0; i < pass.length; i++) {
      let curChar = pass[i].toUpperCase();
      if (isNaN(curChar) === true && curChar === pass[i]) {
        hasUprCase = true;
      }
      curChar = pass[i].toLowerCase();
      if (isNaN(curChar) === true && curChar === pass[i]) {
        hasLowCase = true;
      }
      curChar = parseInt(pass[i], 10);
      if (isNaN(curChar) === false) {
        hasNum = true;
      }
    }
    if (hasLowCase && hasUprCase && hasNum && pass.length >= 12) return true;
    return false;
  }