import React from "react";
import { useHistory } from "react-router";
import IntroInfo from "../../IntroInfo/IntroInfo";

function Page3() {
  const go = useHistory();

  return (
    <IntroInfo apiPage="third_info_page" nextPage={() => go.push("/home/info/4")} />
  );
}

export default Page3;
