import React from "react"
import { connect } from "react-redux"
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight"
import { v4 } from "uuid"
import LabelImportant from "@material-ui/icons/LabelImportant"
function ProgressBar({ number = 6, cur = 5, questionsInfo, showIcon = false }) {
  return (
    <div className="bar-container">
      <span className="line"></span>
      <span className="line dots">
        {/* <span key={i + Math.floor(Math.random() * 50)} className = {(i <= cur) ? "dot colored-dot" : "dot"} > */}
        {[...Array(number)].map((item, i) => (
          <span key={v4()} className={i <= cur ? "dot colored-dot" : "dot"}>
            {/* {showIcon && i === cur && <KeyboardArrowRightIcon />} */}
            <LabelImportant
              style={{
                color: i <= cur ? "#ff6500" : "white",
                fontSize: 40,
                position: "absolute",
                top: -20,
              }}
            />
          </span>
        ))}
      </span>
    </div>
  )
}
const mapStateToProps = ({ questionsInfo }) => ({
  questionsInfo,
})
export default connect(mapStateToProps)(ProgressBar)
