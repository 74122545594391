import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { get_risk_value } from "../../../services/chart";
import { getContent } from "../../../services/Content";
import SpeedChart from "../Chart/speedmeterchart/SpeedChart";
import CustomNextBtn from "../../utilities/Buttons/NextButton";
import "./tut1styles.scss";

function Tutorial1({ userId, svId }) {
  const [title, settitle] = useState("");
  const [text, settext] = useState("");
  const [btnText, setbtnText] = useState("");
  const [riskText, setriskText] = useState("")

  const go = useHistory();
  const [riskValue, setriskValue] = useState(0);

  useEffect(() => {
    getPageContent();
    get_risk_value(userId.user_id_company).then((data) => {
      setriskValue(data.risk);
    });
  }, []);

  const getRiskColor = (precent) => {
    if (precent >= 0 && precent <= 25) return "green";
    else if (precent >= 26 && precent <= 50) return "#d48e04";
    else if (precent >= 51 && precent <= 75) return "#d46604";
    else return "#c90700";
  };

  const getPageContent = () => {
    getContent("tutorial-1").then((data) => {
      const arr = data.content;
      settitle(arr[0].value);
      settext(arr[1].value);
      setbtnText(arr[2].value);
      setriskText(arr[3].value)
    });
  };

  return (
    <div className="tut1__container">
      <h1 className="title" dangerouslySetInnerHTML={{ __html: title }}></h1>
      <SpeedChart percValue={riskValue / 100} size="500px" />
      <h1
        className="risk-precentage"
        style={{ color: getRiskColor(riskValue) }}
      >
        <span dangerouslySetInnerHTML={{__html: riskText}}></span> {riskValue}
      </h1>

      <div className="description__">
        <p dangerouslySetInnerHTML={{ __html: text }}></p>
      </div>
      <div className="btn__holder">
        <CustomNextBtn onClick={() => go.push("/home/questions_list/tut2")}>
          <span dangerouslySetInnerHTML={{ __html: btnText }}></span>
        </CustomNextBtn>
      </div>
    </div>
  );
}

const mapStateToProps = ({ userId, svId }) => ({
  userId,
  svId,
});
export default connect(mapStateToProps)(Tutorial1);
