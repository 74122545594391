import { prefixApi2, prefixHeaderObj } from "./prefix"

const retrieve_company_statistics = `${prefixApi2}reports/retrieve_companies_statistics/`
const retrieve_supervision_answers = `${prefixApi2}reports/retrieve_supervision_answers/`

export function fetchRetrieveCompanyStatistics(userId = "") {
  return fetch(`${retrieve_company_statistics}${userId}`, {
    method: "GET",
    headers: prefixHeaderObj,
  }).then((res) => res.json())
}

export function fetchRetrieveSupervisionAnswers(supervisionId = "") {
  return fetch(`${retrieve_supervision_answers}${supervisionId}`, {
    method: "GET",
    headers: prefixHeaderObj,
  }).then((res) => res.json())
}
