import { CircularProgress } from "@material-ui/core"
import React from "react"
import { v4 } from "uuid"
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight"
import { useHistory } from "react-router-dom"
function RightOfChart({
  chartsRightTitle,
  loadingRightChartTypes,
  chartTypesContent,
  setspcificBarIdx_,
  settheFristChartBarIdx_,
  setcurType_,
  get_both_charts,
  setnumOfBars_,
  curType,
  loadingCreateContinueRmTexts,
  createContinueTitle,
  disableStartRoadMap,
  createRoadmapText,
  roadMapId,
  continueRoadmapText,
  handleNewSuperVison,
  newSupervisionText,
  handleContinueRoadMap,
  disableContiueRoadmap,
  auditTitle,
  url,
  svId,
}) {
  const go = useHistory()

  return (
    <div className="right">
      <div className="right-chart">
        <div className="formation-container-right">
          <div className="formation-container">
            <div className="chart-types">
              <h3
                className="title-type"
                dangerouslySetInnerHTML={{ __html: chartsRightTitle }}
              ></h3>
              <ul className="ul-chart-types">
                {loadingRightChartTypes ? (
                  <div style={{ textAlign: "center" }}>
                    <CircularProgress
                      size={50}
                      thickness={4}
                      style={{ color: "#ff6500", marginRight: "3px" }}
                    />
                  </div>
                ) : (
                  chartTypesContent.map((item, i) => {
                    if (i > 1) return null
                    return (
                      <li className="chart-type-li">
                        <div
                          key={v4()}
                          onClick={() => {
                            setspcificBarIdx_(-1)
                            settheFristChartBarIdx_(-1)
                            setcurType_(i)
                            if (i >= 2) {
                              get_both_charts(svId.id)
                              setnumOfBars_(15)
                            } else {
                              setnumOfBars_(11)
                            }
                          }}
                          className={`chart-type-container ${
                            curType === i ? "chosen" : ""
                          } `}
                        >
                          <div className="icon">
                            {curType === i && <KeyboardArrowRightIcon />}
                          </div>

                          {/* <p className="type-text">{item.name}</p> */}
                          {i > 0 && (
                            <p
                              className="type-text"
                              dangerouslySetInnerHTML={{ __html: item.value }}
                            ></p>
                          )}
                          {i === 0 && <p className="type-text">Votre risque</p>}
                        </div>
                        <div className="line-horizotal"></div>
                      </li>
                    )
                  })
                )}
              </ul>
            </div>

            {loadingCreateContinueRmTexts ? (
              <div style={{ textAlign: "center", marginTop: "100px" }}>
                <CircularProgress
                  size={50}
                  thickness={4}
                  style={{ color: "#ff6500", margin: "auto" }}
                />
              </div>
            ) : (
              <div className="btns-container">
                <div className="create-continue-reminder-container">
                  <h4
                    dangerouslySetInnerHTML={{
                      __html: createContinueTitle,
                    }}
                  ></h4>
                  <p
                    className="p-btns enable"
                    onClick={() => {
                      go.push("/home/questions_list/chart/createroadmap")
                    }}
                  >
                    Accès
                  </p>
                </div>
                <div>
                  <h4 dangerouslySetInnerHTML={{ __html: auditTitle }}></h4>
                  <p
                    className="enable"
                    onClick={handleNewSuperVison}
                    dangerouslySetInnerHTML={{ __html: newSupervisionText }}
                  ></p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default RightOfChart
