import React from "react"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import Slide from "@material-ui/core/Slide"
import vLine from "../../../../images/v-line.png"
import "./styles.scss"
import { v4 } from "uuid"
import { CircularProgress } from "@material-ui/core"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

function ProcessModal({
  data = null,
  openProcessModel = false,
  handleCloseProcessInfoModal = (f) => f,
  title = "testing",
}) {
  return (
    <Dialog
      open={openProcessModel}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseProcessInfoModal}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className="process-modal-container"
    >
      <span onClick={handleCloseProcessInfoModal} className="close-icon"></span>
      <DialogTitle id="alert-dialog-slide-title">
        <span dangerouslySetInnerHTML={{ __html: title }}></span>
      </DialogTitle>
      <div className="modal-wrapper">
        {data === null && (
          <div style={{ textAlign: "center", marginTop: "130px" }}>
            <CircularProgress
              size={100}
              thickness={4}
              style={{ color: "#ff6500", margin: "auto" }}
            />
          </div>
        )}
        {/* {data.length ? ( */}
        {Array.isArray(data) && data.length
          ? data.map((item) => (
              <div key={v4()} className="info-modal-content">
                <DialogTitle id="alert-dialog-slide-title">
                  {item.question}
                </DialogTitle>
                <div className="img-text-content">
                  <img src={vLine} alt="" className="left-line" />

                  <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                      <span
                        dangerouslySetInnerHTML={{ __html: item.text_full }}
                      ></span>
                    </DialogContentText>
                  </DialogContent>
                  <img src={vLine} alt="" className="right-line" />
                </div>
              </div>
            ))
          : null}

        {Array.isArray(data) && !data.length ? (
          <h3 style={{ color: "white", margin: "auto" }}>
            Aucun paramètre ne correspond à ce niveau de risque
          </h3>
        ) : null}
      </div>
    </Dialog>
  )
}

export default ProcessModal
