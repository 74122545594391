import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts"
import { v4 } from "uuid"
import { get_chart } from "../../../../services/chart"

function BarData({ svId }) {
  const [chartData, setchartData] = useState([])
  const [checkboxState, setcheckboxState] = useState(
    [...Array(10)].map(() => false)
  )
  useEffect(() => {
    ;(async () => {
      const data = await getDataOfchart()
      setchartData(() => {
        let arr = []
        data.map((item, i) => {
          arr[i] = { name: item.no_of_answers, points: item.points }
        })
        return arr
      })
    })()
  }, [])

  async function getDataOfchart() {
    const respo = await get_chart(svId.id)
    const res = await respo.json()
    return res.data
  }
  function changeStateOfbox(idx) {
    setcheckboxState((arr) => {
      arr[idx] = !arr[idx]
      return arr
    })
  }
  return (
    <div style={{ width: "100%", height: "80vh" }}>
      <ResponsiveContainer width="80%" height="90%" aspect={3}>
        <BarChart width={500} height={300} data={chartData}>
          <Bar barSize={15} dataKey="points" fill="#8884d8" />
          <XAxis />
          <YAxis />
          <Tooltip />
        </BarChart>
      </ResponsiveContainer>
      {chartData.map((item, i) => (
        <input
          key={v4()}
          checked={checkboxState[i]}
          style={{ color: "orange", marginLeft: 15 }}
          type="checkbox"
          onChange={() => changeStateOfbox(i)}
        />
      ))}
    </div>
  )
}

const mapStatetoProps = ({ svId }) => ({ svId })

export default connect(mapStatetoProps)(BarData)
