import React from 'react'
import { useHistory } from 'react-router'
import IntroInfo from '../../IntroInfo/IntroInfo'

function Page4() {
    const  go = useHistory()

    return (
        <IntroInfo apiPage="fourth_info_page" nextPage = {() => go.push('/home/info/5')} />

    )
}

export default Page4
