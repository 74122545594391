import React from "react"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import Slide from "@material-ui/core/Slide"
import vLine from "../../../../images/v-line.png"
import "./style.scss"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

function ModalModified({
  description = "",
  name = "",
  openModel = false,
  handleCloseInfoModal = (f) => f,
}) {
  return (
    <Dialog
      open={openModel}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseInfoModal}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className="modified-info-modal-container"
    >
      <div className="info-modal-content">
        <span onClick={handleCloseInfoModal} className="close-icon"></span>

        <DialogTitle id="alert-dialog-slide-title">{name}</DialogTitle>
        <div className="img-text-content">
          <img src={vLine} alt="" className="left-line" />

          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <span dangerouslySetInnerHTML={{ __html: description }}></span>
            </DialogContentText>
          </DialogContent>
          <img src={vLine} alt="" className="right-line" />
        </div>
      </div>
    </Dialog>
  )
}

export default ModalModified
