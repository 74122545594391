export const optionsOfBarChart = (curType) => {
  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: (items) => {
            let danger = "";
            let protection = "";
            if (items.length === 2) {
              danger = `Menace : ${items[1].raw}`;
              protection = `Protection : ${items[0].raw}`;
            } else {
              if (items[0].dataset.label === "Protection") {
                return (protection = `Protection : ${items[0].raw}`);
              } else if (items[0].dataset.label === "dangerous") {
                return (danger = `Menace : ${items[0].raw}`);
              } else {
                return (
                  `Niveau de protection : ${items[0].dataIndex}` +
                  "\n" +
                  `Nombre de pratiques : ${items[0].raw}`
                );
              }
            }
            return (
              `${items[0].dataset.label} : ${items[0].raw}` + "\n" + danger
            );
          },
          label: () => null,
        },
      },
    },
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          color: "white",
          font: {
            size: 15,
          },
          display: curType < 2 ? true : false,
        },
      },
      y: {
        ticks: {
          color: "white",
          font: {
            size: 15,
          },
        },
      },
    },
  };
  return options;
};

export function getRiskColor(precent) {
  if (precent >= 0 && precent <= 25) return "green";
  else if (precent >= 26 && precent <= 50) return "#d48e04";
  else if (precent >= 51 && precent <= 75) return "#d46604";
  else return "#c90700";
}
export function handleTheTypeOfModels(curType) {
  switch (curType) {
    case 0:
      return "speedmeter_chart_info";
    case 1:
      return "chart_process_info";
    case 2:
      return "chart_protection_info";
    case 3:
      return "chart_threats_info";
    case 4:
      return "chart_threats_protection_info";
    default:
      return "";
  }
}
