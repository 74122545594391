import "./Login/styles.scss";
import WarningIcon from "@material-ui/icons/Warning";

// Check if is a valid email
export const checkEmailValidation = (email, setIsValidatedEmail) => {
  let valid = /\S+@\S+\.\S+/.test(email);
  if (valid) {
    setIsValidatedEmail(true);
  } else {
    setIsValidatedEmail(false);
  }
};

// show error message
export const showErrorMessage = (errorType , errorText = "Attention!") => {
  return (
    <div className="error">
      <span>
        <WarningIcon className="warning-icon" />
        {errorText}
      </span>
      <span className="error-text" dangerouslySetInnerHTML={{__html: errorType}}></span>
    </div>
  );
};
