import React, { useEffect, useState } from "react"
import { loginApi } from "../../../services/auth"
import { CircularProgress } from "@material-ui/core"
import { Link } from "react-router-dom"
import "./styles.scss"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { withStyles } from "@material-ui/core/styles"
import Checkbox from "@material-ui/core/Checkbox"
import { useForm } from "react-hook-form"
import { IconButton } from "@material-ui/core"
import hidePass from "../../../images/imgpsh_fullsize_anim.png"
import showPass from "../../../images/imgpsh_fullsize_anim-45.png"
import { useHistory, withRouter } from "react-router"
import { connect } from "react-redux"
import {
  set_user_id,
  set_user_id_audit,
  set_user_id_company,
  set_user_id_role,
  set_user_profile_name,
  set_user_tuts,
} from "../../../redux/actions/user"
import { showErrorMessage } from "../FormsService"
import { set_term_1 } from "../../../redux/actions/term"
import AccountsBtn from "../../utilities/Buttons/AccountsBtn"
import { get_second_connection } from "../../../services/secondconnection"
import { get_status_sv } from "../../../services/superVision"
import {
  set_questions_finished_once,
  set_sv_id,
  set_sv_name,
} from "../../../redux/actions/superVision"
import { get_question } from "../../../services/questions"
import {
  set_roadmap_id,
  set_roadmap_name,
} from "../../../redux/actions/roadmap"
import { getContent } from "../../../services/Content"
import logoImg from "../../../images/icon-1.png"
import {
  set_last_login_time,
  set_log_time,
} from "../../../redux/actions/timeAction"
import { set_user_img } from "../../../redux/actions/profileAction"
import {
  prefix_api_set_img,
  prefix_api_set_img_content,
} from "../../../services/prefix"

const Login = ({ dispatch, userId }) => {
  useEffect(() => {
    if (!userId.user_id) {
      setDispatchesNull()
    }
  }, [])

  const [checkTerms, setCheckTerms] = useState(true)
  const [loading, setLoading] = useState(false)
  const [textLoading, setTextLoading] = useState(false)
  const [typepassword, settypepassword] = useState("password")
  const [showFormError, setShowFormError] = useState(false)
  const [itIsNotAnActiveUser, setItIsNotAnActiveUser] = useState(false)
  const [userIsAlreadyLoggedIn] = useState("")
  const [title, settitle] = useState("")
  const [forgetPassText, setforgetPassText] = useState("")
  const [loadingText, setloadingText] = useState("")
  const [loginText, setloginText] = useState("")
  const [emailError, setemailError] = useState("")
  const [passwordError, setpasswordError] = useState("")
  const [smthWentWrongError, setsmthWentWrongError] = useState("")
  const [notActiveUserError, setnotActiveUserError] = useState("")

  const history = useHistory()
  useEffect(() => {
    setTextLoading(true)
    if (userId.user_id !== null) {
      history.push("/home/info/1")
    }
    getContent("login_page").then((data) => {
      const arr = data.content
      settitle(arr[0].value)
      setforgetPassText(arr[1].value)
      setloadingText(arr[2].value)
      setloginText(arr[3].value)
      setemailError(arr[4].value)
      setpasswordError(arr[5].value)
      setsmthWentWrongError(arr[6].value)
      setnotActiveUserError(arr[7].value)
      setTextLoading(false)
    })
  }, [])
  const GreenCheckbox = withStyles({
    root: {
      color: "#bcbcbc",
      "&$checked": {
        color: "#bcbcbc",
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />)

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm()

  const handleChange = (event) => {
    setCheckTerms(event.target.checked)
  }

  function setTheDataToRedux(data) {
    dispatch(set_user_id(data.user.id))
    dispatch(set_user_id_company(data.user.id_company))
    dispatch(set_user_tuts(data.user.tutorial))
    dispatch(set_questions_finished_once(data.user.questions_finished_once))
    dispatch(set_term_1(data.user.terms_first))
    dispatch(set_log_time(new Date().getTime()))
    dispatch(set_last_login_time(data.user.last_login_date))
    dispatch(set_user_id_audit(data.user.id_audit))
    dispatch(set_user_id_role(data.user.role))
  }

  const submitForm = (event) => {
    const { email, password } = event
    setLoading(true)
    loginApi(email, password)
      .then(async (data) => {
        if (data.user !== null) {
          if (data.user.active === "0") {
            setLoading(false)
            return setItIsNotAnActiveUser(true)
          }
          setTheDataToRedux(data)
          await setTheUserimg(data.user.profile_image)
          await setTheUserName(`${data.user.first_name} ${data.user.last_name}`)
          setLoading(false)
          return data.user
        } else {
          setShowFormError(true)
          setLoading(false)
        }
      })
      // handle if there is second connection
      .then(async (user) => {
        const secData = await handleSecondConnection(user.id)
        handleTheFromSecondConnection(secData, user)
      })
      .then(() => {
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }
  function setDispatchesNull() {
    dispatch(set_user_id(null))
    dispatch(set_user_id_company(null))
    dispatch(set_user_id_role(null))
    dispatch(set_sv_id(null))
    dispatch(set_sv_name(null))
    dispatch(set_roadmap_name(null))
    dispatch(set_roadmap_id(null))
    dispatch(set_user_profile_name(null))
    dispatch(set_user_img(null))
    dispatch(set_term_1(null))
  }
  async function setTheUserimg(img) {
    if (img === null || img === "") {
      const res = await getContent("default_user_image")
      dispatch(set_user_img(prefix_api_set_img_content + res.content[0].file))
    } else {
      dispatch(set_user_img(prefix_api_set_img + img))
    }
  }
  async function setTheUserName(name) {
    dispatch(set_user_profile_name(name))
  }
  const handleSecondConnection = async (id) => {
    const response = await get_second_connection(id)
    const res = await response.json()
    return res
  }

  const handleShowPass = () => {
    if (typepassword === "password") settypepassword("text")
    if (typepassword !== "password") settypepassword("password")
  }

  const handleFinishSupervison = (data) => {
    dispatch(set_sv_name(data.name))
    console.log("finish supervision", data)
    if (data.name === null || data.name === "null") {
      history.push({
        pathname: `/home/questions_list/finish`,
        state: {
          status: "finished",
        },
      })
    } else {
      history.push("/home/questions_list/chart")
    }
  }

  async function checkIfStatusFinished(svId) {
    const response = await get_status_sv(svId)
    const res = await response.json()
    return res
  }
  const handleTheFromSecondConnection = (data, user) => {
    const CONSULTANT_ROLE = "2"

    if (user.company_status !== 1) {
      // throw some error
    }
    console.log("second connection", data, user)
    if (user.role === CONSULTANT_ROLE) {
      history.push("/reports")
    } else if (user.role !== CONSULTANT_ROLE) {
      if (data.supervision === "not_found" || !user.terms_first) {
        history.push("/home/info/1")
      } else {
        dispatch(set_sv_id(data.supervision.id))
        if (data.roadmaps && data.roadmaps !== "not_found") {
          // dispatch(set_roadmap_name(data.roadmaps.name));
          // dispatch(set_roadmap_id(data.roadmaps.id));
          history.push("/home/questions_list/chart")
        } else {
          if (data.supervision.status === "finished") {
            handleFinishSupervison(data.supervision)
          } else if (data.supervision.status === "not_started") {
            history.push("/home/questions_list")
          } else if (data.supervision.status === "in_progress") {
            checkIfStatusFinished(data.supervision.id).then((statusRes) => {
              if (statusRes.status === "finished") {
                handleFinishSupervison(data.supervision)
              } else {
                if (statusRes.category_in_progress !== 1) {
                  return history.push("/home/questions_list")
                }
                const lst_cat_idx = data.supervision.last_category_index
                const lst_ques_idx = data.supervision.last_question_index
                handleGoToQuesion(lst_cat_idx).then((questions) => {
                  history.push({
                    pathname: "/home/questions_list/questions",
                    state: {
                      category: lst_cat_idx,
                      categorys_Question: questions,
                      lst_q_idx: lst_ques_idx,
                    },
                  })
                })
              }
            })
          }
        }
      }
    }
  }

  const handleGoToQuesion = async (lst_c_idx) => {
    const questions = await setTheCategoryQuestions(lst_c_idx)
    return questions
  }
  const setTheCategoryQuestions = async (lst_cat_idx) => {
    const response1 = await get_question(lst_cat_idx)
    const res1 = await response1.json()
    const questions_cat = res1.data
    return questions_cat
  }

  if (textLoading) {
    return (
      <div style={{ textAlign: "center" }}>
        <CircularProgress
          size={50}
          thickness={4}
          style={{ color: "#ff6500", marginRight: "3px" }}
        />
      </div>
    )
  }
  return (
    <div className="login-container">
      <form
        onSubmit={handleSubmit(submitForm)}
        className="login-section input-group"
      >
        <img src={logoImg} className="logo-img" alt="" />
        <p
          className="login-title"
          dangerouslySetInnerHTML={{ __html: title }}
        ></p>
        <div className="input-container">
          <input
            className={`form-control email ${errors.email && "input-error"}`}
            type="text"
            {...register("email", { required: true })}
            placeholder="Adresse mail"
          />

          <div className="password-input-section">
            <input
              className={`form-control ${errors.password && "input-error"}`}
              type={typepassword}
              {...register("password", { required: true })}
              placeholder="Mot de passe"
            />
            <div className="show-hide-pass">
              {/* icon show pass */}
              <IconButton onClick={handleShowPass}>
                {typepassword === "password" ? (
                  <img src={showPass} alt="" />
                ) : (
                  <img src={hidePass} alt="" />
                )}
              </IconButton>
            </div>
          </div>
          <AccountsBtn
            btnType="submit"
            styleBtn={`submit-btn ${loading && "loading-btn"}`}
          >
            {loading ? (
              <span dangerouslySetInnerHTML={{ __html: loadingText }}></span>
            ) : (
              <span dangerouslySetInnerHTML={{ __html: loginText }}></span>
            )}
          </AccountsBtn>

          <div className="remember-forget-pass">
            <FormControlLabel
              control={
                <GreenCheckbox
                  checked={checkTerms}
                  onChange={handleChange}
                  name="checkedG"
                />
              }
              label="Rester connecté"
              className="terms-checkbox"
            />
            <Link
              to="/auth/forgot_pass"
              dangerouslySetInnerHTML={{ __html: forgetPassText }}
            ></Link>
          </div>
        </div>

        <div className="error-section">
          {errors.email && showErrorMessage(emailError)}
          {errors.password && showErrorMessage(passwordError)}
          {showFormError && showErrorMessage(smthWentWrongError)}
          {itIsNotAnActiveUser && showErrorMessage(notActiveUserError)}
          {userIsAlreadyLoggedIn &&
            showErrorMessage(
              "L'utilisateur est déjà connecté depuis un autre appareil"
            )}

          {/* {!isvalidatedEmail && showErrorMessage("This must be a valid email")} */}
        </div>
      </form>
    </div>
  )
}
const mapStateToProps = ({ userId }) => ({
  userId,
})
export default withRouter(connect(mapStateToProps)(Login))
