import { CircularProgress } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useHistory } from "react-router"
import { get_protect_deng } from "../../../services/chart"
import { getContent } from "../../../services/Content"
import BackBtn from "../../utilities/Buttons/BackBtn"
import CustomNextBtn from "../../utilities/Buttons/NextButton"
import BarChartUtl from "./BarChartUtl"
import "./tut4.scss"

function Tut4({ svId }) {
  const go = useHistory()
  const [chartData, setchartData] = useState([])
  const [loading, setloading] = useState(false)
  const [chartNames, setchartNames] = useState([])
  const [title, settitle] = useState("")
  const [firstText, setfirstText] = useState("")
  const [secondText, setsecondText] = useState("")
  const [nextBtnText, setnextBtnText] = useState("")
  const [prevBtnText, setprevBtnText] = useState("")

  useEffect(() => {
    ;(async () => {
      setloading(true)
      getPageContent()
      await getThedata()
    })()
  }, [])

  const getPageContent = () => {
    getContent("tutorial-4").then((data) => {
      const arr = data.content
      settitle(arr[0].value)
      setfirstText(arr[1].value)
      setsecondText(arr[2].value)
      setnextBtnText(arr[3].value)
      setprevBtnText(arr[4].value)
    })
  }

  async function getThedata() {
    const res = await get_protect_deng(svId.id)
    const datares = await res.json()
    setchartNames(() => datares.data.map((item) => item.name))
    setchartData(() =>
      [...datares.data].map((item, idx) => ({
        name: "points",
        dangerousity: parseInt(item.threat_dangerousity_percentage, 10),
        text: idx + 1,
      }))
    )
    setloading(false)
  }
  function getTheColors(i) {
    return "red"
  }

  if (loading) {
    return (
      <CircularProgress
        size={100}
        thickness={4}
        style={{ color: "#ff6500", margin: "auto" }}
      />
    )
  }
  return (
    <div className="tut4__container">
      <p className="text" dangerouslySetInnerHTML={{ __html: firstText }}></p>
      <h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
      <div className="barchart__parent">
        <BarChartUtl
          width="75%"
          keyOfBar1={"dangerousity"}
          chartData={chartData}
          getTheColors={getTheColors}
          hideX={true}
          // height="80%"
        />
        <div className="chartnames__">
          {chartNames.map((item) => (
            <p key={item}>{item}</p>
          ))}
        </div>
      </div>
      <p className="text" dangerouslySetInnerHTML={{ __html: secondText }}></p>
      <div className="btn__holder">
        <BackBtn onClick={() => go.push("/home/questions_list/tut3")}>
          <span dangerouslySetInnerHTML={{ __html: prevBtnText }}></span>
        </BackBtn>
        <CustomNextBtn onClick={() => go.push("/home/questions_list/tut5")}>
          <span dangerouslySetInnerHTML={{ __html: nextBtnText }}></span>
        </CustomNextBtn>
      </div>
    </div>
  )
}
const mapStateToProps = ({ svId }) => ({ svId })
export default connect(mapStateToProps)(Tut4)
