import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import UserAvatar from "../utilities/userAavtar/UserAvatar"
import { Button } from "@material-ui/core"
import "./profile.scss"
import test from "../../images/user.jpeg"
import { uploadTheimg } from "../../services/profile"
import { set_user_img } from "../../redux/actions/profileAction"
import { get_user_data, update_user_profile } from "../../services/auth"
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace"
import { useHistory } from "react-router-dom"
import FinishProfileInfo from "../utilities/Modals/finishProfileInfo/FinishProfileInfo"
import { getContent } from "../../services/Content"
import CustomNextBtn from "../utilities/Buttons/NextButton"
import {
  prefix_api_set_img,
  prefix_api_set_img_content,
} from "../../services/prefix"
const ProfileImage = ({ userId, dispatch, profile, ...rest }) => {
  const [file, setfile] = useState(null)
  const [beforeUpload, setbeforeUpload] = useState(`${profile.src}`)
  const [alertStatus, setalertStatus] = useState(false)
  const inputRef = useRef(null)

  const history = useHistory()

  useEffect(() => {
    setbeforeUpload(`${profile.src}`)
  }, [profile.src])

  useEffect(() => {
    get_user_data(userId.user_id).then((res) => {
      let img = res.data.profile_image
      setTheUserimg(img)
    })
  }, [profile.src])

  async function setTheUserimg(img) {
    if (img === null || img === "") {
      const res = await getContent("default_user_image")
      dispatch(set_user_img(prefix_api_set_img_content + res.content[0].file))
    } else {
      dispatch(set_user_img(prefix_api_set_img + img))
    }
  }

  function chossefile() {
    inputRef.current.click()
  }

  async function getThefile(e) {
    try {
      e.stopPropagation()
      e.preventDefault()
      const objectURL = await readBlob(e.target.files[0])
      setfile(e.target.files[0])
      const newImg = await get_user_data(userId.user_id)
    } catch (errImg) {
      // console.log(errImg);
    }
  }

  async function handleValidate() {
    const res = await uploadTheimg(file, userId.user_id)
    if (file === null) {
      return
    }
    const objectURL = URL.createObjectURL(file)
    dispatch(set_user_img(objectURL))
    setalertStatus(true)
  }
  async function readBlob(blob) {
    const objectURL = URL.createObjectURL(blob)
    setbeforeUpload(objectURL)
    return objectURL
  }
  async function handleRemove() {
    const res = await update_user_profile(userId.user_id)
    dispatch(set_user_img(null))
    inputRef.current.value = null
  }
  function goBackToPrevScreen() {
    history.push("/home/info/1")
  }

  function handleCloseAlert() {
    setalertStatus(false)
  }
  return (
    <div className="profile-conatainer">
      <FinishProfileInfo
        handleCloseFinishModal={handleCloseAlert}
        finishModalStatus={alertStatus}
        shouldCloseOnClick={true}
        text="L'image a été modifiée"
      />
      <div className="left">
        <h3 className="title">Changement de votre photo</h3>
        <UserAvatar iconClick={handleRemove} src={beforeUpload} />
      </div>
      <div className="right">
        {/* <span>Sélectionnez l'image de profil</span> */}
        <div style={{ display: "flex", flexDirection: "column" }}>
          <button
            onClick={chossefile}
            className="btn btn-lg btn-rounded btn-warning browse"
          >
            Rechercher
          </button>
          <span className="img-size-text">
            Maximum : 1 MB, 1000 pixels x 1000 pixels
          </span>
        </div>
        {/* <button
          onClick={handleValidate}
          className="validate-btn btn btn-lg btn-rounded btn-warning"
        >
          VALIDER
        </button> */}
        <CustomNextBtn
          onClick={handleValidate}
          className="validate-btn btn btn-lg btn-rounded btn-warning"
        >
          VALIDER
        </CustomNextBtn>
      </div>
      <input
        type="file"
        id="file"
        ref={inputRef}
        onChange={getThefile}
        style={{ display: "none" }}
      />
    </div>
  )
}

const mapStateToProps = ({ userId, profile }) => ({
  userId,
  profile,
})

export default connect(mapStateToProps)(ProfileImage)
