import { CircularProgress } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import "./proccessandaction.scss"
import {
  getchoises_by_rmid,
  submit_ans_by_rm,
  update_lst_question_idx,
} from "../../../../services/roadmap"
import { useHistory } from "react-router"
import CustomNextBtn from "../../../utilities/Buttons/NextButton"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import { getContent } from "../../../../services/Content"
import InfoModel from "../../../utilities/Modals/infoModel"

// Images
import infoIcon from "../../../../images/info-selected.png"
import pdfFile from "../../../../images/pdf_file.png"
import selectedRadioImg from "../../../../images/Group 2081.png"
import notSelectedRadioImg from "../../../../images/radio_off.png"
import { set_log_time } from "../../../../redux/actions/timeAction"
import { v4 } from "uuid"
import { fileApi } from "../../../../services/prefix"
import ExitBtn from "../../../utilities/Buttons/ExitBtn"
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "60%",
    margin: "32px auto",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}))

const ProccessAndAction = ({ checkTheTimeGab, handleLogOut, dispatch }) => {
  const classes = useStyles()
  const { push, location } = useHistory()
  const { curQuestion, newRoadMapId, rmStatus } = setTheStateOfRoute()
  const [validatedText, setvalidatedText] = useState("")
  const [goBackOneStep] = useState({
    pathname: "/home/questions_list/chart2/RoadMapQuestions",
    state: {
      newRoadMapId,
    },
  })

  useEffect(() => {
    if (!curQuestion || !newRoadMapId) {
      push("/home/questions_list/chart/createroadmap")
      return
    }

    getChoiscesbyIdAndRodmap(curQuestion.id)
    handleTheautologout()
    setLoading(true)
    getAllPageContent()
  }, [])

  function getAllPageContent() {
    getContent("process_action_page").then((data) => {
      const arr = data.content
      setprocessTitle(arr[0].value)
      setactionTitle(arr[1].value)
      setBtnText(arr[2].value)
      setprevSelectedText(arr[3].value)
      setpossibleChoiceText(arr[4].value)
      setbtnInfoText(arr[5].value)
      setvalidatedText(arr[6].value)
    })
  }

  function handleTheautologout() {
    if (checkTheTimeGab()) {
      handleLogOut()
    } else {
      dispatch(set_log_time(new Date().getTime()))
    }
  }

  function setTheStateOfRoute() {
    if (location.state) {
      const { newRoadMapId, question, rmStatus } = location.state
      return {
        curQuestion: question,
        newRoadMapId,
        rmStatus,
      }
    }
    return {
      curQuestion: null,
      newRoadMapId: null,
      rmStatus: null,
    }
  }

  const [curChoices, setcurChoices] = useState([])
  const [curAnsNum, setcurAnsNum] = useState(-1)
  const [idxOfSelected, setidxOfSelected] = useState(0)
  const [processTitle, setprocessTitle] = useState("")
  const [actionTitle, setactionTitle] = useState("")
  const [btnText, setBtnText] = useState("")
  const [prevSelectedText, setprevSelectedText] = useState("")
  const [possibleChoiceText, setpossibleChoiceText] = useState("")
  const [btnInfoText, setbtnInfoText] = useState("")
  const [infoModal, setinfoModal] = useState(false)
  const [loading, setLoading] = useState(false)

  async function getChoiscesbyIdAndRodmap(id = "") {
    const response = await getchoises_by_rmid(newRoadMapId, id).then((res) => {
      setcurChoices([])
      return res
    })
    const res = await response.json()
    setcurChoices(
      [...res.data].map((item, i) => {
        if (item.hasOwnProperty("selected")) {
          setidxOfSelected(i)
        }
        if (item.roadmap_selected && !item.hasOwnProperty("selected")) {
          setcurAnsNum(i)
        }
        return item
      })
    )
    setLoading(false)
  }

  async function goToNextQuestion() {
    if (curQuestion.status === "validated") {
      return push({
        pathname: "/home/questions_list/chart2/RoadMapQuestions",
        state: {
          newRoadMapId,
        },
      })
    }
    await handleSubmitTheAnswer()
    push({
      pathname: "/home/questions_list/chart2/RoadMapQuestions",
      state: {
        newRoadMapId,
      },
    })
  }

  async function handleSubmitTheAnswer() {
    const ansIdx = curAnsNum === -1 ? idxOfSelected : curAnsNum
    const idQ = curQuestion.id
    const idC = curChoices[ansIdx].id
    const response = await submit_ans_by_rm(idC, idQ, newRoadMapId)
    const res = await response.json()
  }

  function determineTheColor(idx) {
    if (idx > idxOfSelected) return "white"
    if (idx === idxOfSelected) return "white"
    return "gray"
  }

  function handleSelect(i) {
    if (curQuestion.status === "validated") {
      return
    }
    setcurAnsNum(i)
  }

  function handleCloseInfoModal() {
    setinfoModal(false)
  }

  return (
    <>
      <ExitBtn routePage={goBackOneStep} />
      <div className="process-conatiner">
        {loading ? (
          <div style={{ textAlign: "center", marginTop: "100px" }}>
            <CircularProgress
              size={80}
              thickness={4}
              style={{ color: "#ff6500", margin: "auto" }}
            />
          </div>
        ) : (
          <div className="procces-actions">
            <InfoModel
              infoModal={infoModal}
              type="app_info_proccess_action"
              handleCloseInfoModal={handleCloseInfoModal}
            />

            <div className={classes.root}>
              <div className="sub-titles-container">
                <h3
                  className="type"
                  dangerouslySetInnerHTML={{ __html: processTitle }}
                ></h3>
                <h3
                  className="type"
                  dangerouslySetInnerHTML={{ __html: actionTitle }}
                ></h3>
              </div>

              <Grid container spacing={3} className="chocies-container">
                {curChoices.map((item, i) => (
                  <>
                    <Grid item xs={6} key={v4()}>
                      <div
                        onClick={() => {
                          if (i > idxOfSelected) {
                            handleSelect(i)
                          }
                        }}
                        className="choise-conatiner"
                        key={item.id}
                      >
                        <div className="choice-text-icon">
                          <div className="left-of-choice">
                            {i === idxOfSelected && (
                              <div className="left-subpart">
                                {rmStatus === "validated" ? (
                                  <p
                                    className="pcolor validated_text"
                                    style={{ fontSize: "18px" }}
                                    dangerouslySetInnerHTML={{
                                      __html: validatedText,
                                    }}
                                  ></p>
                                ) : (
                                  <p
                                    className={
                                      i === idxOfSelected
                                        ? "pcolor"
                                        : "pnotcolor"
                                    }
                                    dangerouslySetInnerHTML={{
                                      __html: prevSelectedText,
                                    }}
                                  ></p>
                                )}
                              </div>
                            )}
                            {rmStatus !== "validated" &&
                              i === idxOfSelected + 1 && (
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: possibleChoiceText,
                                  }}
                                ></p>
                              )}
                          </div>

                          <div className="icon-text">
                            {i === curAnsNum && (
                              <img src={selectedRadioImg} alt="" />
                            )}

                            {i > idxOfSelected && i !== curAnsNum && (
                              <img src={notSelectedRadioImg} alt="" />
                            )}
                            {i === idxOfSelected && (
                              <div className="prev-selected-choice"></div>
                            )}

                            <p
                              className="choise-text"
                              style={{
                                color:
                                  i === idxOfSelected
                                    ? "#034795"
                                    : determineTheColor(i),
                              }}
                              dangerouslySetInnerHTML={{ __html: item.text }}
                            ></p>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={6} key={item.id + "" + v4().toString()}>
                      <div className="info-of-choice">
                        <p
                          style={{
                            color:
                              i === idxOfSelected
                                ? "#034795"
                                : determineTheColor(i),
                          }}
                          dangerouslySetInnerHTML={{
                            __html: item.text_roadmap,
                          }}
                        ></p>

                        {/* the file section */}
                        {item.text_roadmap && curChoices[i].resources.length ? (
                          <a
                            href={`${fileApi}${curChoices[i].resources[0].file}`}
                          >
                            <img src={pdfFile} alt="" />
                          </a>
                        ) : null}
                      </div>
                    </Grid>
                  </>
                ))}
              </Grid>
            </div>
            <div className="btn-conatiner">
              <CustomNextBtn
                className="info-btn"
                onClick={() => setinfoModal(true)}
              >
                <span dangerouslySetInnerHTML={{ __html: btnInfoText }}></span>
                <img className="info-select-icon" src={infoIcon} alt="" />
              </CustomNextBtn>

              <CustomNextBtn onClick={goToNextQuestion}>
                {curQuestion.status === "validated" ? (
                  <span dangerouslySetInnerHTML={{ __html: btnText }}></span>
                ) : (
                  <span dangerouslySetInnerHTML={{ __html: btnText }}></span>
                )}
              </CustomNextBtn>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

const mapStateToProps = ({ roadMapId, svId, userId }) => ({
  roadMapId,
  userId,
  svId,
})

export default connect(mapStateToProps)(ProccessAndAction)
