import React from "react"
import { connect } from "react-redux"
import { makeStyles } from "@material-ui/core/styles"
import Avatar from "@material-ui/core/Avatar"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}))
export const UserAvatr = ({ type = "small", srcImg = "" }) => {
  const classes = useStyles()

  return <Avatar alt="Remy Sharp" src={srcImg} className={classes.small} />
}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps)(UserAvatr)
