import React from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogTitle from "@material-ui/core/DialogTitle"
import Slide from "@material-ui/core/Slide"
import "./style.scss"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function NewAlert({
  text,
  handleCloseNewSV = (f) => f,
  handleOkNewSV = (f) => f,
  type,
  newSVStatus = false,
  removeAlldata,
}) {
  return (
    <Dialog
      open={newSVStatus}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseNewSV}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className="alert-modal"
    >
      <span onClick={handleCloseNewSV} className="close-icon">
        x
      </span>
      <DialogTitle id="alert-dialog-slide-title">
        <span dangerouslySetInnerHTML={{ __html: text }}></span>
      </DialogTitle>

      <DialogActions>
        <Button onClick={handleOkNewSV} className="ok-btn">
          OK
        </Button>
        {removeAlldata !== true && (
          <Button onClick={handleCloseNewSV} className="ok-btn">
            Annuler
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
