import React, { useState } from "react"
import { useForm } from "react-hook-form"
import "./password.scss"
import { showErrorMessage } from "../Account/FormsService"
import { email_reset_password } from "../../services/auth"
import FinishProfileInfo from "../utilities/Modals/finishProfileInfo/FinishProfileInfo"
import Alert from "../utilities/Modals/alert/Alert"
import CustomNextBtn from "../utilities/Buttons/NextButton"

const ChangePassword = () => {
  // const [showEmailWasSent, setshowEmailWasSent] = useState(false);
  const [alertStatus, setalertStatus] = useState(false)

  const [modalEmailWasntSent, setmodalEmailWasntSent] = useState(false)

  //   useEffect(() => {
  //     setLoading(true);
  //     ("reset_password_page").then((data) => {
  //       const arr = data.content;
  //       settitle(arr[0].value);
  //       setBtnText(arr[1].value);
  //       setpasswordError(arr[2].value);
  //       setsmthWentWrongError(arr[3].value);
  //       setLoading(false);
  //     });
  //   }, []);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm()

  const changeUserName = (e) => {
    const { email } = e
    email_reset_password(email).then((data) => {
      if (data.status === 1) {
        // setshowEmailWasSent(true);
        setalertStatus(true)
        // setmodalEmailSent(true);
      } else if (data.status === 0) {
        setmodalEmailWasntSent(true)
      }
    })
  }

  function handleCloseAlert() {
    setalertStatus(false)
  }

  return (
    <div className="change-password-container">
      <FinishProfileInfo
        handleCloseFinishModal={handleCloseAlert}
        finishModalStatus={alertStatus}
        shouldCloseOnClick={true}
        text="Mail envoyé !
Si vous ne le trouvez pas dans votre boîte de réception, regardez dans vos courriers indésirables."
      />

      <Alert
        alertStatus={modalEmailWasntSent}
        handleCloseAlert={() => setmodalEmailWasntSent(false)}
        title="Adresse Email incorrecte"
      />
      <form
        onSubmit={handleSubmit(changeUserName)}
        className="change-password-section"
      >
        <div className="title-container">
          <h3 className="title">Modifier le mot de passe</h3>
          <span>
            Nous allons vous envoyer un mail avec un lien pour changer votre mot
            de passe.
          </span>
        </div>

        <div className="input-container">
          <div className="input-content">
            <p>Votre adresse mail :</p>
            <input
              className={`form-control ${errors.password && "input-error"}`}
              autoComplete="true"
              type="text"
              {...register("email", { required: true })}
              placeholder="Email"
            />
          </div>

          <CustomNextBtn className="validate-btn" type="submit">
            Valider
          </CustomNextBtn>
        </div>

        <div className="error-section">
          {errors.email && showErrorMessage("Ce champ doit être complété")}
        </div>
      </form>
    </div>
  )
}

export default ChangePassword
