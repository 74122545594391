import React, { useEffect } from "react"
import { connect } from "react-redux"
import { useHistory } from "react-router"
import { createNewSupervision } from "../../../../services/superVision"
import { set_sv_id } from "../../../../redux/actions/superVision"
import Main from "../pages1-6/Main"
import "../page1/styelpage1.scss"
import { set_log_time } from "../../../../redux/actions/timeAction"
import { get_second_connection } from "../../../../services/secondconnection"

const Page6 = ({ userId, svId, dispatch, checkTheTimeGab, handleLogOut }) => {
  useEffect(() => {
    handleTheautologout()
  }, [])

  function handleTheautologout() {
    if (checkTheTimeGab()) {
      handleLogOut()
    } else {
      dispatch(set_log_time(new Date().getTime()))
    }
  }

  const go = useHistory()
  async function createTheSuperVision() {
    const response = await createNewSupervision(
      "null",
      userId.user_id_company,
      0,
      0,
      userId.id_audit
    )
    const res = await response.json()
    return res
  }

  async function checkWhereTo() {
    const res = await get_second_connection(userId.user_id)
    return res
  }

  async function skipToList() {
    const datawhereTo = await checkWhereTo()
    const res = await datawhereTo.json()
    // debugger;
    if (res.supervision === "not_found") {
      createTheSuperVision()
        .then((data) => {
          dispatch(set_sv_id(data.id))
        })
        .then(() => {
          go.push("/home/questions_list")
          // window.location.reload();
        })
        .catch((e) => {
          console.log(e)
        })
    } else {
      dispatch(set_sv_id(res.supervision.id))
      if (
        res.supervision === "in_progress" ||
        res.supervision.name === null ||
        res.supervision.name === "null" ||
        res.supervision.name === "" ||
        res.supervision.name === "new super Vision"
      ) {
        go.push("/home/questions_list")
      } else {
        go.push("/home/questions_list/tut1")
        return
      }
    }
  }

  return (
    <div className="page-1-conatiner">
      <Main pageContent="sixth_info_page" clickBtn={skipToList} />
    </div>
  )
}

const mapStateToProps = ({ userId, svId }) => ({
  userId,
  svId,
})
export default connect(mapStateToProps)(Page6)
