import React from "react";
import { v4 } from "uuid";

function IconsUnderChart({
  curType,
  numOfBars,
  spcificBarIdx,
  theFristChartBarIdx,
  spcificbarImg,
  infoIcons,
  handleTypeSpcificIcon,
  handlespcificBarInfo,
}) {
  return (
    <div
      className={`${
        curType < 2 && curType > 0 ? "spcific-bar-paramiters" : "spcific-bar"
      }`}
    >
      {[...Array(numOfBars)].map((item, i) => (
        <img
          style={{ height: "50px" }}
          src={
            spcificBarIdx === i || theFristChartBarIdx === i
              ? spcificbarImg
              : infoIcons
          }
          alt=""
          key={v4()}
          onClick={() => {
            if (curType >= 2) {
              handleTypeSpcificIcon(i);
            } else {
              handlespcificBarInfo(i + 1);
            }
          }}
        />
      ))}
    </div>
  );
}

export default IconsUnderChart;
