import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { get_protect_deng } from "../../../services/chart"
import "./page2styles.scss"
import { BarChart, Bar, Cell, YAxis, ResponsiveContainer } from "recharts"
import { v4 } from "uuid"
import { CircularProgress } from "@material-ui/core"
import CheckBoxTerms from "../../terms/CheckBoxTerms"
import BackBtn from "../../utilities/Buttons/BackBtn"
import CustomNextBtn from "../../utilities/Buttons/NextButton"
import { useHistory } from "react-router"
import { getContent } from "../../../services/Content"

function Page2Tut({ svId }) {
  const go = useHistory()

  const [selectedBoxes, setselectedBoxes] = useState([])
  const [numOfBars, setnumOfBars] = useState(0)
  const [dataColordinP, setdataColordinP] = useState([])
  const [dataColordinD, setdataColordinD] = useState([])
  const [labels, setlables] = useState([])
  const [allChartData, setallChartData] = useState([])
  const [datanewCahrt, setdatanewCahrt] = useState([])
  const [loading, setloading] = useState(false)

  const [firstText, setfirstText] = useState("")
  const [title, settitle] = useState("")
  const [secText, setsecText] = useState("")
  const [nextBtn, setnextBtn] = useState("")
  const [prevBtn, setprevBtn] = useState("")

  const [loadingContent, setloadingContent] = useState(false)

  useEffect(() => {
    setloading(true)
    getAllPageContent()
    getChartData()
  }, [])

  function getChartData() {
    getTheDataOfBarChar()
      .then((data) => {
        setlables([])
        handleTheChartDataInState(data)
        setallChartData(data)
        setloading(false)
      })
      .catch((e) => console.log("some thing wrong", e.message))
  }

  function getAllPageContent() {
    setloadingContent(true)
    getContent("rm_tutorial_2").then((data) => {
      const arr = data.content
      setfirstText(arr[0].value)
      settitle(arr[1].value)
      setsecText(arr[2].value)
      setprevBtn(arr[3].value)
      setnextBtn(arr[4].value)
      setloadingContent(false)
    })
  }

  async function getTheDataOfBarChar() {
    const response = await get_protect_deng(svId.id)
    const res = await response.json()
    return res.data
  }
  function handleTheChartDataInState(dataOfChart = []) {
    setnumOfBars(dataOfChart.length)
    setdataColordinD([...Array(dataOfChart.length)].map(() => "gray"))
    setdataColordinP([...Array(dataOfChart.length)].map(() => "gray"))
    dataOfChart.map((item, i) => {
      setdatanewCahrt((arr) => {
        arr[i] = {
          name: item.name,
          protection: parseInt(item.default_safety_percentage, 10),
          danger: parseInt(item.threat_dangerousity_percentage, 10),
        }
        return arr
      })
      setlables((arra) => [...arra, item.name])
    })
  }
  function handleCheckSpcificBox(idx = "", type = "add") {
    if (type === "add") {
      setselectedBoxes((arr) => arr.filter((item) => item !== idx))
      setselectedBoxes((arr) => [...arr, idx])

      handleTheColorOfBars(idx, type)
    } else {
      setselectedBoxes((arr) => arr.filter((item) => item !== idx))
      handleTheColorOfBars(idx, type)
    }
  }

  function handleTheColorOfBars(idx, type = "add") {
    if (type === "add") {
      setdataColordinP((arr) => {
        arr[idx] = "#0d90f5"
        return arr
      })
      setdataColordinD((arr) => {
        arr[idx] = "#ff4600"
        return arr
      })
    } else {
      setdataColordinP((arr) => {
        arr[idx] = "gray"
        return arr
      })
      setdataColordinD((arr) => {
        arr[idx] = "gray"
        return arr
      })
    }
  }

  if (loadingContent) {
    return (
      <div style={{ textAlign: "center" }}>
        <CircularProgress
          size={80}
          thickness={4}
          style={{ color: "#ff6500", marginRight: "3px" }}
        />
      </div>
    )
  }
  return (
    <div className="page2__container">
      <p
        className="top__text"
        dangerouslySetInnerHTML={{ __html: firstText }}
      ></p>
      <div className="middle__container">
        <h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
        {loading === true && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress
              size={100}
              thickness={4}
              style={{ color: "#ff6500", marginRight: "3px" }}
            />
          </div>
        )}
        {loading === false && (
          <div className="bar__conatiner">
            <div className="the__bar">
              <ResponsiveContainer width="100%" height="80%" aspect={5}>
                <BarChart width={330} height={100} data={datanewCahrt}>
                  <YAxis />
                  <Bar
                    barSize={16}
                    dataKey="protection"
                    fill="#0d90f5"
                    name="protection"
                  >
                    {datanewCahrt.map((item, i) => (
                      <Cell key={v4()} fill={dataColordinP[i]} radius={5} />
                    ))}
                  </Bar>
                  <Bar barSize={16} dataKey="danger" fill="#ff4600">
                    {datanewCahrt.map((item, i) => (
                      <Cell key={v4()} fill={dataColordinD[i]} radius={5} />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
            <div className="bar-name">
              {labels.map((item) => (
                <div key={v4()}>
                  <span>{item}</span>
                </div>
              ))}
            </div>
            <div className="spcific-bar">
              {[...Array(numOfBars)].map((item, i) => (
                <CheckBoxTerms
                  selectedBoxes={selectedBoxes}
                  key={v4()}
                  idx={i}
                  handleTheCheck={handleCheckSpcificBox}
                />
              ))}
            </div>
          </div>
        )}
      </div>
      <p
        className="down__text"
        dangerouslySetInnerHTML={{ __html: secText }}
      ></p>
      <div className="btn__holder">
        <BackBtn onClick={() => go.push("/home/roadmap/tut1")}>
          <span dangerouslySetInnerHTML={{ __html: prevBtn }}></span>
        </BackBtn>
        <CustomNextBtn onClick={() => go.push("/home/roadmap/tut3")}>
          <span dangerouslySetInnerHTML={{ __html: nextBtn }}></span>
        </CustomNextBtn>
      </div>
    </div>
  )
}
const mapStateToProps = ({ svId }) => ({ svId })
export default connect(mapStateToProps)(Page2Tut)
