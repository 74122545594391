import moment from "moment"
import { prefixApi, prefixHeaderObj } from "./prefix"

const api_create_supervision = `${prefixApi}supervisions/create`
const api_update_supervision = `${prefixApi}supervisions/update/`
const api_status_super_vison = `${prefixApi}supervisions/status/`
const api_get_all_supervision = `${prefixApi}supervisions/retrieve`
const api_get_all_supervisions_for_company = `${prefixApi}supervisions/retrieve_by_company/`
const api_get_all_roadmaps_for_company = `${prefixApi}roadmaps/retrieve_by_company/`
const api_change_in_supervision_flow = `${prefixApi}supervisions/update/`
const api_calc_question_impact = `${prefixApi}reports/calculate_questions_impact`
const api_supervision_content = `${prefixApi}content/get/`

export function createNewSupervision(
  name = "",
  id_company = "",
  last_category_index = "",
  last_question_index = "",
  id_audit
) {
  const date_time = moment().format("YYYY-MM-DD HH:mm:ss")
  var details = {
    name: name,
    datetime: date_time,
    id_company,
    last_category_index,
    last_question_index,
    id_audit,
  }
  var formBody = []
  for (var property in details) {
    var encodedKey = encodeURIComponent(property)
    var encodedValue = encodeURIComponent(details[property])
    formBody.push(encodedKey + "=" + encodedValue)
  }
  formBody = formBody.join("&")

  return fetch(api_create_supervision, {
    method: "POST",
    headers: prefixHeaderObj,
    body: formBody,
  })
}

export const update_supervision = (
  last_category_index,
  last_question_index,
  supervision_id,
  status
) => {
  var details = {
    last_category_index: last_category_index,
    last_question_index: last_question_index,
    status: status,
  }
  var formBody = []
  for (var property in details) {
    var encodedKey = encodeURIComponent(property)
    var encodedValue = encodeURIComponent(details[property])
    formBody.push(encodedKey + "=" + encodedValue)
  }
  formBody = formBody.join("&")
  return fetch(`${api_update_supervision}${supervision_id}`, {
    method: "PUT",
    headers: prefixHeaderObj,
    body: formBody,
  })
}

// export const get_all_supervision = () => {
//   return fetch(api_get_all_supervision, {
//     method: "GET",
//     headers: {
//       Authorization: "Basic " + btoa("api" + ":" + "P4r0|@876!api"),
//       "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
//       Accept: "application/json, text/javascript",
//     },
//     // body: formBody,
//   });
// };

export const get_all_supervisions_for_company = (id) => {
  return fetch(api_get_all_supervisions_for_company + `${id}`, {
    method: "GET",
    headers: prefixHeaderObj,
    // body: formBody,
  })
}

export const get_all_raodmaps_for_company = (id) => {
  return fetch(api_get_all_roadmaps_for_company + `${id}`, {
    method: "GET",
    headers: prefixHeaderObj,
    // body: formBody,
  })
}

export const get_specific_supervision = (id = 1) => {
  return fetch(api_get_all_supervision + `/${id}`, {
    method: "GET",
    headers: prefixHeaderObj,
    // body: formBody,
  })
}

export const get_status_sv = (idSv) => {
  return fetch(api_status_super_vison + `${idSv}`, {
    method: "GET",
    headers: prefixHeaderObj,
    // body: formBody,
  })
}
export function change_supervision_flow(
  svId = "",
  name = "",
  status = "finished"
) {
  var details = {
    name: name,
    status,
  }
  var formBody = []
  for (var property in details) {
    var encodedKey = encodeURIComponent(property)
    var encodedValue = encodeURIComponent(details[property])
    formBody.push(encodedKey + "=" + encodedValue)
  }
  formBody = formBody.join("&")
  return fetch(api_change_in_supervision_flow + `${svId}`, {
    method: "PUT",
    headers: prefixHeaderObj,
    body: formBody,
  })
}

export async function claculate_the_impact(svId = "", arr = "") {
  var details = {
    id_supervision: svId,
    threats_array: arr,
  }
  var formBody = []
  for (var property in details) {
    var encodedKey = encodeURIComponent(property)
    var encodedValue = encodeURIComponent(details[property])
    formBody.push(encodedKey + "=" + encodedValue)
  }
  formBody = formBody.join("&")
  return fetch(api_calc_question_impact, {
    method: "POST",
    headers: prefixHeaderObj,
    body: formBody,
  })
}

export function supervisionContent(type) {
  var details = {
    // key: type,
    page: type,
  }
  var formBody = []
  for (var property in details) {
    var encodedKey = encodeURIComponent(property)
    var encodedValue = encodeURIComponent(details[property])
    formBody.push(encodedKey + "=" + encodedValue)
  }
  formBody = formBody.join("&")

  return fetch(api_supervision_content, {
    method: "POST",
    headers: prefixHeaderObj,
    body: formBody,
  }).then((res) => res.json())
}
