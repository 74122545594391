import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import "./name.scss"
import { showErrorMessage } from "../Account/FormsService"
import { get_user_data, update_user_first_last_name } from "../../services/auth"
import { connect } from "react-redux"
import "../Account/Login/styles.scss"
import { set_user_profile_name } from "../../redux/actions/user"
import FinishProfileInfo from "../utilities/Modals/finishProfileInfo/FinishProfileInfo"
import CustomNextBtn from "../utilities/Buttons/NextButton"

const ChangeName = ({ dispatch, userId }) => {
  const [showFormError, setshowFormError] = useState(false)

  const [firstnameText, setfirstnameText] = useState("")
  const [lastnameText, setlastname] = useState("")
  const [alertStatus, setalertStatus] = useState(false)
  const [loading, setloading] = useState(true)
  useEffect(() => {
    get_user_data(userId.user_id)
      .then((res) => {
        let { first_name, last_name } = res.data
        setfirstnameText(first_name)
        setlastname(last_name)
      })
      .then(() => setloading(false))
  }, [])

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm()

  const changeUserName = (e) => {
    if (firstnameText === "" || lastnameText === "") {
      return setshowFormError(true)
    }
    var details = {
      first_name: firstnameText,
      last_name: lastnameText,
    }
    update_user_first_last_name(userId.user_id, details).then(() => {
      dispatch(set_user_profile_name(firstnameText))
      setalertStatus(true)
    })
  }

  function handleCloseAlert() {
    setalertStatus(false)
  }

  return loading ? null : (
    <div className="change-name-container">
      <FinishProfileInfo
        handleCloseFinishModal={handleCloseAlert}
        finishModalStatus={alertStatus}
        shouldCloseOnClick={true}
        text="Votre nom a été changé."
      />
      <form
        onSubmit={handleSubmit(changeUserName)}
        className="change-name-section"
      >
        <h3 className="title">Modification de vos coordonnées</h3>
        <div className="input-container">
          <div className="input-content">
            <p>Nom</p>
            <input
              className={`form-control ${errors.firstname && "input-error"}`}
              autoComplete="true"
              type="text"
              {...register("firstname", { required: true })}
              placeholder="Prénom"
              value={firstnameText}
              onChange={(t) => setfirstnameText(t.target.value)}
            />
          </div>

          <div className="input-content">
            <p>Prénom</p>

            <input
              className={`form-control ${errors.lastname && "input-error"}`}
              autoComplete="true"
              type="text"
              {...register("lastname", { required: true })}
              value={lastnameText}
              placeholder="Nom de famille"
              onChange={(t) => setlastname(t.target.value)}
            />
          </div>

          <CustomNextBtn
            type="submit"
            className="validate-btn btn btn-lg btn-rounded btn-warning"
          >
            VALIDER
          </CustomNextBtn>
        </div>
      </form>
      <div className="error-section">
        {errors.firstname && showErrorMessage("Ce champ doit être complété")}
        {errors.lastname && showErrorMessage("Ce champ doit être complété")}
      </div>
    </div>
  )
}

const mapStateToProps = ({ dispatch, userId }) => ({
  dispatch,
  userId,
})

export default connect(mapStateToProps)(ChangeName)
