export const SET_USER = "SET_USER"
export const SET_TERM_NUMBER = "SET_TERM_NUMBER"
export const SET_CUR_QUESTION_NUMBER = "SET_CUR_QUESTION_NUMBER"
export const SET_NUMBER_QUESTION = "SET_NUMBER_QUESTION"
export const SET_USER_ID = "SET_USER_ID"
export const SET_USER_ID_COMPANY = "SET_USER_ID_COMPANY"
export const SET_USER_ID_AUDIT = "SET_USER_ID_AUDIT"
export const SET_USER_ID_ROLE = "SET_USER_ID_ROLE"

export const SET_USER_PROFILE_NAME = "SET_USER_PROFILE_NAME"
export const SET_USER_TUT = "SET_USER_TUT"
export const SET_QUESTIONS_FINISHED_ONCE = "SET_QUESTIONS_FINISHED_ONCE"
export const SET_STATE_TUTS_HUMBURGER = "SET_STATE_TUTS_HUMBURGER"
export const SET_SUPERVISION_ID = "SET_SUPERVISION_ID"

export const SET_ROAD_MAP_ID = "SET_ROAD_MAP_ID"

export const SET_ROAD_MAP_NAME = "SET_ROAD_MAP_NAME"
export const SET_SUPERVISION_NAME = "SET_SUPERVISION_NAME"

export const SET_LOGIN_TIME = "SET_LOGIN_TIME"
export const SET_LAST_LOGIN_TIME = "SET_LAST_LOGIN_TIME"
export const SET_USER_IMG = "SET_USER_IMG"

export const SET_CUR_ROADMAP_TABLE_PAGE = "SET_CUR_ROADMAP_TABLE_PAGE"

export const SET_FIRST_REPORT = "SET_FIRST_REPORT"
export const SET_SV_ID = "SET_SV_ID"
