import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import "./styles.scss"
import { showErrorMessage } from "../FormsService"
import AccountsBtn from "../../utilities/Buttons/AccountsBtn"
import { getContent } from "../../../services/Content"
import { CircularProgress } from "@material-ui/core"
import { useHistory, useParams } from "react-router-dom"
import { checkThecode, resetPassword_api } from "../../../services/auth"
import Alert from "../../utilities/Modals/alert/Alert"
import { useDispatch } from "react-redux"
import {
  set_user_id,
  set_user_id_company,
  set_user_id_role,
  set_user_profile_name,
} from "../../../redux/actions/user"
import { set_sv_id, set_sv_name } from "../../../redux/actions/superVision"
import {
  set_roadmap_id,
  set_roadmap_name,
} from "../../../redux/actions/roadmap"
import { set_user_img } from "../../../redux/actions/profileAction"
import { set_term_1 } from "../../../redux/actions/term"
import { checkValid } from "./helper"
const ResetPass = () => {
  const [showFormError, setshowFormError] = useState(false)
  const [alertStatus, setalertStatus] = useState(false)
  const [passDontMatchError, setpassDontMatchError] = useState(false)

  const { token } = useParams()
  const history = useHistory()

  const [idOfuser, setidOfuser] = useState("")
  const [title, settitle] = useState("")
  const [btnText, setBtnText] = useState("")
  const [loading, setLoading] = useState("")
  const [passwordError, setpasswordError] = useState("")
  const [smthWentWrongError, setsmthWentWrongError] = useState("")

  useEffect(() => {
    ;(async () => {
      setTheDispatchesNull()
      const res = await checkThecode(token)
      if (res.user === false) {
        alert(
          "Par mesure de sécurité, votre session a expiré. Vous allez devoir vous reconnecter"
        )
        history.push("/auth/login")
      } else {
        setidOfuser(res.user.id)
      }
    })()
  }, [])

  const dispatch = useDispatch()
  function setTheDispatchesNull() {
    dispatch(set_user_id(null))
    dispatch(set_user_id_company(null))
    dispatch(set_user_id_role(null))
    dispatch(set_sv_id(null))
    dispatch(set_sv_name(null))
    dispatch(set_roadmap_name(null))
    dispatch(set_roadmap_id(null))
    dispatch(set_user_profile_name(null))
    dispatch(set_user_img(null))
    dispatch(set_term_1(null))
  }
  useEffect(() => {
    setLoading(true)
    getContent("reset_password_page").then((data) => {
      const arr = data.content
      settitle(arr[0].value)
      setBtnText(arr[1].value)
      setpasswordError(arr[2].value)
      setsmthWentWrongError(arr[3].value)
      setLoading(false)
    })
  }, [])

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm()

  const resetPassword = async (e) => {
    // const newPass = e.password
    let pass = e.password
    let confPass = e.confirmPassword

    if (checkValid(pass) !== true) {
      alert(
        "Votre mot de passe doit avoir au moins 12 caractères dont une majuscule, une minuscule et un chiffre."
      )
      return
    }

    if (pass !== confPass) {
      return setpassDontMatchError(true)
    }
    try {
      const res = await resetPassword_api(pass, confPass, idOfuser, token)
      setalertStatus(true)

      history.push("/auth/login")
    } catch (error) {
      setshowFormError(true)
      setTimeout(() => {
        setshowFormError(false)
      }, 5000)
    }
  }

  function handleCloseAlert() {
    setalertStatus(false)
  }

  if (loading) {
    return (
      <div style={{ textAlign: "center" }}>
        <CircularProgress
          size={50}
          thickness={4}
          style={{ color: "#ff6500", marginRight: "3px" }}
        />
      </div>
    )
  }

  return (
    <div className="reset-pass-container">
      <Alert
        alertStatus={alertStatus}
        handleCloseAlert={handleCloseAlert}
        title="Votre mot de passe a été changé."
      />
      <form
        onSubmit={handleSubmit(resetPassword)}
        className="reset-pass-section"
      >
        <p
          className="reset-title"
          dangerouslySetInnerHTML={{ __html: title }}
        ></p>
        <div className="input-container">
          <input
            className={`form-control ${errors.password && "input-error"}`}
            autoComplete="true"
            type="password"
            {...register("password", { required: true })}
            placeholder="Entrez le nouveau mot de passe"
          />

          <input
            className={`form-control ${errors.password && "input-error"}`}
            autoComplete="true"
            type="password"
            {...register("confirmPassword", { required: true })}
            placeholder="Confirmez le nouveau mot de passe"
          />
          <AccountsBtn btnType="submit">
            <span dangerouslySetInnerHTML={{ __html: btnText }}></span>
          </AccountsBtn>
        </div>

        <div className="error-section">
          {errors.password && showErrorMessage(passwordError)}
          {showFormError && showErrorMessage(smthWentWrongError)}
          {passDontMatchError &&
            showErrorMessage("le mot de passe ne correspond pas")}
        </div>
      </form>
    </div>
  )
}

export default ResetPass
