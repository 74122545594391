import { SET_USER_IMG } from "../constants"

export const profileReducer = (state = { src: null }, action) => {
  switch (action.type) {
    case SET_USER_IMG:
      return { ...state, src: action.src }
    default:
      return state
  }
}
